import { authOtp } from "app/fb/config";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import CodexAPIConfirmer from "app/api/codexapiconfirmer";

import store from "store";
import { codexApiCatchError } from "actions/snackBar";

const requestOtp = async (
  phoneNumber,
  letter_uuid,
  confirmer_response,
  setIsOtpSent,
  setPhoneErrorMessage,
  setRequestingOtp
) => {
  const reCaptchaGenerator = () => {
    const check = document.getElementById("recaptcha-container");
    if (check) {
      check.remove();
    }

    const div = document.createElement("div");
    div.id = "recaptcha-container";
    document.body.appendChild(div);

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      authOtp
    );
  };

  setRequestingOtp(true);
  setPhoneErrorMessage("");

  let apiOK = false;
  try {
    const apiResponse = await CodexAPIConfirmer().post(
      "confirmer/letter/check-to-otp-request/",
      {
        letter_uuid,
        response: confirmer_response,
        phone_number: phoneNumber,
      }
    );
    apiOK = true;
  } catch (error) {
    const dispatch = store.dispatch;
    dispatch(codexApiCatchError(error));
  }

  if (apiOK) {
    reCaptchaGenerator();
    let appVerifier = window.recaptchaVerifier;

    await signInWithPhoneNumber(authOtp, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setIsOtpSent(true);
      })
      .catch((error) => {
        if (error.code === "auth/invalid-phone-number") {
          setPhoneErrorMessage("Invalid phone number");
        } else {
          setPhoneErrorMessage(error.code);
        }
        setIsOtpSent(false);
      });
  }

  // document.getElementById("recaptcha-container").remove();
  setRequestingOtp(false);
};

export default requestOtp;
