import { useNavigate } from "react-router-dom";

import SuiButton from "components/sui/SuiButton";
import Icon from "@mui/material/Icon";

import { BASE_PATH } from "settings";

const EditButton = ({ businessUUID }) => {
  const navigate = useNavigate();
  return (
    <SuiButton
      size="small"
      variant="gradient"
      color="warning"
      iconOnly
      circular
      onClick={() => {
        navigate(
          `${BASE_PATH}/overview/business/edit-business?uuid=${businessUUID}`
        );
      }}
    >
      <Icon>edit</Icon>
    </SuiButton>
  );
};

export default EditButton;
