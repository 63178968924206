import { BASE_URL, BASE_APP_URL } from "settings";

export const BASE_CLIENT_PATH = "/client";
export const BASE_AUDITOR_PATH = "/auditor";
export const BASE_CONFIRMER_PATH = "/confirmer";

export const BASE_APP_PATH = "/overview";

export const PRIVACY_POLICY = `${BASE_URL}/privacy`;
export const TERM_OF_USE = `${BASE_APP_URL}/term-of-use`;
