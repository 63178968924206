import {
  CONF_SIGN_IN,
  CONF_SIGN_OUT,
  CONF_RELOAD_TOKEN,
  SIGN_OUT,
} from "actions/types";

import { deploySnackbar } from "actions";

// export const confSignIn = (fbData) => {
//   return {
//     type: CONF_SIGN_IN,
//     payload: {
//       user: {
//         uid: fbData.uid,
//         email: fbData.email,
//         fbData,
//         token: fbData.accessToken,
//         tokenExpireAt: fbData.stsTokenManager.expirationTime,
//       },
//     },
//   };
// };

export const confSignOut = () => {
  return {
    type: CONF_SIGN_OUT,
  };
};

export const confReloadToken = (fbData) => (dispatch, getState) => {
  const appEmail = getState().user.email;
  if (appEmail !== fbData.email && appEmail !== "") {
    dispatch({
      type: CONF_SIGN_OUT,
    });
    dispatch({
      type: SIGN_OUT,
    });
    const snackBar = {
      color: "error",
      icon: "report_problem",
      title: "Other account is signed in",
      content: `Automatic sign off for both accounts, please sign in again.`,
    };
    dispatch(deploySnackbar(snackBar));
  } else {
    dispatch({
      type: CONF_RELOAD_TOKEN,
      payload: {
        user: {
          uid: fbData.uid,
          email: fbData.email,
          fbData,
          token: fbData.accessToken,
          tokenExpireAt: fbData.stsTokenManager.expirationTime,
        },
      },
    });
  }
};
