// export const BASE_APP_URL = "http://localhost:3000";
export const BASE_APP_URL = "https://beta.confirmation.codexlab.co";
export const BASE_URL = "https://www.codexlab.co";
export const BASE_PATH = "";

// export const BASE_API = "http://localhost:8000/api";

export const BASE_API = "https://api.beta.confirmation.codexlab.co/api";

export const UNKNOWN_ERROR_MESSAGE = "Something went wrong! Please try again.";

export const LESS_SECURE_KEY =
  "This is a fake key. Not the real one. - d699273f8f1e0b2b44ef04a81b0ce84b6ba848a5240d21555cb574d98b13ff65";

export const rootElement = document.getElementById("root");
