export const BUSINESS_MEMBERSHIP_OPTIONS = [
  { value: 0, label: "Member" },
  { value: 1, label: "Manager" },
  { value: 2, label: "Admin" },
  { value: 3, label: "Owner" },
];
export const BUSINESS_MEMBERSHIP_TYPE = BUSINESS_MEMBERSHIP_OPTIONS.map(
  (e) => e.label
);

export const ENGAGEMENT_MEMBERSHIP_OPTIONS = [
  { value: 0, label: "Member" },
  { value: 1, label: "Manager" },
];

export const ENGAGEMENT_MEMBERSHIP_TYPE = ENGAGEMENT_MEMBERSHIP_OPTIONS.map(
  (e) => e.label
);

export const ENGAGEMENT_TEAM_OPTIONS = [
  { value: 0, label: "Client" },
  { value: 1, label: "Auditor" },
];

export const ENGAGEMENT_TEAM_TYPE = ENGAGEMENT_TEAM_OPTIONS.map((e) => e.label);

export const ENGAGEMENT_BILLING_METHOD_OPTIONS = [
  { value: 0, label: "Prepaid" },
  { value: 1, label: "Postpaid" },
];

export const ENGAGEMENT_BILLING_METHOD_TYPE =
  ENGAGEMENT_BILLING_METHOD_OPTIONS.map((e) => e.label);
