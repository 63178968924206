import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxInput from "components/cdx/CdxInput";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import CodexAPI from "app/api/codexapi";

import { UNKNOWN_ERROR_MESSAGE } from "settings";

import { LSK_EMAIL_FOR_SIGN_UP } from "settings/localStorageKeys";

const SignUpRequestForm = ({}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState({});

  const validateForm = (values) => {
    const error = {};

    if (!values.email) {
      error.email = "Please enter email";
    } else {
      const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      };
      if (!validateEmail(values.email)) {
        error.email = "Please enter a valid email";
      }
    }
  };

  const onSubmit = async (values) => {
    setMessage({ color: "dark", text: "Emailing sign up link..." });
    const error = {};
    try {
      const result = await CodexAPI().post("/user/sign-up-request/", {
        email: values.email,
      });
      setMessage({
        color: "success",
        text: "Sign up link has sent to your email",
      });
      console.log(result.data.data.link);
      window.localStorage.setItem(LSK_EMAIL_FOR_SIGN_UP, values.email);
    } catch (error) {
      setMessage({});
      if (!error.response) {
        return { [FORM_ERROR]: "Unable to connect API server" };
      } else {
        const errorMessage = error.response.data.message
          ? error.response.data.message.text
          : UNKNOWN_ERROR_MESSAGE;
        return { [FORM_ERROR]: errorMessage };
      }
    }
    return error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <SuiBox
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Field name="email">
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  mb={2}
                  label={t("Email")}
                  error={
                    !!(meta.error && meta.touched) ||
                    !!meta.submitError ||
                    !!submitError
                  }
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            {submitError && (
              <SuiBox>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </SuiBox>
            )}
            {message.text && (
              <SuiBox>
                <SuiTypography
                  color={message.color || "info"}
                  variant="caption"
                >
                  {t(message.text)}
                </SuiTypography>
              </SuiBox>
            )}
            <SuiBox mt={3}>
              <SuiButton
                variant="gradient"
                color="primary"
                type="submit"
                fullWidth
                disabled={
                  submitting ||
                  pristine ||
                  Object.values(formState.errors).length > 0
                }
              >
                {t("Send a sign up link to email")}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        );
      }}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpRequestForm);
