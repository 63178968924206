import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SuiButton from "components/sui/SuiButton";

import CodexAPI from "app/api/codexapi";
import { deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

const CancelButton = ({
  name,
  engagement_request_uuid,
  accept_as,
  getRequestList,
  deploySnackbar,
  codexApiCatchError,
}) => {
  const { t } = useTranslation();
  const handleOnClick = async () => {
    try {
      const response = await CodexAPI().post(
        "engagement/engagement-request-by-user/reject/",
        {
          engagement_request_uuid,
          accept_as,
        }
      );
      getRequestList();
      const snackBar = {
        color: "success",
        icon: "done",
        title: t("Successfully reject engagement request"),
        content: `${t("Engagement request - ")} ${name} ${t("is rejected")}`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };
  return (
    <SuiButton
      size="small"
      variant="gradient"
      color="error"
      onClick={handleOnClick}
    >
      {t("Reject")}
    </SuiButton>
  );
};
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton);
