import { useTranslation } from "react-i18next";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { Grid } from "@mui/material";

import DefaultCounterCard from "components/cdx/CdxCounterCards/DefaultCounterCard";
import { useEffect, useState, Fragment } from "react";

import DownloadSummary from "../DownloadSummary";

const ResponseSummary = ({ letters = [], mapping }) => {
  const { t } = useTranslation();
  const [counters, setCounters] = useState({});

  useEffect(() => {
    if (letters) {
      const count = {};

      let keys = [];
      letters.forEach((e) => {
        Object.entries(e.confirm_balance).forEach(([k, v]) => {
          if (!keys.includes(k)) {
            keys = [...keys, k];
            count[k] = {
              sent: 0,
              per_letter: 0,
              per_response: 0,
              diff: 0,
              abs_diff: 0,
            };
          }
          count[k]["sent"] += v;
          if (e.confirmer_submit) {
            const per_response = e.response_balance[k];
            count[k]["per_letter"] += v;
            count[k]["per_response"] += per_response;
            count[k]["diff"] += v - per_response;
            count[k]["abs_diff"] += Math.abs(v - per_response);
          }
        });
        Object.entries(e.confirm_transaction).forEach(([k, v]) => {
          if (!keys.includes(k)) {
            keys = [...keys, k];
            count[k] = {
              sent: 0,
              per_letter: 0,
              per_response: 0,
              diff: 0,
              abs_diff: 0,
            };
          }
          count[k]["sent"] += v;
          if (e.confirmer_submit) {
            const per_response = e.response_transaction[k];
            count[k]["per_letter"] += v;
            count[k]["per_response"] += per_response;
            count[k]["diff"] += v - per_response;
            count[k]["abs_diff"] += Math.abs(v - per_response);
          }
        });
      });

      setCounters(count);
    } else {
      setCounters([]);
    }
  }, [letters]);

  return (
    <Fragment>
      {letters.length == 0 ? (
        <Fragment></Fragment>
      ) : (
        <SuiBox mt={4}>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SuiBox>
              <SuiTypography variant="h6">
                {t("Response from confirmer")}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <DownloadSummary />
            </SuiBox>
          </SuiBox>

          {Object.entries(counters).map(([key, counter]) => {
            return (
              <SuiBox mt={2} key={`counter-${key}`}>
                <SuiTypography variant="body2">
                  {mapping[key] || key}
                </SuiTypography>
                <Grid
                  container
                  pt={2}
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                >
                  <Grid item xs>
                    <DefaultCounterCard
                      height="100%"
                      color="primary"
                      title={t("Sent amount")}
                      count={counter.sent || 0}
                    />
                  </Grid>
                  <Grid item xs>
                    <DefaultCounterCard
                      height="100%"
                      color="primary"
                      title={t("Response amount")}
                      count={counter.per_letter || 0}
                    />
                  </Grid>
                  <Grid item xs>
                    <DefaultCounterCard
                      height="100%"
                      color="primary"
                      title={t("Per confirm")}
                      count={counter.per_response || 0}
                    />
                  </Grid>
                  <Grid item xs>
                    <DefaultCounterCard
                      height="100%"
                      color="primary"
                      title={t("Difference")}
                      count={counter.diff || 0}
                    />
                  </Grid>
                  <Grid item xs>
                    <DefaultCounterCard
                      height="100%"
                      color="primary"
                      title={t("Difference (Abs per letter)")}
                      count={counter.diff || 0}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            );
          })}
        </SuiBox>
      )}
    </Fragment>
  );
};

export default ResponseSummary;
