import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

import { SoftUIControllerProvider } from "context";

import CdxSnackbars from "components/cdx/CdxSnackbars";

import onAppFirstLoad from "app/onAppFirstLoad";

import AppRoute from "AppRoute";

import { GA_TRACKING_ID } from "settings/ga";

import gtag from "app/ga";

const App = () => {
  useEffect(() => {
    onAppFirstLoad();

    gtag("js", new Date());
    gtag("config", GA_TRACKING_ID);
  }, []);

  return (
    <SoftUIControllerProvider>
      <BrowserRouter>
        <AppRoute />
      </BrowserRouter>
      <CdxSnackbars />
    </SoftUIControllerProvider>
  );
};

export default App;
