import CdxCard from "components/cdx/CdxCard";
import CdxCollapseCard from "components/cdx/CdxCollapseCard";

import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import LetterTemplateSelection from "./Selection";
import LetterTemplateEditor from "./Editor";
import LetterTemplatePreview from "./Preview";

import { connect } from "react-redux";

const LetterTemplate = ({ engagementData }) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [template, setTemplate] = useState("");
  const [onEdit, setOnEdit] = useState(false);

  useEffect(() => {
    if (selectedTemplate) {
      setTemplate(
        (
          ((engagementData.information || {}).templates || {})[
            selectedTemplate
          ] || {}
        ).template || ""
      );
    } else {
      setTemplate("");
    }
  }, [selectedTemplate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LetterTemplateSelection
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          templateList={((engagementData || {}).information || {}).templates}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
        />
      </Grid>
      {selectedTemplate && (
        <Grid item xs={12}>
          <LetterTemplatePreview template={template} />
        </Grid>
      )}
      {onEdit && (
        <Grid item xs={12}>
          <LetterTemplateEditor
            template={template}
            setTemplate={setTemplate}
            setOnEdit={setOnEdit}
            selectedTemplate={selectedTemplate}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LetterTemplate);
