import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SuiBox from "components/sui/SuiBox";

import CdxSelect from "components/cdx/CdxSelect";

import {
  BUSINESS_MEMBERSHIP_TYPE,
  BUSINESS_MEMBERSHIP_OPTIONS,
} from "settings/confirmation";

import SuiButton from "components/sui/SuiButton";

export default function ChangeModal({
  open,
  email,
  curType,
  type,
  setType,
  myType,
  handleClose,
  handleSubmit,
}) {
  const { t } = useTranslation();
  const [memberOptions, setMemberOptions] = useState([]);
  const modalElement = useRef(null);

  useEffect(() => {
    const op = BUSINESS_MEMBERSHIP_OPTIONS.filter(
      (e) => e.value <= myType && e.value !== curType
    ).map((e) => {
      return { value: e.value, label: t(e.label) };
    });

    const timeOutId = setTimeout(() => {
      setMemberOptions(op);
    }, 100);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [myType, curType, open]);

  return (
    <>
      <Dialog
        ref={modalElement}
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={open}
        onClose={handleClose}
        sx={{ backdropFilter: "blur(5px)" }}
      >
        <SuiBox p={2}>
          <DialogTitle>
            {t("Change type for")} {email}
          </DialogTitle>
          <DialogContentText px={2}>
            {t("Current membership type is")}{" "}
            {t(BUSINESS_MEMBERSHIP_TYPE.at(curType).toLowerCase())}
          </DialogContentText>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <CdxSelect
                label={t("New membership type")}
                value={type}
                onChange={setType}
                options={memberOptions}
                menuPortalTarget={modalElement.current}
              />
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SuiButton
                  onClick={handleClose}
                  color="error"
                  variant="gradient"
                >
                  Close
                </SuiButton>
                <SuiButton
                  disabled={type == null || type == curType || type > myType}
                  onClick={handleSubmit}
                  color="primary"
                  variant="gradient"
                >
                  Change
                </SuiButton>
              </Box>
            </Box>
          </DialogContent>
        </SuiBox>
      </Dialog>
    </>
  );
}
