import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import { viewBusiness } from "actions";

import getNames from "app/utils/getNames";

const BusinessNameHook = ({
  uuid,
  notfound = "Business",
  businessData,
  viewBusiness,
}) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState(notfound);
  const [timeoutId, setTimeoutId] = useState();
  useEffect(() => {
    const business = businessData[uuid];
    if (business) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (business.information) {
        const name = getNames(business.information.names);
        if (name) {
          setBusinessName(name);
        }
      }
    } else {
      const id = setTimeout(() => {
        viewBusiness(uuid);
      }, 500);
      setTimeoutId(id);
    }
  }, [uuid, businessData]);
  return <>{businessName}</>;
};

const mapStateToProps = (state) => {
  return { businessData: state.business.businessData };
};

const mapDispatchToProps = {
  viewBusiness,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessNameHook);
