import SuiButton from "components/sui/SuiButton";

import { Grid, Icon } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { BASE_PATH } from "settings";

const BusinessViewFunctionBar = ({ uuid }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Grid
        columnSpacing={2}
        rowSpacing={1}
        container
        item
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        lg={6}
      >
        <Grid item>
          <SuiButton
            size="small"
            color="warning"
            variant="gradient"
            onClick={() => {
              navigate(
                `${BASE_PATH}/overview/business/edit-business?uuid=${uuid}`
              );
            }}
          >
            <Icon>edit</Icon>&nbsp;&nbsp;{t("Edit my business")}
          </SuiButton>
        </Grid>
        <Grid item>
          <SuiButton
            size="small"
            color="info"
            variant="gradient"
            onClick={() => {
              navigate(
                `${BASE_PATH}/overview/business/manage-business?uuid=${uuid}`
              );
            }}
          >
            <Icon>people</Icon>&nbsp;&nbsp;{t("Manage my business")}
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessViewFunctionBar;
