import { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";
import SuiInput from "components/sui/SuiInput";
import SuiSelect from "components/sui/SuiSelect";
import SuiTypography from "components/sui/SuiTypography";

import { codexApiCatchError } from "actions/snackBar";
import { listMyEngagement } from "actions";

import { useTranslation } from "react-i18next";

import GotoButton from "../GotoButton";
import AdminButton from "../AdminButton";

import getNames from "app/utils/getNames";

const SearchNFilter = ({
  setRows,
  setPageSize,
  codexApiCatchError,
  myEngagement,
  listMyEngagement,
  displayOption,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [myEngagementAdj, setMyEngagementAdj] = useState([]);

  useEffect(() => {
    listMyEngagement();
  }, []);

  const actionButton = (type, engagementUUID, archive_at) => {
    let isArchive = false;
    if (archive_at) {
      const day = new Date(archive_at);
      if (Date.now() > day) {
        isArchive = true;
      }
    }
    return (
      <Grid container spacing={1}>
        {type < 2 && (
          <Grid item>
            <GotoButton engagementUUID={engagementUUID} />
          </Grid>
        )}
        {type >= 1 && (
          <Grid item>
            <AdminButton engagementUUID={engagementUUID} />
          </Grid>
        )}
      </Grid>
    );
  };

  const rebuildEngagement = (engagements) => {
    return engagements.map((engagement) => {
      const clientName = getNames(engagement.clientBusinessNames);

      const auditorName = getNames(engagement.auditorBusinessNames);
      return {
        type: engagement.type,
        team: engagement.team,
        name: engagement.engagementMutualName,
        myBusiness: engagement.team ? auditorName : clientName,
        counterparty: !engagement.team ? auditorName : clientName,
        billing_method: engagement.billing_method,
        clientNames: engagement.clientBusinessNames,
        auditorNames: engagement.auditorBusinessNames,
        archive_at: engagement.archive_at,
        paid_by:
          engagement.auditorBusinessUUID == engagement.billingBusinessUUID
            ? auditorName
            : clientName,
        action: actionButton(
          engagement.type,
          engagement.engagementUUID,
          engagement.archive_at
        ),
      };
    });
  };

  const filterEngagement = (space, displayOption) => {
    const searchRegExp = new RegExp(search, "i");
    let arr1 = space.filter((e) => {
      let valid = e.name.search(searchRegExp) >= 0;
      valid =
        valid ||
        e.clientNames.filter((n) => n.name.search(searchRegExp) >= 0).length >
          0;
      valid =
        valid ||
        e.auditorNames.filter((n) => n.name.search(searchRegExp) >= 0).length >
          0;
      return valid;
    });

    let combineArr = [...new Set([...arr1])];
    arr1 = null;
    if (!displayOption.archive) {
      combineArr = combineArr.filter((e) => {
        return !e.archive_at;
      });
    }
    if (!displayOption.admin) {
      combineArr = combineArr.filter((e) => {
        return e.type !== 2;
      });
    }
    return combineArr;
  };

  useEffect(() => {
    setMyEngagementAdj(rebuildEngagement(myEngagement));
  }, [myEngagement]);

  useEffect(() => {
    if (!search && displayOption.archive && displayOption.admin) {
      setRows(myEngagementAdj);
    } else {
      setRows(filterEngagement(myEngagementAdj, displayOption));
    }
  }, [myEngagementAdj, search, displayOption]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} lg={5} xl={4}>
        <SuiInput
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          icon={{
            component: "search",
            direction: "left",
          }}
        />
      </Grid>
      <Grid item xs={12} lg={7} xl={8}>
        <Grid
          container
          columnSpacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <SuiBox display="flex" alignItems="center">
              <SuiSelect
                defaultValue={{ value: 10, label: 10 }}
                options={entries.map((entry) => ({
                  value: entry.value,
                  label: entry.label,
                }))}
                onChange={(e) => {
                  setPageSize(e.value);
                }}
                size="small"
              />
              <SuiTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const entries = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: -1, label: "Show all" },
];

const mapStateToProps = (state) => {
  return { myEngagement: state.engagement.myEngagement };
};

const mapDispatchToProps = { codexApiCatchError, listMyEngagement };

export default connect(mapStateToProps, mapDispatchToProps)(SearchNFilter);
