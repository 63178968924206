import axios from "axios";
import store from "store";
import { BASE_API } from "settings";

const CodexAPIConfirmer = () => {
  const csrfToken = store.getState().app.csrfToken || "";
  const accessToken = store.getState().confUser.token || "";
  const instance = axios.create({
    baseURL: BASE_API,
    headers: {
      Accept: "application/json",
    },
  });

  if (csrfToken) {
    instance.defaults.headers.common["X-CSRFToken"] = csrfToken;
  }

  if (accessToken) {
    instance.defaults.headers.common["X-CDX-Token"] = accessToken;
  }

  return instance;
};

export default CodexAPIConfirmer;
