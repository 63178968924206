import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import ManageSelectBusiness from "./select-business";
import ManageGeneralEngagement from "./general";

const ManageEngagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [uuid, setUuid] = useState(
    (searchParams.get("uuid") || "").replace(/-/gi, "")
  );

  useEffect(() => {
    setUuid((searchParams.get("uuid") || "").replace(/-/gi, ""));
  }, [searchParams]);

  return (
    <>
      {uuid ? (
        <ManageSelectBusiness uuid={uuid} />
      ) : (
        <ManageGeneralEngagement />
      )}
    </>
  );
};

export default ManageEngagement;
