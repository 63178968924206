import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid, Icon } from "@mui/material";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";
import CdxButton from "components/cdx/CdxButton";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";

import { ENGAGEMENT_MEMBERSHIP_OPTIONS } from "settings/confirmation";
import CodexAPI from "app/api/codexapi";

import { engagementMember } from "actions";
import { deploySnackbar } from "actions";
import { rootElement } from "settings";

const ManageSelectBusinessFunctionBar = ({
  engagement_uuid,
  member,
  counterMember,
  setMemberRows,
  setCounterMemberRows,
  myType,
  engagementMember,
  deploySnackbar,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [searchMessage, setSearchMessage] = useState("");
  const [errorSearchMessage, setErrorSearchMessage] = useState("");
  const [errorSelect, setErrorSelect] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addMemberType, setAddMemberType] = useState(null);
  const [memberOptions, setMemberOptions] = useState([]);

  useEffect(() => {
    const op = ENGAGEMENT_MEMBERSHIP_OPTIONS.filter((e) => e.value <= myType).map(
      (e) => {
        return { value: e.value, label: t(e.label) };
      }
    );

    setMemberOptions(op);
  }, [myType]);

  const doTheSearch = () => {
    if (search) {
      const searchRegExp = new RegExp(search, "i");
      let arr1 = member.filter((e) => {
        let valid = e.email.search(searchRegExp) >= 0;
        return valid;
      });
      let arr2 = counterMember.filter((e) => {
        let valid = e.email.search(searchRegExp) >= 0;
        return valid;
      });
      setMemberRows(arr1);
      setCounterMemberRows(arr2);
      setSearchMessage(`${t("Active search")}: ${search}`);
    } else {
      setMemberRows(member);
      setCounterMemberRows(counterMember);
      setSearchMessage("");
    }
  };

  const addNewMember = async () => {
    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    };
    setSubmitting(true);

    let checkNotPass = false;
    if (!validateEmail(search)) {
      setErrorSearchMessage(t("Please enter a valid email"));
      checkNotPass = true;
    } else {
      setErrorSearchMessage("");
    }
    if (addMemberType === null) {
      setErrorSelect(true);
      checkNotPass = true;
    } else {
      setErrorSelect(false);
    }

    if (checkNotPass) {
      setSubmitting(false);
      return;
    }

    member.forEach((e) => {
      if (e.email === search) {
        setErrorSearchMessage(t("This email is a current member"));
        checkNotPass = true;
      }
    });
    counterMember.forEach((e) => {
      if (e.email === search) {
        setErrorSearchMessage(t("This email is a current member"));
        checkNotPass = true;
      }
    });

    if (checkNotPass) {
      setSubmitting(false);
      return;
    }

    try {
      let response = await CodexAPI().get("user/check-user/", {
        params: { email: search },
      });
      response = await CodexAPI().post("engagement/member-manage/add/", {
        email: search,
        type: addMemberType,
        engagement_uuid,
      });
      setSearch("");
      setAddMemberType();
      const snackBar = {
        color: "success",
        icon: "done",
        title: t("Successfully making a membership request"),
        content: `${t("Member request has sent to")} ${search}`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      if (!error.response) {
        setErrorSearchMessage(t("Unknown"));
      } else if (error.response.data.message) {
        if (error.response.data.message.text === "User not found") {
          setErrorSearchMessage(t("This email has not yet registered"));
        }
      } else {
        setErrorSearchMessage(t("Unsuccessful. Please try again."));
      }
    }
    engagementMember(engagement_uuid);
    setSubmitting(false);
  };

  useEffect(() => {
    setMemberRows(member);
    setCounterMemberRows(counterMember);
    setSearchMessage("");
  }, [member, counterMember]);

  return (
    <>
      <Grid mt={1} container spacing={2} justifyContent="center">
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs>
              <CdxInput
                placeholder={t("email")}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                error={!!errorSearchMessage}
                message={errorSearchMessage || searchMessage}
              />
            </Grid>
            <Grid item>
              <SuiButton onClick={doTheSearch} variant="gradient" color="dark">
                <Icon>search</Icon>&nbsp;{t("Search")}
              </SuiButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <CdxSelect
                value={addMemberType}
                onChange={setAddMemberType}
                options={memberOptions}
                error={errorSelect}
                message={errorSelect && t("Please select a member type")}
                menuPortalTarget={rootElement}
              />
            </Grid>
            <Grid item>
              <CdxButton
                variant="gradient"
                color="primary"
                onClick={addNewMember}
                progress={submitting}
              >
                {t("Add member")}
              </CdxButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  engagementMember,
  deploySnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSelectBusinessFunctionBar);
