import {
  LIST_MY_ENGAGEMENT_HASH,
  LIST_MY_ENGAGEMENT,
  ENGAGEMENT_MEMBER_HASH,
  ENGAGEMENT_MEMBER,
  CLEAR_ENGAGEMENT_MEMBER,
  ENGAGEMENT_DATA_HASH,
  ENGAGEMENT_DATA,
  CLEAR_ENGAGEMENT_DATA,
  ENGAGEMENT_LETTER_HASH,
  ENGAGEMENT_LETTER,
  CLEAR_ENGAGEMENT_LETTER,
} from "actions/types";

import CodexAPI from "app/api/codexapi";

import { apiServerError } from "actions/snackBar";
import {
  NO_MAX_ENGAGEMENT_MEMBER,
  NO_MAX_ENGAGEMENT_DATA,
  NO_MAX_ENGAGEMENT_LETTER,
} from "settings/redux";

export const listMyEngagement = () => async (dispatch, getState) => {
  const prevHash = getState().hashDictionary[LIST_MY_ENGAGEMENT_HASH];
  try {
    let response = await CodexAPI().get("/engagement/list-my-engagement/hash/");
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/engagement/list-my-engagement/", {
        params: { hash: newHash },
      });
      dispatch({
        type: LIST_MY_ENGAGEMENT_HASH,
        payload: newHash,
      });
      dispatch({ type: LIST_MY_ENGAGEMENT, payload: response.data.data });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const engagementMember = (uuid) => async (dispatch, getState) => {
  const clean_uuid = uuid.replace(/-/gi, "");
  const prevHash =
    getState().hashDictionary[ENGAGEMENT_MEMBER_HASH][clean_uuid];
  try {
    let response = await CodexAPI().get(
      "/engagement/list-member-engagement/hash/",
      {
        params: { uuid },
      }
    );
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/engagement/list-member-engagement/", {
        params: { uuid, hash: newHash },
      });
      dispatch({
        type: ENGAGEMENT_MEMBER_HASH,
        payload: { [clean_uuid]: newHash },
      });
      dispatch({
        type: ENGAGEMENT_MEMBER,
        payload: { [clean_uuid]: response.data.data },
      });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    } else if (error.response.status === 422) {
      dispatch({
        type: ENGAGEMENT_MEMBER,
        payload: { [clean_uuid]: { is_invalid_uuid: true } },
      });
    }
  }
  dispatch(clearEngagementMember());
};

const clearEngagementMember = () => (dispatch, getState) => {
  const engagementMemberHash = Object.entries(
    getState().hashDictionary[ENGAGEMENT_MEMBER_HASH]
  );
  if (engagementMemberHash.length > NO_MAX_ENGAGEMENT_MEMBER) {
    const hashDictinary = {};
    let uuidArr = [];
    engagementMemberHash
      .slice(-NO_MAX_ENGAGEMENT_MEMBER)
      .forEach(([uuid, hash]) => {
        hashDictinary[uuid] = hash;
        uuidArr = [...uuidArr, uuid];
      });
    const engagementMember = {};
    Object.entries(getState().engagement.engagementMember).forEach(
      ([uuid, data]) => {
        if (uuidArr.includes(uuid)) {
          engagementMember[uuid] = data;
        }
      }
    );
    dispatch({
      type: CLEAR_ENGAGEMENT_MEMBER,
      payload: { engagementMember, hashDictinary },
    });
  }
};

export const loadEngagementData = (uuid) => async (dispatch, getState) => {
  const clean_uuid = uuid.replace(/-/gi, "");
  const prevHash = getState().hashDictionary[ENGAGEMENT_DATA_HASH][clean_uuid];
  try {
    let response = await CodexAPI().get("/engagement/engagement-data/hash/", {
      params: { uuid },
    });
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/engagement/engagement-data/", {
        params: { uuid, hash: newHash },
      });
      dispatch({
        type: ENGAGEMENT_DATA_HASH,
        payload: { [clean_uuid]: newHash },
      });
      dispatch({
        type: ENGAGEMENT_DATA,
        payload: { [clean_uuid]: response.data.data },
      });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    } else if (error.response.status === 422) {
      dispatch({
        type: ENGAGEMENT_DATA,
        payload: { [clean_uuid]: { is_invalid_uuid: true } },
      });
    }
  }
  dispatch(clearLoadEngagementData());
};

const clearLoadEngagementData = () => (dispatch, getState) => {
  const engagementDataHash = Object.entries(
    getState().hashDictionary[ENGAGEMENT_DATA_HASH]
  );
  if (engagementDataHash.length > NO_MAX_ENGAGEMENT_DATA) {
    const hashDictinary = {};
    let uuidArr = [];
    engagementDataHash
      .slice(-NO_MAX_ENGAGEMENT_DATA)
      .forEach(([uuid, hash]) => {
        hashDictinary[uuid] = hash;
        uuidArr = [...uuidArr, uuid];
      });
    const engagementData = {};
    Object.entries(getState().engagement.engagementData).forEach(
      ([uuid, data]) => {
        if (uuidArr.includes(uuid)) {
          engagementData[uuid] = data;
        }
      }
    );
    dispatch({
      type: CLEAR_ENGAGEMENT_DATA,
      payload: { engagementData, hashDictinary },
    });
  }
};

export const loadEngagementLetter =
  (uuid, type = "view", stop_loop = false) =>
  async (dispatch, getState) => {
    const clean_uuid = uuid.replace(/-/gi, "");
    const prevHash =
      getState().hashDictionary[ENGAGEMENT_LETTER_HASH][clean_uuid];
    try {
      let response = await CodexAPI().get(`/engagement/letter/${type}/hash/`, {
        params: { uuid },
      });
      const newHash = response.data.data;
      if (prevHash !== newHash) {
        response = await CodexAPI().get(`/engagement/letter/${type}/`, {
          params: { uuid, hash: newHash },
        });
        dispatch({
          type: ENGAGEMENT_LETTER_HASH,
          payload: { [clean_uuid]: newHash },
        });
        dispatch({
          type: ENGAGEMENT_LETTER,
          payload: { [clean_uuid]: response.data.data },
        });
      }
    } catch (error) {
      if (!error.response) {
        dispatch(apiServerError());
      } else if (error.response.status === 422) {
        if (stop_loop) {
          dispatch({
            type: ENGAGEMENT_LETTER,
            payload: { [clean_uuid]: { is_invalid_uuid: true } },
          });
        } else {
          const newType = type === "view" ? "preview" : "view";
          console.log(newType);
          dispatch(loadEngagementLetter(uuid, newType, true));
        }
      }
    }
    dispatch(clearLoadEngagementLetter());
  };

const clearLoadEngagementLetter = () => (dispatch, getState) => {
  const engagementLetterHash = Object.entries(
    getState().hashDictionary[ENGAGEMENT_LETTER_HASH]
  );
  if (engagementLetterHash.length > NO_MAX_ENGAGEMENT_LETTER) {
    const hashDictinary = {};
    let uuidArr = [];
    engagementLetterHash
      .slice(-NO_MAX_ENGAGEMENT_LETTER)
      .forEach(([uuid, hash]) => {
        hashDictinary[uuid] = hash;
        uuidArr = [...uuidArr, uuid];
      });
    const letters = {};
    Object.entries(getState().engagement.letters).forEach(([uuid, data]) => {
      if (uuidArr.includes(uuid)) {
        letters[uuid] = data;
      }
    });
    dispatch({
      type: CLEAR_ENGAGEMENT_LETTER,
      payload: { letters, hashDictinary },
    });
  }
};
