import CdxCollapseCard from "components/cdx/CdxCollapseCard";
import { useTranslation } from "react-i18next";

import ConfirmationTemplateParse from "app/utils/ConfirmationTemplateParse";
import SuiTypography from "components/sui/SuiTypography";
import { useState, useEffect } from "react";

const LetterTemplatePreview = ({ template }) => {
  const { t } = useTranslation();
  const defaultTemplate = {
    recipient_name: t("customer"),
    confirm_entity_name: t("Confirmer Company Limited"),
  };

  const [templateValues, setTemplateValues] = useState({ ...defaultTemplate });

  // .match(/\$balance__[^\r\n\t\f\v \\]+/g);

  useEffect(() => {
    if (template) {
      const data = {};
      (template.match(/\$balance__[^\r\n\t\f\v \\][^<]+/g) || []).forEach(
        (k) => {
          data[k.slice(1)] = (88888.88).toLocaleString();
        }
      );
      (template.match(/\$transaction__[^\r\n\t\f\v \\][^<]+/g) || []).forEach(
        (k) => {
          data[k.slice(1)] = (777777.77).toLocaleString();
        }
      );
      setTemplateValues({ ...defaultTemplate, ...data });
    }
  }, [template]);


  return (
    <CdxCollapseCard title={t("Preview")} p={3}>
      {template ? (
        <ConfirmationTemplateParse
          template={template}
          values={templateValues}
        />
      ) : (
        <SuiTypography variant="caption">
          {t("Please insert a text in editor")}
        </SuiTypography>
      )}
    </CdxCollapseCard>
  );
};

export default LetterTemplatePreview;
