import { useState, useEffect } from "react";
import {
  useLocation,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { connect } from "react-redux";

import { useSoftUIController, setMiniSidenav } from "context";

import CdxLayout from "components/layouts/Containter/CdxLayout";
import CdxFooter from "components/cdx/CdxFooter";
import CdxNavbar from "components/cdx/CdxNavbar";
import CdxSidenav from "components/cdx/CdxSidenav";

import logo192 from "assets/logo192.png"; // CDX IMPORTANT - To Replace brand

import { confirmerRoutes } from "routes";

import RoutesRender from "app/utils/RoutesRender";

const ConfirmerApp = ({ isConfirmerSignIn }) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [combinedRoutes, setCombinedRoutes] = useState([...confirmerRoutes]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route and check if user has sign up
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if (!isConfirmerSignIn && !pathname.startsWith("/confirmer/sign-in")) {
      navigate(`/confirmer/sign-in?redirect_to=${pathname}`);
    }
  }, [pathname, isConfirmerSignIn]);

  return (
    <>
      <CdxSidenav
        color={sidenavColor}
        brand={logo192} // CDX IMPORTANT - To Replace brand
        brandName="Codex Confirmation"
        routes={combinedRoutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <CdxLayout>
        <CdxNavbar app="confirmer" />
        <RoutesRender routes={combinedRoutes} />
        <CdxFooter />
      </CdxLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return { isConfirmerSignIn: state.app.isConfirmerSignIn };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmerApp);
