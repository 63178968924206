import CdxCollapseCard from "components/cdx/CdxCollapseCard";
import SuiEditor from "components/sui/SuiEditor";
import SuiButton from "components/sui/SuiButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";

import CodexAPI from "app/api/codexapi";
import { connect } from "react-redux";

import { deploySnackbar, loadEngagementData } from "actions";

import { codexApiCatchError } from "actions/snackBar";

const LetterTemplateEditor = ({
  template,
  setTemplate,
  setOnEdit,
  selectedTemplate,
  engagement_uuid,
  deploySnackbar,
  codexApiCatchError,
  loadEngagementData,
}) => {
  const { t } = useTranslation();
  const [templateB4Edit, setTemplateB4Edit] = useState("");

  useEffect(() => {
    setTemplateB4Edit(template);
  }, []);

  const submitEditTemplate = async () => {
    try {
      const response = await CodexAPI().post("engagement/templates/edit/", {
        engagement_uuid: engagement_uuid,
        template_name: selectedTemplate,
        template: template,
      });
      loadEngagementData(engagement_uuid);
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Successfully edit template",
        content: `Template has been edited`,
      };
      deploySnackbar(snackBar);
      setOnEdit(false);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  return (
    <CdxCollapseCard title={t("Editor")} p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SuiEditor
            value={template}
            onChange={(e) => {
              setTemplate(e);
            }}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
                ["clean"],
              ],
            }}
            formats={["header", "bold", "italic", "underline"]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SuiButton
            fullWidth
            size="small"
            color="error"
            variant="gradient"
            onClick={() => {
              setOnEdit(false);
              setTemplate(templateB4Edit);
            }}
          >
            {t("Cancel")}
          </SuiButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SuiButton
            fullWidth
            size="small"
            color="primary"
            variant="gradient"
            onClick={submitEditTemplate}
            disabled={templateB4Edit === template}
          >
            {t("Submit")}
          </SuiButton>
        </Grid>
      </Grid>
    </CdxCollapseCard>
  );
};

const mapStateToProps = (state) => {
  return { engagement_uuid: state.app.activeEngagementUUID };
};

const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
  loadEngagementData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LetterTemplateEditor);
