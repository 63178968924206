import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Switch } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxInput from "components/cdx/CdxInput";
import CdxPasswordInput from "components/cdx/CdxPasswordInput";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import signIn from "app/fb/signIn";

import confirmerSignOut from "app/fb/confirmer/signOut";

import { useSearchParams, useNavigate } from "react-router-dom";
import { BASE_APP_PATH } from "settings/routes";

const SignInForm = ({ confirmerEmail }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || BASE_APP_PATH
  );
  const navigate = useNavigate();

  const validateForm = (values) => {
    const error = {};

    if (!values.email) {
      error.email = "Please enter email";
    } else {
      const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      };
      if (!validateEmail(values.email)) {
        error.email = "Please enter a valid email";
      }
    }

    if (!values.password) {
      error.password = "Please enter password";
    }
    return error;
  };

  const onSubmit = async (values) => {
    if (values.email !== confirmerEmail) {
      confirmerSignOut();
    }
    const error = {};
    const result = await signIn(values.email, values.password);
    if (result.type === "success") {
      navigate(redirectTo);
    } else if (result.type === "error") {
      if (result.data.code === "auth/user-not-found") {
        error.email = "Couldn't find your email";
      } else if (result.data.code === "auth/wrong-password") {
        error.password = "Your password is incorrect";
      } else {
        error[FORM_ERROR] = "Unable to sign in, please try again";
      }
    }
    return error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <SuiBox
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Field name="email">
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  mb={2}
                  label={t("Email")}
                  error={
                    !!(meta.error && meta.touched) ||
                    !!meta.submitError ||
                    !!submitError
                  }
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            <Field name="password" type="password">
              {({ input, meta }) => (
                <CdxPasswordInput
                  name={input.name}
                  type={input.type}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  mb={2}
                  label={t("Password")}
                  error={
                    !!(meta.error && meta.touched) ||
                    !!meta.submitError ||
                    !!submitError
                  }
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            {submitError && (
              <SuiBox>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </SuiBox>
            )}
            <SuiBox
              width="100%"
              mt={3}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <SuiBox>
                <SuiButton
                  variant="gradient"
                  color="primary"
                  disabled={!!formState.errors.email}
                >
                  {t("Sign in with email")}
                </SuiButton>
              </SuiBox> */}
              {/* <SuiBox> */}
              <SuiButton
                variant="gradient"
                color="info"
                type="submit"
                fullWidth
                disabled={
                  submitting ||
                  pristine ||
                  !!formState.errors.email ||
                  !!formState.errors.password
                }
              >
                {t("Sign in")}
              </SuiButton>
              {/* </SuiBox> */}
            </SuiBox>
          </SuiBox>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return { confirmerEmail: state.confUser.email };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
