import {
  SIGN_IN,
  SIGN_OUT,
  RELOAD_TOKEN,
  LOAD_CDX_USER,
  UPDATE_USER_SETTING,
} from "actions/types";

const userDefault = {
  email: "",
  fbData: {},
  token: "",
  tokenExpireAt: "",
  userData: {},
  userSetting: {},
};

const userReducer = (user = userDefault, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...user, ...action.payload.user };
    case RELOAD_TOKEN:
      return { ...user, ...action.payload.user };
    case SIGN_OUT:
      return { ...userDefault };
    case LOAD_CDX_USER:
      return { ...user, ...action.payload };
    case UPDATE_USER_SETTING:
      return {
        ...user,
        userSetting: { ...user.userSetting, ...action.payload },
      };
    default:
      return user;
  }
};

export default userReducer;
