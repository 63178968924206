import {
  LIST_MY_BUSINESS_HASH,
  LIST_MY_BUSINESS,
  VIEW_BUSINESS_HASH,
  VIEW_BUSINESS,
  CLEAR_VIEW_BUSINESS,
  BUSINESS_MEMBER_HASH,
  BUSINESS_MEMBER,
  CLEAR_BUSINESS_MEMBER,
} from "actions/types";

import CodexAPI from "app/api/codexapi";
import CodexAPIConfirmer from "app/api/codexapiconfirmer";

import { apiServerError } from "actions/snackBar";

import { NO_MAX_VIEW_BUSINESS, NO_MAX_BUSINESS_MEMBER } from "settings/redux";

export const listMyBusiness = () => async (dispatch, getState) => {
  const prevHash = getState().hashDictionary[LIST_MY_BUSINESS_HASH];
  try {
    let response = await CodexAPI().get("/business/list-my-business/hash/");
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/business/list-my-business/", {
        params: { hash: newHash },
      });
      dispatch({
        type: LIST_MY_BUSINESS_HASH,
        payload: newHash,
      });
      dispatch({ type: LIST_MY_BUSINESS, payload: response.data.data });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const viewBusiness =
  (uuid, engagement_uuid = null) =>
  async (dispatch, getState) => {
    const clean_uuid = uuid.replace(/-/gi, "");
    const prevHash = getState().hashDictionary[VIEW_BUSINESS_HASH][clean_uuid];
    try {
      let response = await CodexAPI().get("/business/view-business/hash/", {
        params: { uuid },
      });
      const newHash = response.data.data;
      if (prevHash !== newHash) {
        response = await CodexAPI().get("/business/view-business/", {
          params: { uuid, hash: newHash },
        });
        dispatch({
          type: VIEW_BUSINESS_HASH,
          payload: { [clean_uuid]: newHash },
        });
        dispatch({
          type: VIEW_BUSINESS,
          payload: { [clean_uuid]: response.data.data },
        });
      }
    } catch (error) {
      if (!error.response) {
        dispatch(apiServerError());
      } else if (error.response.status === 422) {
        dispatch({
          type: VIEW_BUSINESS,
          payload: { [clean_uuid]: { is_invalid_uuid: true } },
        });
      }
    }
    dispatch(clearViewBusiness());
  };

const clearViewBusiness = () => (dispatch, getState) => {
  const viewBusinessHash = Object.entries(
    getState().hashDictionary[VIEW_BUSINESS_HASH]
  );
  if (viewBusinessHash.length > NO_MAX_VIEW_BUSINESS) {
    const hashDictinary = {};
    let uuidArr = [];
    viewBusinessHash.slice(-NO_MAX_VIEW_BUSINESS).forEach(([uuid, hash]) => {
      hashDictinary[uuid] = hash;
      uuidArr = [...uuidArr, uuid];
    });
    const businessData = {};
    Object.entries(getState().business.businessData).forEach(([uuid, data]) => {
      if (uuidArr.includes(uuid)) {
        businessData[uuid] = data;
      }
    });
    dispatch({
      type: CLEAR_VIEW_BUSINESS,
      payload: { businessData, hashDictinary },
    });
  }
};

export const businessMember = (uuid) => async (dispatch, getState) => {
  const clean_uuid = uuid.replace(/-/gi, "");
  const prevHash = getState().hashDictionary[BUSINESS_MEMBER_HASH][clean_uuid];
  try {
    let response = await CodexAPI().get("/business/list-member-entity/hash/", {
      params: { uuid },
    });
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPI().get("/business/list-member-entity/", {
        params: { uuid, hash: newHash },
      });
      dispatch({
        type: BUSINESS_MEMBER_HASH,
        payload: { [clean_uuid]: newHash },
      });
      dispatch({
        type: BUSINESS_MEMBER,
        payload: { [clean_uuid]: response.data.data },
      });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    } else if (error.response.status === 422) {
      dispatch({
        type: BUSINESS_MEMBER,
        payload: { [clean_uuid]: { is_invalid_uuid: true } },
      });
    }
  }
  dispatch(clearBusinessMember());
};

const clearBusinessMember = () => (dispatch, getState) => {
  const businessMemberHash = Object.entries(
    getState().hashDictionary[BUSINESS_MEMBER_HASH]
  );
  if (businessMemberHash.length > NO_MAX_BUSINESS_MEMBER) {
    const hashDictinary = {};
    let uuidArr = [];
    businessMemberHash
      .slice(-NO_MAX_BUSINESS_MEMBER)
      .forEach(([uuid, hash]) => {
        hashDictinary[uuid] = hash;
        uuidArr = [...uuidArr, uuid];
      });
    const businessMember = {};
    Object.entries(getState().business.businessMember).forEach(
      ([uuid, data]) => {
        if (uuidArr.includes(uuid)) {
          businessMember[uuid] = data;
        }
      }
    );
    dispatch({
      type: CLEAR_BUSINESS_MEMBER,
      payload: { businessMember, hashDictinary },
    });
  }
};

export const viewBusinessByLetter =
  (uuid, letter_uuid = null) =>
  async (dispatch, getState) => {
    const clean_uuid = uuid.replace(/-/gi, "");
    const prevHash = getState().hashDictionary[VIEW_BUSINESS_HASH][clean_uuid];
    try {
      let response = await CodexAPIConfirmer().get(
        "/confirmer/view-business/hash/",
        {
          params: { uuid, letter_uuid },
        }
      );
      const newHash = response.data.data;
      if (prevHash !== newHash) {
        response = await CodexAPIConfirmer().get("/confirmer/view-business/", {
          params: { uuid, letter_uuid, hash: newHash },
        });
        dispatch({
          type: VIEW_BUSINESS_HASH,
          payload: { [clean_uuid]: newHash },
        });
        dispatch({
          type: VIEW_BUSINESS,
          payload: { [clean_uuid]: response.data.data },
        });
      }
    } catch (error) {
      if (!error.response) {
        dispatch(apiServerError());
      } else if (error.response.status === 422) {
        dispatch({
          type: VIEW_BUSINESS,
          payload: { [clean_uuid]: { is_invalid_uuid: true } },
        });
      }
    }
    dispatch(clearViewBusiness());
  };
