import { connect } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SuiButton from "components/sui/SuiButton";
import Icon from "@mui/material/Icon";

import CodexAPI from "app/api/codexapi";
import { deploySnackbar, engagementMember } from "actions";
import { codexApiCatchError } from "actions/snackBar";
import { BUSINESS_MEMBERSHIP_TYPE } from "settings/confirmation";

import ChangeModal from "./ChangeModal";

const ChangeButton = ({
  email,
  engagement_uuid,
  curType,
  myType,
  deploySnackbar,
  codexApiCatchError,
  engagementMember,
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [top, setTop] = useState(0);
  const [type, setType] = useState();

  const handleSubmit = async () => {
    try {
      const response = await CodexAPI().post(
        "engagement/member-manage/change-type/",
        {
          email,
          engagement_uuid,
          type,
        }
      );
      engagementMember(engagement_uuid);
      const snackBar = {
        color: "success",
        icon: "done",
        title: t("Successfully change user's type"),
        content: `${email} ${t("has been change to")} ${t(
          BUSINESS_MEMBERSHIP_TYPE.at(type).toLowerCase()
        )}`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
    handleClose();
  };

  const handleClose = () => {
    setOpenModal(false);
    document.body.scrollTop = top;
    document.documentElement.scrollTop = top;
    document.scrollingElement.scrollTop = top;
    setTimeout(() => {
      document.body.scrollTop = top;
      document.documentElement.scrollTop = top;
      document.scrollingElement.scrollTop = top;
    }, 100);
  };

  const handleOpen = () => {
    setTop(document.documentElement.scrollTop || document.body.scrollTop);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    setOpenModal(true);
  };

  return (
    <>
      <SuiButton
        size="small"
        variant="gradient"
        color="info"
        iconOnly
        circular
        onClick={handleOpen}
      >
        <Icon>move_up</Icon>
      </SuiButton>
      <ChangeModal
        email={email}
        curType={curType}
        open={openModal}
        type={type}
        setType={setType}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        myType={myType}
      />
    </>
  );
};
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
  engagementMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeButton);
