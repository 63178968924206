import {
  CONFIRMER_SUMMARY,
  CONFIRMER_INBOX,
  CONFIRMER_HISTORY,
  CONFIRMER_LETTER,
  CLEAR_CONFIRMER_LETTER,
  SIGN_OUT,
  CONF_SIGN_OUT,
} from "actions/types";

const confirmerDefault = {
  summary: {},
  inbox: [],
  history: [],
  letters: {},
};

const confirmerReducer = (confirmer = confirmerDefault, action) => {
  switch (action.type) {
    case CONFIRMER_SUMMARY:
      return { ...confirmer, summary: action.payload };
    case CONFIRMER_INBOX:
      return { ...confirmer, inbox: action.payload };
    case CONFIRMER_HISTORY:
      return { ...confirmer, history: action.payload };
    case CONFIRMER_LETTER:
      return {
        ...confirmer,
        letters: { ...confirmer.letters, ...action.payload },
      };
    case CLEAR_CONFIRMER_LETTER:
      return {
        ...confirmer,
        letters: action.payload.letters,
      };
    case SIGN_OUT:
      return {
        ...confirmerDefault,
      };
    case CONF_SIGN_OUT:
      return {
        ...confirmerDefault,
      };
    default:
      return confirmer;
  }
};

export default confirmerReducer;
