import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

// CompleteSignUpInApp page components
import SetUpPassword from "./components/SetUpPassword";
import Setting from "./components/Setting";
import Information from "./components/Information";

import CodexAPI from "app/api/codexapi";

import { deploySnackbar, reloadToken, loadCdxUser } from "actions";

import signIn from "app/fb/signIn";
import { codexApiCatchError } from "actions/snackBar";

import { BASE_APP_PATH } from "settings/routes";

const getSteps = () => {
  return ["Set up password", "Information", "Setting"];
};

const CompleteSignUpInApp = ({
  email,
  displayName,
  deploySnackbar,
  reloadToken,
  codexApiCatchError,
  loadCdxUser,
}) => {
  const { t } = useTranslation();
  const [disabledNext, setDisabledNext] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [passwordValues, setPasswordValues] = useState({});
  const [setting, setSetting] = useState();
  const [informationValues, setInformationValues] = useState({
    full_name: "",
  });

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || BASE_APP_PATH
  );

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setDisabledNext(true);
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SetUpPassword
            form={{
              currentValues: passwordValues,
              setValues: setPasswordValues,
              setDisabledNext,
            }}
          />
        );
      case 1:
        return (
          <Information
            form={{
              currentValues: informationValues,
              setValues: setInformationValues,
              setDisabledNext,
            }}
          />
        );
      case 2:
        return (
          <Setting
            form={{
              currentValue: setting,
              setValue: setSetting,
              setDisabledNext,
            }}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);
    let isOk = false;
    const names = [
      { lang: informationValues.lang1, name: informationValues.name1 },
    ];
    if (informationValues.name2) {
      names[1] = {
        lang: informationValues.lang2,
        name: informationValues.name2,
      };
    }
    try {
      const response = await CodexAPI().post("/user/complete-sign-up/", {
        password: passwordValues.password,
        setting,
        names,
      });
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Complete sign up",
        content: `${t("You will be redirect to")} ${redirectTo} ${t("soon")}${t(
          "."
        )}`,
      };
      deploySnackbar(snackBar);
      isOk = true;
    } catch (error) {
      codexApiCatchError(error);
      setSubmitting(false);
    }

    if (isOk) {
      loadCdxUser();
      setTimeout(async () => {
        const result = await signIn(email, passwordValues.password);
        reloadToken(result.data.user);
      }, 1000 * 5);
      setTimeout(() => {
        navigate(redirectTo);
      }, 1000 * 10);
    }
  };

  useEffect(() => {
    if (displayName) {
      const snackBar = {
        color: "error",
        icon: "report_problem",
        title: "Cannot access to complete sign up",
        content: `${t(
          "Your account has completed sign up. Automatic redirect to "
        )} ${redirectTo}${t(".")}`,
      };
      deploySnackbar(snackBar);
      navigate(redirectTo);
    }
  }, []);

  return (
    <SuiBox pt={3} pb={8}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <SuiBox mt={6} mb={1} textAlign="center">
            <SuiBox mb={1}>
              <SuiTypography variant="h3" fontWeight="bold">
                {t("Build Your Account")}
              </SuiTypography>
            </SuiBox>
            <SuiTypography variant="h5" fontWeight="regular" color="secondary">
              {t("This information will let us know more about you.")}
            </SuiTypography>
            {searchParams.get("redirect_to") ? (
              <SuiTypography
                variant="caption"
                fontWeight="regular"
                color="error"
              >
                {t(
                  "Please complete this in order to access Codex Confirmation."
                )}
              </SuiTypography>
            ) : null}
          </SuiBox>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card>
            <SuiBox p={3}>
              <SuiBox>
                {getStepContent(activeStep)}
                <SuiBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  {activeStep === 0 ? (
                    <SuiBox />
                  ) : (
                    <SuiButton
                      variant="gradient"
                      color="light"
                      onClick={handleBack}
                    >
                      {t("back")}
                    </SuiButton>
                  )}
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    onClick={!isLastStep ? handleNext : onSubmit}
                    disabled={disabledNext || submitting}
                  >
                    {isLastStep ? t("submit") : t("next")}
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    displayName: state.user.fbData.displayName,
  };
};

const mapDispatchToProps = {
  deploySnackbar,
  reloadToken,
  codexApiCatchError,
  loadCdxUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteSignUpInApp);
