import { Icon } from "@mui/material";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import { useState } from "react";

const CdxCollapseCard = ({
  children,
  title,
  titleSize = "h6",
  spaceBetween = 2,
  defaultCollapse = false,
  ...rest
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);
  return (
    <SuiBox bgColor="white" borderRadius="xl" shadow="lg" {...rest}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiBox>
          <SuiTypography variant={titleSize}>{title}</SuiTypography>
        </SuiBox>
        <SuiBox
          onClick={() => {
            setCollapse(!collapse);
          }}
          lineHeight={0}
          sx={{ cursor: "pointer" }}
        >
          <Icon>{collapse ? "chevron_left" : "expand_more"}</Icon>
        </SuiBox>
      </SuiBox>
      {!collapse && children && <SuiBox mt={spaceBetween}>{children}</SuiBox>}
    </SuiBox>
  );
};

export default CdxCollapseCard;
