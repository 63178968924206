import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import CdxBadgeDot from "components/cdx/CdxBadgeDot";
import SuiButton from "components/sui/SuiButton";
import { useTranslation } from "react-i18next";
import EngagementRedirect from "pages/engagement/redirect";

import CdxDropzone from "components/cdx/CdxDropzone";
import CdxCard from "components/cdx/CdxCard";

import { connect } from "react-redux";
import { useState, useEffect } from "react";

import * as XLSX from "xlsx/xlsx.mjs";

import AddConfirmProcess from "./process";

const EngagementAddConfirm = ({ engagementData }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [workbook, setWorkbook] = useState();
  const [invalidFile, setInvalidFile] = useState([]);
  const [isUpload, setIsUpload] = useState(false);

  useEffect(async () => {
    if (file) {
      setIsUpload(true);
      setFileName(file.name);
      try {
        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        setWorkbook(wb);
      } catch {
        setInvalidFile(["Invalid file type"]);
      }
    } else {
      setIsUpload(false);
      setFileName("");
    }
  }, [file]);

  const uploadAgain = () => {
    setFile();
    setFileName("");
    setWorkbook();
    setInvalidFile([]);
    setIsUpload(false);
  };

  return (
    <EngagementRedirect>
      {engagementData && (
        <>
          <SuiBox mt={2}>
            <SuiTypography variant="h5">
              {t("Add confirmation")} - {engagementData.mutual_engagement_name}
            </SuiTypography>
          </SuiBox>
          <CdxCard mt={2} p={3}>
            {!isUpload && <CdxDropzone setFile={setFile} />}
            {fileName && (
              <SuiBox>
                <SuiTypography variant="h6">
                  {t("File name:")} {fileName}
                </SuiTypography>
              </SuiBox>
            )}
            {isUpload && invalidFile.length > 0 && (
              <>
                {invalidFile.map((e) => {
                  return (
                    <CdxBadgeDot
                      key={`error-upload-${e}`}
                      badgeContent={t(e)}
                      size="sm"
                      color="error"
                      font={{ color: "error" }}
                    />
                  );
                })}
              </>
            )}
            {isUpload && invalidFile.length === 0 && (
              <SuiBox>
                <SuiTypography variant="caption">
                  {t("Please review before proceed")}
                </SuiTypography>
              </SuiBox>
            )}
            {isUpload && (
              <SuiBox mt={1}>
                <SuiButton
                  variant="gradient"
                  color="error"
                  onClick={uploadAgain}
                >
                  {t("Upload again")}
                </SuiButton>
              </SuiBox>
            )}
          </CdxCard>
          <AddConfirmProcess
            workbook={workbook}
            setInvalidFile={setInvalidFile}
          />
        </>
      )}
    </EngagementRedirect>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementAddConfirm);
