import CodexAPIConfirmer from "app/api/codexapiconfirmer";

import signOutOtp from "app/fb/signOutOtp";

import store from "store";
import { codexApiCatchError } from "actions/snackBar";
import { deploySnackbar, loadConfirmerLetter } from "actions";

const submitConfirmation = (
  otp,
  uuid,
  response,
  mark_phone,
  setOtpErrorMessage,
  confirmationResult = null
) => {
  if (confirmationResult === null) {
    confirmationResult = window.confirmationResult;
  }

  const dispatch = store.dispatch;
  confirmationResult
    .confirm(otp)
    .then(async (result) => {
      // User signed in successfully.
      const otp_token = result.user.accessToken;
      try {
        const apiResponse = await CodexAPIConfirmer().post(
          "confirmer/letter/submit/",
          {
            otp_token,
            letter_uuid: uuid,
            response,
            mark_phone,
          }
        );
        setTimeout(() => {
          signOutOtp();
        }, 1000 * 1);

        const snackBar = {
          color: "success",
          icon: "done",
          title: "Successfully submit a confirm",
          content: `Your response has submited. Thank you.`,
        };
        dispatch(loadConfirmerLetter(uuid));
        dispatch(deploySnackbar(snackBar));
      } catch (error) {
        dispatch(codexApiCatchError(error));
      }
    })
    .catch((error) => {
      // User couldn't sign in (bad verification code?)
      if (error.code === "auth/invalid-verification-code") {
        setOtpErrorMessage("Invalid OTP");
      } else {
        setOtpErrorMessage(error.code);
      }
    });
};

export default submitConfirmation;
