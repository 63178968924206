export default function timeSince(date, subfix = "short") {
  const subfixes = {
    short: [" y", " mo", " d", " h", " m", " s"],
    long: [" years", "months", " days", " hours", " minutes", " seconds"],
  };

  if (typeof date === "string") {
    if (!date.endsWith("Z")) {
      date = date + "Z";
    }
    date = new Date(date).getTime();
  }

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + subfixes[subfix].at(0);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + subfixes[subfix].at(1);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + subfixes[subfix].at(2);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + subfixes[subfix].at(3);
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + subfixes[subfix].at(4);
  }
  return Math.floor(seconds) + subfixes[subfix].at(5);
}
