import SuiButton from "components/sui/SuiButton";
import CdxSwitch from "components/cdx/CdxSwitch";

import { Grid, Icon } from "@mui/material";

import { Fragment } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { BASE_PATH } from "settings";

const EngagementFunctionBar = ({ displayOption, setDisplayOption }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onChange = (key) => {
    const newValue = { ...displayOption };
    newValue[key] = !newValue[key];
    setDisplayOption(newValue);
  };

  return (
    <Fragment>
      <Grid
        spacing={2}
        container
        item
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={9}
        lg={7}
      >
        <Grid item>
          <SuiButton
            size="small"
            color="primary"
            variant="gradient"
            onClick={() => {
              navigate(
                `${BASE_PATH}/overview/engagement/create-new-engagement`
              );
            }}
          >
            <Icon>add</Icon>&nbsp;{t("Add new engagement")}
          </SuiButton>
        </Grid>
        <Grid item>
          <CdxSwitch
            spaceLabelAtTop={false}
            value={displayOption.archive}
            label={[t("Include archive"), t("Exclude archive")]}
            onChange={() => {
              onChange("archive");
            }}
          />
        </Grid>
        <Grid item>
          <CdxSwitch
            spaceLabelAtTop={false}
            value={displayOption.admin}
            label={[t("Include admin"), t("Exclude admin")]}
            onChange={() => {
              onChange("admin");
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EngagementFunctionBar;
