// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9Y0xCe7UYGi9e6oMBFTRVfHMWzxwPFJA",
  authDomain: "auditconfirm.firebaseapp.com",
  projectId: "auditconfirm",
  storageBucket: "auditconfirm.appspot.com",
  messagingSenderId: "306163596860",
  appId: "1:306163596860:web:5a898cb2bdb0436fa78d39",
};

const confirmerConfig = {
  apiKey: "AIzaSyC0ENOACtcDdbQgY2JVWKVDubPwnm7jkGk",
  authDomain: "auditconfirm-confirmer.firebaseapp.com",
  projectId: "auditconfirm-confirmer",
  storageBucket: "auditconfirm-confirmer.appspot.com",
  messagingSenderId: "748944292475",
  appId: "1:748944292475:web:d555c9c7e82cd28bd200de",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const confirmer = initializeApp(confirmerConfig, "confirmer");
export const authConfirmer = getAuth(confirmer);
const otp = initializeApp(confirmerConfig, "otp");
export const authOtp = getAuth(otp);
