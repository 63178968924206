import store from "store";

import { authConfirmer } from "../config";

import { confReloadToken as actionReloadToken } from "actions/confUser";

import { authServerError } from "actions/snackBar";

const reloadToken = async () => {
  const dispatch = store.dispatch;
  const user = authConfirmer.currentUser;
  try {
    if (user) {
      await user.getIdToken(true);
      dispatch(actionReloadToken(user));
    }
  } catch {
    dispatch(authServerError());
  }
};

export default reloadToken;
