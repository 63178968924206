import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SuiTypography from "components/sui/SuiTypography";

import { useSoftUIController } from "context";

import breakpoints from "assets/theme/base/breakpoints";

import { BASE_PATH } from "settings";

import { useTranslation } from "react-i18next";

import { loadEngagementLetter } from "actions";
import SuiBox from "components/sui/SuiBox";
import EngagementLetterView from "./letter";
import EngagementLetterResponse from "./response";

const ConfirmerLetter = ({
  letters,
  activeEngagementUUID,
  loadEngagementLetter,
}) => {
  const { t } = useTranslation();
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [letter_uuid, setUuid] = useState(searchParams.get("letter_uuid"));
  const { pathname } = useLocation();
  const [letter, setLetter] = useState({});
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs([
      {
        name: "View response",
        key: `/${BASE_PATH}/engagement/track/response`,
        route: `${BASE_PATH}/engagement/track/response`,
        element: <EngagementLetterResponse />,
      },
      {
        name: "View Letter",
        key: `/${BASE_PATH}/engagement/track/view`,
        route: `${BASE_PATH}/engagement/track/view`,
        element: <EngagementLetterView />,
      },
    ]);
  }, []);

  useEffect(() => {
    setUuid(searchParams.get("letter_uuid"));
  }, [searchParams]);

  const navigate = useNavigate();

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const delaySetTabValue = (value, delay = 400) => {
    const dSetTabValue = () => {
      setTabValue(value === 0 ? 1 : value - 1);
      setTabValue(value);
    };

    const timeOutId = setTimeout(dSetTabValue, delay);
    return timeOutId;
  };

  useEffect(() => {
    const tempTab = [...tabs].reverse();
    const idx =
      tempTab.length -
      1 -
      tempTab.findIndex((t) => {
        return pathname.toLowerCase().startsWith(t.route.toLowerCase());
      });

    let timeOutId = undefined;
    if (idx >= 0) {
      setTabValue(idx);
      timeOutId = delaySetTabValue(idx, 500);
    }

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [pathname, tabs]);

  useEffect(() => {
    const currentTabValue = tabValue;
    const timeOutId = delaySetTabValue(currentTabValue);
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [miniSidenav]);

  useEffect(() => {
    if (letter_uuid) {
      loadEngagementLetter(letter_uuid);
    }
  }, [letter_uuid]);

  useEffect(() => {
    const l = letters[letter_uuid.replace(/-/gi, "")];
    if (l) {
      setLetter(l);
    } else {
      setLetter({});
    }
  }, [letters, letter_uuid]);

  const renderTab = (e) => {
    return (
      <Tab
        key={e.key}
        label={t(e.name)}
        onClick={() => {
          navigate(`${e.route}?letter_uuid=${letter_uuid}`);
        }}
      />
    );
  };

  const renderPage = () => {
    return (tabs.at(tabValue) || {}).element;
  };

  return (
    <>
      {letter_uuid ? (
        <>
          {Object.keys(letter).length > 0 && (
            <>
              {letter.is_invalid_uuid ? (
                <SuiBox mt={2}>
                  <SuiTypography caption="h5">
                    {t("Invalid letter uuid")}
                  </SuiTypography>
                </SuiBox>
              ) : (
                <>
                  {activeEngagementUUID ===
                  letter.engagementUUID.replace(/-/gi, "") ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <AppBar position="static">
                          <Tabs
                            orientation={tabsOrientation}
                            value={tabValue}
                            onChange={handleSetTabValue}
                          >
                            {tabs.map((e) => renderTab(e))}
                          </Tabs>
                        </AppBar>
                      </Grid>
                      <Grid item xs>
                        {renderPage()}
                      </Grid>
                    </Grid>
                  ) : (
                    <SuiBox mt={2}>
                      <SuiTypography caption="h5">
                        {t("Incorrect active engagement")}
                      </SuiTypography>
                    </SuiBox>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <SuiTypography caption="h5">{t("No letter is selected")}</SuiTypography>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeEngagementUUID: state.app.activeEngagementUUID,
    letters: state.engagement.letters,
  };
};

const mapDispatchToProps = {
  loadEngagementLetter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmerLetter);
