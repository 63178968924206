import { useState, useEffect } from "react";
import {
  useLocation,
  useSearchParams,
  Routes,
  Route,
  Navigate,
  useNavigate,
  s,
} from "react-router-dom";
import { connect } from "react-redux";

import { useSoftUIController, setMiniSidenav } from "context";

import CdxLayout from "components/layouts/Containter/CdxLayout";
import CdxFooter from "components/cdx/CdxFooter";
import CdxNavbar from "components/cdx/CdxNavbar";
import CdxSidenav from "components/cdx/CdxSidenav";

import logo192 from "assets/logo192.png"; // CDX IMPORTANT - To Replace brand

import {
  overviewRoutes,
  engagementRoutes,
  confirmerRoutes,
  appRoutes,
} from "routes";

import RoutesRender from "app/utils/RoutesRender";

const AuthApp = ({ displayName, activeEngagementUUID, engagementData }) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [isConfirmApp, setIsConfirmApp] = useState(false);

  const [combinedRoutes, setCombinedRoutes] = useState([
    ...overviewRoutes,
    ...appRoutes,
  ]);

  useEffect(() => {
    let newCR = [...overviewRoutes];
    if (activeEngagementUUID) {
      let enRoutes = [];
      if (engagementData) {
        if (engagementData.archive_at) {
          engagementRoutes.forEach((e) => {
            if (!e.archiveNotShow) {
              enRoutes.push(e);
            }
          });
        } else {
          enRoutes = [...engagementRoutes];
        }
      } else {
        enRoutes = [...engagementRoutes];
      }
      newCR = [...newCR, ...enRoutes];
    }
    newCR = [...newCR, ...appRoutes];
    setCombinedRoutes(newCR);
  }, [isConfirmApp, activeEngagementUUID, engagementData]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route and check if user has sign up
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if (!displayName && !pathname.startsWith("/sign-out")) {
      if (
        searchParams.get("redirect_to") &&
        !pathname.startsWith("/complete-sign-up")
      ) {
        navigate(searchParams.get("redirect_to"));
      } else if (!pathname.startsWith("/complete-sign-up")) {
        navigate(`/complete-sign-up?redirect_to=${pathname}`);
      }
    }
  }, [pathname]);

  return (
    <>
      <CdxSidenav
        color={sidenavColor}
        brand={logo192} // CDX IMPORTANT - To Replace brand
        brandName="Codex Confirmation"
        routes={combinedRoutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <CdxLayout>
        <CdxNavbar />
        <RoutesRender routes={combinedRoutes} />
        <CdxFooter />
      </CdxLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    displayName: state.user.fbData.displayName,
    activeEngagementUUID: state.app.activeEngagementUUID,
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthApp);
