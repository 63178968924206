import { useTranslation } from "react-i18next";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { Grid } from "@mui/material";

import DefaultCounterCard from "components/cdx/CdxCounterCards/DefaultCounterCard";
import { useEffect, useState } from "react";

const LetterSummary = ({ letters }) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState({});

  useEffect(() => {
    if (letters) {
      const response = letters.filter((e) => e.confirmer_submit).length;
      const count = {
        sent: letters.length,
        response,
        response_rate: response / Math.max(1, letters.length),
      };
      setCounter(count);
    } else {
      setCounter({});
    }
  }, [letters]);

  return (
    <>
      {!letters ? (
        <></>
      ) : (
        <SuiBox mt={2}>
          <SuiTypography variant="h6">{t("Sent letter")}</SuiTypography>
          <Grid
            container
            pt={2}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item minWidth="15rem" xs>
              <DefaultCounterCard
                title={t("# Sent")}
                count={counter.sent || 0}
              />
            </Grid>
            <Grid item minWidth="15rem" xs>
              <DefaultCounterCard
                title={t("# Response")}
                count={counter.response || 0}
              />
            </Grid>
            <Grid item minWidth="15rem" xs>
              <DefaultCounterCard
                title={t("Response rate")}
                count={counter.response_rate * 100 || 0}
                decimals={2}
                suffix={"%"}
              />
            </Grid>
          </Grid>
        </SuiBox>
      )}
    </>
  );
};

export default LetterSummary;
