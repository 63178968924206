import { useEffect, useRef } from "react";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";

// Custom styles for the SuiDropzone
import CdxDropzoneRoot from "components/cdx/CdxDropzone/CdxDropzoneRoot";

function CdxDropzone({ setFile }) {
  const dropzoneRef = useRef();

  const options = {
    addRemoveLinks: true,
    maxFiles: 1,
    autoQueue: false,
    init: function () {
      this.on("addedfile", (file) => {
        const files = this.files;
        if (this.files.length > 1) {
          this.removeAllFiles();
          this.addFile(files[0]);
        }
        setFile(this.files[0]);
      });
    },
  };

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, []);

  return (
    <CdxDropzoneRoot
      component="form"
      action="/"
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SuiBox className="fallback">
        <SuiBox component="input" name="file" type="file" />
      </SuiBox>
    </CdxDropzoneRoot>
  );
}

export default CdxDropzone;
