import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Item from "./Item";
import Divider from "@mui/material/Divider";
import { BASE_PATH } from "settings";

import { useTranslation } from "react-i18next";

import signOut from "app/fb/signOut";

import CdxChangeLanguage from "../CdxChangeLanguage";

const UserMenu = ({ openMenu, handleCloseMenu, name, app = "app" }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [visibleProfile, setVisibleProfile] = useState(false);
  const [openChangeLanguage, setOpenChangeLanguage] = useState(false);

  const handleClose = () => {
    setOpenChangeLanguage(false);
    handleCloseMenu();
  };

  useEffect(() => {
    if (app === "confirmer") {
      setVisibleProfile(false);
    } else {
      setVisibleProfile(true);
    }
  }, [pathname]);

  return (
    <>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <Item title={name} onClick={handleCloseMenu} />
        <Divider sx={{ my: 1 }} />
        {visibleProfile ? (
          <NavLink to={`${BASE_PATH}/profile-&-setting`}>
            <Item title={t("Profile & setting")} onClick={handleCloseMenu} />
          </NavLink>
        ) : (
          <Item
            title={t("Change langauge")}
            onClick={() => {
              setOpenChangeLanguage(true);
            }}
          />
        )}
        <NavLink
          to={
            app === "confirmer"
              ? `${BASE_PATH}/confirmer/sign-out`
              : `${BASE_PATH}/sign-out`
          }
        >
          <Item title={t("Sign out")} />
        </NavLink>
      </Menu>
      <CdxChangeLanguage open={openChangeLanguage} handleClose={handleClose} />
    </>
  );
};

export default UserMenu;
