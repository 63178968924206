import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import InformationForm from "./form";

function Information({ form }) {
  return (
    <SuiBox>
      <SuiTypography variant="h5">Information</SuiTypography>
      <InformationForm form={form} />
    </SuiBox>
  );
}

export default Information;
