import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxSelect from "components/cdx/CdxSelect";

import { Form, Field } from "react-final-form";

import { deploySnackbar, updateUserSetting } from "actions";

import { LANGUAGES_OPTIONS } from "settings/i18n";

import checkObjectEqual from "app/utils/checkObjectEqual";

import { rootElement } from "settings";

const SettingForm = ({ setting, updateUserSetting }) => {
  const { t } = useTranslation();
  const tl = useTranslation("languages").t;

  const langOptions = LANGUAGES_OPTIONS.map((e) => {
    return { value: e.value, label: tl(e.label) };
  });
  const langValArr = LANGUAGES_OPTIONS.map((e) => e.value);

  const [message, setMessage] = useState({});

  const validateForm = (values) => {
    const error = {};

    if (!values.language) {
      error.language = "Please select a language";
    } else if (!langValArr.includes(values.language)) {
      error.language = "Please select a valid language";
    }

    return error;
  };

  const onSubmit = async (values) => {
    if (checkObjectEqual(values, setting || {})) {
      return;
    }
    updateUserSetting(values);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      initialValues={setting}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <Grid
            container
            columnSpacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={6} lg={5} xxl={4}>
              <Field name="language">
                {({ input, meta }) => (
                  <CdxSelect
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Language")}
                    options={langOptions}
                    error={!!(meta.error && meta.touched) || !!meta.submitError}
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                    menuPortalTarget={rootElement}
                  />
                )}
              </Field>
            </Grid>
            {submitError && (
              <Grid item xs={12} mt={0.5}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
            {message.text && (
              <Grid item xs={12} mt={0.5}>
                <SuiTypography
                  color={message.color || "info"}
                  variant="caption"
                >
                  {t(message.text)}
                </SuiTypography>
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SuiButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  Object.values(formState.errors).length > 0
                }
              >
                {t("Change setting")}
              </SuiButton>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    setting: state.user.userSetting,
  };
};

const mapDispatchToProps = { deploySnackbar, updateUserSetting };

export default connect(mapStateToProps, mapDispatchToProps)(SettingForm);
