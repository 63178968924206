import { Grid } from "@mui/material";
import CdxSwitch from "components/cdx/CdxSwitch";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import CdxCollapseCard from "components/cdx/CdxCollapseCard";
import CdxCard from "components/cdx/CdxCard";
import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";

const LetterTemplateSelection = ({
  selectedTemplate,
  setSelectedTemplate,
  templateList,
  onEdit,
  setOnEdit,
}) => {
  const { t } = useTranslation();
  const [create, setCreate] = useState(false);
  const [options, setOptions] = useState([]);
  const [createTemplate, setCreateTemplate] = useState("");
  const [currentSelect, setCurrentSelect] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setOptions(
      Object.keys(templateList || {}).map((e) => {
        return { value: e, label: e };
      })
    );
  }, [templateList]);

  useEffect(() => {}, [create]);

  const createNewTemplate = () => {
    setErrorMessage("");
    if (Object.keys(templateList || {}).includes(createTemplate)) {
      setErrorMessage("Duplicate template code");
    } else {
      setSelectedTemplate(createTemplate);
    }
  };

  return (
    <>
      {selectedTemplate ? (
        <CdxCard p={3}>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SuiBox>
              <SuiTypography variant="h6">
                {t("Selected template")}: {selectedTemplate}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <Grid container spacing={2}>
                {!onEdit &&
                  !((templateList || {})[selectedTemplate] || {}).freeze && (
                    <Grid item>
                      <SuiButton
                        variant="gradient"
                        color="warning"
                        size="small"
                        onClick={() => {
                          setOnEdit(true);
                        }}
                      >
                        {t("Edit template")}
                      </SuiButton>
                    </Grid>
                  )}
                {!onEdit && (
                  <Grid item>
                    <SuiButton
                      variant="gradient"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setSelectedTemplate("");
                      }}
                    >
                      {t("Change template")}
                    </SuiButton>
                  </Grid>
                )}
              </Grid>
            </SuiBox>
          </SuiBox>
        </CdxCard>
      ) : (
        <CdxCollapseCard title={t("Template selection")} p={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CdxSwitch
                spaceLabelAtTop={false}
                label={[t("Create template"), t("View template")]}
                value={create}
                onChange={() => {
                  setCreate(!create);
                }}
              />
            </Grid>

            <Grid item xs>
              {create ? (
                <CdxInput
                  placeholder={t("Enter a template code")}
                  value={createTemplate}
                  onChange={(e) => {
                    setCreateTemplate(e.target.value);
                  }}
                  error={!!errorMessage}
                  message={errorMessage}
                />
              ) : (
                <CdxSelect
                  value={currentSelect}
                  onChange={setCurrentSelect}
                  options={options}
                />
              )}
            </Grid>
            <Grid item>
              {create ? (
                <SuiButton
                  variant="gradient"
                  color="primary"
                  onClick={createNewTemplate}
                >
                  {t("Create new")}
                </SuiButton>
              ) : (
                <SuiButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    setSelectedTemplate(currentSelect);
                  }}
                >
                  {t("Select")}
                </SuiButton>
              )}
            </Grid>
          </Grid>
        </CdxCollapseCard>
      )}
    </>
  );
};

export default LetterTemplateSelection;
