import {
  CONFIRMER_INBOX,
  CONFIRMER_INBOX_HASH,
  CONFIRMER_HISTORY,
  CONFIRMER_HISTORY_HASH,
  CONFIRMER_SUMMARY,
  CONFIRMER_LETTER_HASH,
  CONFIRMER_LETTER,
  CLEAR_CONFIRMER_LETTER,
} from "actions/types";

import CodexAPIConfirmer from "app/api/codexapiconfirmer";

import { apiServerError } from "actions/snackBar";

import checkObjectEqual from "app/utils/checkObjectEqual";

import { NO_MAX_CONFIRMER_LETTER } from "settings/redux";

export const confirmerSummary = () => async (dispatch, getState) => {
  try {
    const response = await CodexAPIConfirmer().get("/confirmer/summary/");
    if (!checkObjectEqual(response.data.data, getState().confirmer.summary)) {
      dispatch({ type: CONFIRMER_SUMMARY, payload: response.data.data });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const confirmerInbox = () => async (dispatch, getState) => {
  const prevHash = getState().hashDictionary[CONFIRMER_INBOX_HASH];
  try {
    let response = await CodexAPIConfirmer().get("/confirmer/inbox/hash/");
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPIConfirmer().get("/confirmer/inbox/", {
        params: { hash: newHash },
      });
      dispatch({
        type: CONFIRMER_INBOX_HASH,
        payload: newHash,
      });
      dispatch({ type: CONFIRMER_INBOX, payload: response.data.data });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const confirmerHistory = () => async (dispatch, getState) => {
  const prevHash = getState().hashDictionary[CONFIRMER_HISTORY_HASH];
  try {
    let response = await CodexAPIConfirmer().get("/confirmer/history/hash/");
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPIConfirmer().get("/confirmer/history/", {
        params: { hash: newHash },
      });
      dispatch({
        type: CONFIRMER_HISTORY_HASH,
        payload: newHash,
      });
      dispatch({ type: CONFIRMER_HISTORY, payload: response.data.data });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const loadConfirmerLetter = (uuid) => async (dispatch, getState) => {
  const clean_uuid = uuid.replace(/-/gi, "");
  const prevHash = getState().hashDictionary[CONFIRMER_LETTER_HASH][clean_uuid];
  try {
    let response = await CodexAPIConfirmer().get(
      "/confirmer/letter/view/hash/",
      {
        params: { uuid },
      }
    );
    const newHash = response.data.data;
    if (prevHash !== newHash) {
      response = await CodexAPIConfirmer().get("/confirmer/letter/view/", {
        params: { uuid, hash: newHash },
      });
      dispatch({
        type: CONFIRMER_LETTER_HASH,
        payload: { [clean_uuid]: newHash },
      });
      dispatch({
        type: CONFIRMER_LETTER,
        payload: { [clean_uuid]: response.data.data },
      });
    }
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    } else if (error.response.status === 422) {
      dispatch({
        type: CONFIRMER_LETTER,
        payload: { [clean_uuid]: { is_invalid_uuid: true } },
      });
    }
  }
  dispatch(clearLoadConfirmerLetter());
};

const clearLoadConfirmerLetter = () => (dispatch, getState) => {
  const confirmerLetterHash = Object.entries(
    getState().hashDictionary[CONFIRMER_LETTER_HASH]
  );
  if (confirmerLetterHash.length > NO_MAX_CONFIRMER_LETTER) {
    const hashDictinary = {};
    let uuidArr = [];
    confirmerLetterHash
      .slice(-NO_MAX_CONFIRMER_LETTER)
      .forEach(([uuid, hash]) => {
        hashDictinary[uuid] = hash;
        uuidArr = [...uuidArr, uuid];
      });
    const letters = {};
    Object.entries(getState().confirmer.letters).forEach(([uuid, data]) => {
      if (uuidArr.includes(uuid)) {
        letters[uuid] = data;
      }
    });
    dispatch({
      type: CLEAR_CONFIRMER_LETTER,
      payload: { letters, hashDictinary },
    });
  }
};
