import { useState, useEffect } from "react";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { BASE_PATH } from "settings";

import CdxCard from "components/cdx/CdxCard";

import { confirmerSummary } from "actions";

const ConfirmerHome = ({ email, summary, confirmerSummary }) => {
  const { t } = useTranslation();
  const [letterCount, setLetterCount] = useState({ new: "n/a", done: "n/a" });

  useEffect(() => {
    confirmerSummary();
  }, []);

  useEffect(() => {
    if (summary) {
      setLetterCount({
        new: summary.all - summary.done - summary.expired,
        done: summary.done,
      });
    }
  }, [summary]);

  return (
    <SuiBox mt={3}>
      <CdxCard p={3}>
        <SuiTypography variant="h5">
          {t("Welcome")} {email}
        </SuiTypography>

        <SuiBox pt={3}>
          {letterCount.new < 1 ? (
            <SuiTypography variant="body2">
              {t("No confirmation in your inbox.")}
            </SuiTypography>
          ) : (
            <SuiTypography variant="body2">
              {t("You have")} {letterCount.new}{" "}
              {letterCount.new > 1
                ? t("new confirmations to response.")
                : t("new confirmation to response.")}
            </SuiTypography>
          )}
        </SuiBox>
        {letterCount.done > 0 && (
          <SuiBox pt={3}>
            <SuiTypography variant="body2">
              {t("Thank you for answering ")} {letterCount.done}{" "}
              {letterCount.done === 1 ? t("message.") : t("messages.")}
            </SuiTypography>
          </SuiBox>
        )}
      </CdxCard>
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return { email: state.confUser.email, summary: state.confirmer.summary };
};

const mapDispatchToProps = { confirmerSummary };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmerHome);
