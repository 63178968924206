import { Grid } from "@mui/material";

import { useState, useEffect, Fragment } from "react";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";

import { useTranslation } from "react-i18next";
import EditBusinessForm from "./EditBusinessForm";

import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { viewBusiness } from "actions";

import getNames from "app/utils/getNames";

const EditBusiness = ({ email, viewBusiness, businessesData }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [uuid, setUuid] = useState(
    (searchParams.get("uuid") || "").replace(/-/gi, "")
  );
  const [letterUuid, setLetterUuid] = useState(
    (searchParams.get("letter_uuid") || "").replace(/-/gi, "")
  );
  const [rawUuid, setRawUuid] = useState(searchParams.get("uuid") || "");

  const [entityNotFound, setEntityNotFound] = useState(false);
  const [businessData, setBusinessData] = useState({});
  const [isBusinessVerified, setIsBusinessVerified] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isNotStaff, setIsNotStaff] = useState(false);

  const [businessList, setBusinessList] = useState(null);

  const publicInformation = {
    entity_id: "Entity id",
    nationality: "Nationality",
  };

  const privateInformation = {
    website: "Website",
    address: "Address",
    description: "Description",
  };

  useEffect(() => {
    setUuid((searchParams.get("uuid") || "").replace(/-/gi, ""));
    setRawUuid(searchParams.get("uuid") || "");
    setLetterUuid((searchParams.get("letter_uuid") || "").replace(/-/gi, ""));
  }, [searchParams]);

  useEffect(() => {
    if (uuid) {
      viewBusiness(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (Object.keys(businessesData).length > 0) {
      const business = businessesData[uuid];
      if (business) {
        setBusinessData(business);
      }
    }
  }, [businessesData, uuid]);

  useEffect(() => {
    if (Object.values(businessData).length > 0) {
      if (businessData.is_invalid_uuid) {
        setEntityNotFound(true);
      } else {
        setIsBusinessVerified(businessData.is_verified);

        if (businessData.admin && businessData.is_staff) {
          setIsAdmin(
            businessData.admin.filter((e) => e.email === email).length > 0
          );
        } else {
          setIsAdmin(false);
          setIsNotStaff(true);
        }
      }
    } else {
      setIsAdmin(false);
      setIsNotStaff(false);
    }
  }, [businessData, uuid]);

  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} md={3} lg={5} mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("Edit business")} -{" "}
                {getNames(
                  (businessData.information || {}).names || [
                    {
                      lang: "en",
                      name: t("Load business name"),
                    },
                  ]
                )}
              </SuiTypography>
            </Grid>
          </Grid>
          {isBusinessVerified ? (
            <SuiBox mt={2}>
              <SuiTypography variant="body2">
                {t(
                  "Cannot edit verified business. Please contact us to make an edit request."
                )}
              </SuiTypography>
            </SuiBox>
          ) : (
            <Fragment>
              {isAdmin ? (
                <EditBusinessForm
                  businessUuid={rawUuid}
                  businessData={businessData}
                />
              ) : (
                <SuiBox mt={2}>
                  <SuiTypography variant="body2">
                    {t(
                      "Unauthorized. Only admin account can edit this request."
                    )}
                  </SuiTypography>
                </SuiBox>
              )}
            </Fragment>
          )}
          {entityNotFound && (
            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="error">
                {t("Invalid business uuid. Please recheck your business uuid.")}
              </SuiTypography>
            </SuiBox>
          )}
        </SuiBox>
      </CdxCard>
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    businessesData: state.business.businessData,
  };
};

const mapDispatchToProps = { viewBusiness };

export default connect(mapStateToProps, mapDispatchToProps)(EditBusiness);
