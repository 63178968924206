import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxDataTable from "components/cdx/CdxDataTable";
import CdxCard from "components/cdx/CdxCard";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import ConfirmerHistoryFunctionBar from "./FunctionBar";

import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const ConfirmerHistory = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    console.log(rows);
  });

  return (
    <>
      <SuiBox mt={3}>
        <CdxCard p={3}>
          <SuiBox mb={2}>
            <SuiTypography variant="h6">{t("Confirmer history")}</SuiTypography>
            <ConfirmerHistoryFunctionBar setRows={setRows} />
          </SuiBox>
          <CdxDataTable
            entriesPerPage={{
              defaultValue: 5,
            }}
            showSelectEntriesPerPage={false}
            table={{
              columns: [
                {
                  Header: t("Action"),
                  accessor: "action",
                  width: "10%",
                },
                {
                  Header: t("Confirm entity"),
                  accessor: "confirm_entity_name",
                },
                {
                  Header: t("Sender name"),
                  accessor: "client_name",
                },
                {
                  Header: t("Auditor name"),
                  accessor: "auditor_name",
                },
                {
                  Header: t("Confirm at"),
                  accessor: "confirmer_submit_at",
                  Cell: ({ value }) => {
                    if (value) {
                      return isoTimeToLocaleTimeString(value);
                    } else {
                      return (
                        <SuiTypography color="error" variant="inherit">
                          {t("Didn't response - Expired")}
                        </SuiTypography>
                      );
                    }
                  },
                },
                {
                  Header: t("Email"),
                  accessor: "confirmer_submit_email",
                },
                {
                  Header: t("Phone number"),
                  accessor: "confirmer_submit_phone",
                },
              ],
              rows: rows,
            }}
          />
        </CdxCard>
      </SuiBox>
    </>
  );
};

export default ConfirmerHistory;
