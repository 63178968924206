import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";
import CdxInput from "components/cdx/CdxInput";
import { connect } from "react-redux";
import CodexAPI from "app/api/codexapi";
import { deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

import timeSince from "app/utils/timeSince";

import { BUSINESS_MEMBERSHIP_TYPE } from "settings/confirmation";
import UserAcceptButton from "./UserAcceptButton";
import UserRejectButton from "./UserRejectButton";
import UserCancelButton from "./UserCancelButton";

import { listMyBusiness } from "actions";

import { BASE_PATH } from "settings";

import getNames from "app/utils/getNames";

const ManageGeneralEngagement = ({
  myEmail,
  myBusiness,
  listMyBusiness,
  deploySnackbar,
  codexApiCatchError,
}) => {
  const { t } = useTranslation();
  const [userRequestList, setUserRequestList] = useState([]);
  const [adminBusinessList, setAdminBusinessList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(false);

  const navigate = useNavigate();

  const getRequestList = async () => {
    try {
      const response = await CodexAPI().get(
        "engagement/list-my-engagement-request/"
      );
      setUserRequestList(response.data.data);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  useEffect(() => {
    if (myBusiness) {
      const myAdminBusiness = myBusiness.filter((e) => e.membershipType >= 2);
      setAdminBusinessList(myAdminBusiness);
    }
  }, [myBusiness]);

  useEffect(() => {
    getRequestList();
    listMyBusiness();
  }, []);

  const renderUserCard = (e) => {
    const clientName = getNames(e.clientBusinessNames);

    const auditorName = getNames(e.auditorBusinessNames);

    const youRe = e.clientEmail == myEmail ? "Client" : "Auditor";

    const requestFrom =
      e.client_accepted_at && !e.auditor_accepted_at ? "Client" : "Auditor";

    const requestTo =
      e.auditor_accepted_at && !e.client_accepted_at ? "Client" : "Auditor";

    return (
      <Grid item xs={12} md={6} lg={4} xxl={3} key={e.engagement_request_uuid}>
        <CdxCard>
          <SuiBox p={2}>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SuiTypography variant="h6">
                {e.engagementMutualName}
              </SuiTypography>
              <SuiTypography variant="caption">
                {timeSince(e.client_accepted_at || e.auditor_accepted_at)}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography
                variant="caption"
                fontWeight={requestFrom === youRe ? "bold" : "regular"}
              >
                {t("Request from")}:{" "}
                {requestFrom === "Client" ? clientName : auditorName} (
                {t(requestFrom)}) -{" "}
                {requestFrom === "Client" ? e.clientEmail : e.auditorEmail}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography
                variant="caption"
                fontWeight={requestTo === youRe ? "bold" : "regular"}
              >
                {t("Request to")}:{" "}
                {requestTo === "Client" ? clientName : auditorName} (
                {t(requestTo)}) -{" "}
                {requestTo === "Client" ? e.clientEmail : e.auditorEmail}
              </SuiTypography>
            </SuiBox>
            {requestFrom === youRe ? (
              <SuiBox mt={1}>
                <UserCancelButton
                  name={e.engagementMutualName}
                  engagement_request_uuid={e.engagement_request_uuid}
                  accept_as={youRe.toLowerCase()}
                  getRequestList={getRequestList}
                />
              </SuiBox>
            ) : (
              <SuiBox
                mt={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserRejectButton
                  name={e.engagementMutualName}
                  engagement_request_uuid={e.engagement_request_uuid}
                  accept_as={youRe.toLowerCase()}
                  getRequestList={getRequestList}
                />
                <UserAcceptButton
                  name={e.engagementMutualName}
                  engagement_request_uuid={e.engagement_request_uuid}
                  accept_as={youRe.toLowerCase()}
                  getRequestList={getRequestList}
                />
              </SuiBox>
            )}
          </SuiBox>
        </CdxCard>
      </Grid>
    );
  };

  const renderBusinessCard = (e) => {
    let name = getNames(e.names);
    return (
      <Grid item xs={12} md={6} lg={4} xxl={3} key={e.businessUUID}>
        <CdxCard
          onClick={() => {
            navigate(
              `${BASE_PATH}/overview/engagement/manage-engagement/business?uuid=${e.businessUUID}`
            );
          }}
        >
          <SuiBox p={2}>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SuiTypography variant="h6">{name}</SuiTypography>
            </SuiBox>
          </SuiBox>
        </CdxCard>
      </Grid>
    );
  };

  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} md={3} lg={5} mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("Manage engagement")}
              </SuiTypography>
            </Grid>
          </Grid>
        </SuiBox>
      </CdxCard>
      <SuiBox mt={3} mb={2}>
        <SuiTypography variant="h5">
          {t("Your engagement membership request")}
        </SuiTypography>
      </SuiBox>
      {userRequestList.length > 0 ? (
        <Grid container spacing={2}>
          {userRequestList.map((e) => {
            return renderUserCard(e);
          })}
        </Grid>
      ) : (
        <SuiTypography variant="body2">{t("No active request")}</SuiTypography>
      )}
      {/* Cdx Important - To review this */}
      {/* <SuiBox mt={3} mb={2}>
        <SuiTypography variant="h5">
          {t("Manage engagement under your business adminstration")}
        </SuiTypography>
      </SuiBox>
      {adminBusinessList.length > 0 ? (
        <Grid container spacing={2}>
          {adminBusinessList.map((e) => {
            return renderBusinessCard(e);
          })}
        </Grid>
      ) : (
        <SuiTypography variant="body2">
          {t("No business under your administration")}
        </SuiTypography>
      )} */}
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    myEmail: state.user.email,
    myBusiness: state.business.myBusiness,
  };
};

const mapDispatchToProps = {
  listMyBusiness,
  deploySnackbar,
  codexApiCatchError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageGeneralEngagement);
