import SuiButton from "components/sui/SuiButton";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import { loadEngagementData, deploySnackbar } from "actions";
import CodexAPI from "app/api/codexapi";

import { codexApiCatchError } from "actions/snackBar";

const ApproveLettersButton = ({
  selectLetter,
  setSelectLetter,

  engagementUUID,
  loadEngagementData,
  codexApiCatchError,
  deploySnackbar,
}) => {
  const { t } = useTranslation();

  const handleClick = async () => {
    if (selectLetter.length > 0) {
      try {
        const response = await CodexAPI().post("/engagement/letters/approve/", {
          letters_uuid: selectLetter,
          engagement_uuid: engagementUUID,
        });
        loadEngagementData(engagementUUID);
        const snackBar = {
          color: "success",
          icon: "done",
          title: "Successfully approve letter request",
          content: `Selected letter has approveed`,
        };
        deploySnackbar(snackBar);
        setSelectLetter([]);
      } catch (error) {
        codexApiCatchError(error);
      }
    }
  };

  return (
    <SuiButton
      size="small"
      color="primary"
      variant="gradient"
      disabled={!selectLetter.length > 0}
      onClick={handleClick}
    >
      {t("Approve")}
    </SuiButton>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  loadEngagementData,
  codexApiCatchError,
  deploySnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveLettersButton);
