import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import CdxSelect from "components/cdx/CdxSelect";
import { LANGUAGES_OPTIONS } from "settings/i18n";
import { useEffect } from "react";

import changeLanguage from "app/utils/changeLanguage";
import { rootElement } from "settings";

function Setting({ form: { currentValue, setValue, setDisabledNext } }) {
  const { t } = useTranslation();
  const tl = useTranslation("languages").t;

  const langOptions = LANGUAGES_OPTIONS.map((e) => {
    return { value: e.value, label: tl(e.label) };
  });

  useEffect(() => {
    if (currentValue) {
      setDisabledNext(false);
    } else {
      setDisabledNext(true);
    }
  }, [currentValue]);

  const onChange = (e) => {
    changeLanguage(e);
    setValue(e);
  };
  
  return (
    <SuiBox>
      <SuiTypography variant="h5">Register Service</SuiTypography>
      <SuiBox mt={3} mb="6rem" sx={{ display: "flex", alignItems: "center" }}>
        <SuiTypography variant="body2">
          {t("Your preferred langauge is ")}
        </SuiTypography>
        <SuiBox pl={2} sx={{ flexGrow: 1 }}>
          <CdxSelect
            value={currentValue}
            onChange={onChange}
            options={langOptions}
            menuPortalTarget={rootElement}
          />
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Setting;
