import { useTranslation } from "react-i18next";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { Grid } from "@mui/material";

import DefaultCounterCard from "components/cdx/CdxCounterCards/DefaultCounterCard";
import { useEffect, useState } from "react";

const RequestSummary = ({ requests, billing }) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState({});

  useEffect(() => {
    if (requests) {
      const count = {
        amount: requests.length,
        client: 0,
        auditor: 0,
      };

      requests.forEach((r) => {
        if (billing == "auditor") {
          if (r.client_accepted) {
            if (!r.auditor_accetped) {
              count.auditor += 1;
            }
          } else {
            count.client += 1;
          }
        } else {
          if (r.auditor_accetped) {
            if (!r.client_accepted) {
              count.client += 1;
            }
          } else {
            count.auditor += 1;
          }
        }
      });
      setCounter(count);
    } else {
      setCounter({});
    }
  }, [requests]);

  return (
    <>
      {!requests ? (
        <></>
      ) : (
        <SuiBox mt={2}>
          <SuiTypography variant="h6">{t("Pending letter")}</SuiTypography>
          <Grid
            container
            pt={2}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item minWidth="15rem" xs>
              <DefaultCounterCard
                color="warning"
                title={t("# Total Pending")}
                count={counter.amount || 0}
              />
            </Grid>
            <Grid item minWidth="15rem" xs>
              <DefaultCounterCard
                color="warning"
                title={t("# Client to accept")}
                count={counter.client || 0}
              />
            </Grid>
            <Grid item minWidth="15rem" xs>
              <DefaultCounterCard
                color="warning"
                title={t("# Auditor to accept")}
                count={counter.auditor || 0}
              />
            </Grid>
          </Grid>
        </SuiBox>
      )}
    </>
  );
};

export default RequestSummary;
