import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

const NotFound404 = () => {
  return (
    <SuiBox
      py={20}
      textAlign="center"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <SuiBox p={2}>
        <SuiTypography variant="h1">
          <Icon fontSize="inherit">thumb_down_alt</Icon>
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <SuiTypography variant="header1" color="secondary">
          Page not found (404)
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
};

export default NotFound404;
