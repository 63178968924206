import { useState } from "react";
import { connect } from "react-redux";

import CdxDataTable from "components/cdx/CdxDataTable";

import SuiBox from "components/sui/SuiBox";

import SearchNFilter from "./SearchNFilter";
import { Divider } from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  ENGAGEMENT_MEMBERSHIP_TYPE,
  ENGAGEMENT_TEAM_TYPE,
  ENGAGEMENT_BILLING_METHOD_TYPE,
} from "settings/confirmation";

const EngagementTable = ({ displayOption }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(0);

  return (
    <SuiBox mt={2}>
      <SearchNFilter
        setRows={setRows}
        setPageSize={setPageSize}
        displayOption={displayOption}
      />
      <Divider />
      <CdxDataTable
        entriesPerPage={{
          defaultValue: pageSize,
          entries: [{ value: pageSize, label: pageSize }],
        }}
        showSelectEntriesPerPage={false}
        table={{
          columns: [
            { Header: t("Action"), accessor: "action", width: "5%" },
            { Header: t("Engagement"), accessor: "name" },
            {
              Header: t("Membership"),
              accessor: "type",
              Cell: ({ value }) => {
                return t(
                  value === 2
                    ? "Business admin"
                    : ENGAGEMENT_MEMBERSHIP_TYPE.at(value)
                );
              },
            },
            { Header: t("My Business"), accessor: "myBusiness" },
            {
              Header: t("Team"),
              accessor: "team",
              Cell: ({ value }) => {
                return t(ENGAGEMENT_TEAM_TYPE.at(value));
              },
            },
            { Header: t("Counterparty"), accessor: "counterparty" },
            {
              Header: t("Billing method"),
              accessor: "billing_method",
              Cell: ({ value }) => {
                return t(ENGAGEMENT_BILLING_METHOD_TYPE.at(value));
              },
            },
            {
              Header: t("Paid by"),
              accessor: "paid_by",
            },
            {
              Header: t("Is archived?"),
              accessor: "archive_at",
              Cell: ({ value }) => {
                if (value) {
                  const day = new Date(value);
                  if (Date.now() > day) {
                    return t("Archived");
                  }
                }
                return t("Active");
              },
            },
          ],
          rows: rows,
        }}
      />
    </SuiBox>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementTable);
