/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { connect } from "react-redux";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiInput from "components/sui/SuiInput";

// Soft UI Dashboard PRO React groupComponents components
import CdxBreadcrumbs from "components/cdx/CdxBreadcrumbs";

// Custom styles for CdxNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/cdx/CdxNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

import UserMenu from "./UserMenu";

import CdxUserAvatar from "components/cdx/CdxUserAvatar";
// import useMyFullName from "hooks/useMyFullName";

function CdxNavbar({ absolute, light, isMini, user, confUser, app = "app" }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);

  const { pathname } = useLocation();
  const [route, setRoute] = useState(pathname.split("/").slice(1));

  useEffect(() => {
    let r = pathname.split("/").slice(1);
    if (!r.at(-1)) {
      r = r.slice(0, -1);
    }
    setRoute(r);
  }, [pathname]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <CdxBreadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
            homePath={app === "confirmer" ? "/confirmer/home" : "/home"}
          />
          <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SuiBox>
        {isMini ? null : (
          <SuiBox
            sx={(theme) => {
              return {
                ...navbarRow(theme, { isMini }),
                justifyContent: "flex-end",
              };
            }}
          >
            {false && (
              <SuiBox pr={1} width="20rem">
                <SuiInput
                  placeholder="Type here..."
                  icon={{ component: "search", direction: "left" }}
                />
              </SuiBox>
            )}
            <SuiBox
              color={light ? "white" : "inherit"}
              sx={{ display: "flex" }}
            >
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <CdxUserAvatar size="sm" onClick={handleOpenMenu} app={app} />
              {app === "confirmer" ? (
                <UserMenu
                  openMenu={openMenu}
                  handleCloseMenu={handleCloseMenu}
                  name={confUser.fbData.displayName || confUser.email}
                  app="confirmer"
                />
              ) : (
                <UserMenu
                  openMenu={openMenu}
                  handleCloseMenu={handleCloseMenu}
                  name={user.fbData.displayName || user.email}
                />
              )}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of CdxNavbar
CdxNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the CdxNavbar
CdxNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    confUser: state.confUser,
  };
};

export default connect(mapStateToProps)(CdxNavbar);
