import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import EngagementRedirect from "pages/engagement/redirect";

import CdxCard from "components/cdx/CdxCard";

import { connect } from "react-redux";
import CdxDataTable from "components/cdx/CdxDataTable";

import { Icon } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useState } from "react";

import LetterTrackFunctionBar from "./FunctionBar";
import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const EngagementTrack = ({ engagementData }) => {
  const { t } = useTranslation();
  const [responseRows, setResponseRows] = useState([]);
  const [noresponseRows, setNoresponseRows] = useState([]);
  const [expireResponseRows, setExpireResponseRows] = useState([]);
  return (
    <EngagementRedirect>
      {engagementData && (
        <SuiBox mt={3}>
          <CdxCard>
            <SuiBox p={3}>
              <SuiTypography variant="h5">
                {t("Track")} - {engagementData.mutual_engagement_name}
              </SuiTypography>
              <LetterTrackFunctionBar
                setResponseRows={setResponseRows}
                setNoresponseRows={setNoresponseRows}
                setExpireResponseRows={setExpireResponseRows}
              />
            </SuiBox>
          </CdxCard>
          {responseRows.length > 0 && (
            <CdxCard mt={2}>
              <SuiBox p={3}>
                <SuiTypography variant="h6">{t("Response")}</SuiTypography>
                <CdxDataTable
                  entriesPerPage={{
                    defaultValue: 5,
                  }}
                  showSelectEntriesPerPage={false}
                  table={{
                    columns: [
                      {
                        Header: t("Action"),
                        accessor: "action",
                        width: "10%",
                      },
                      {
                        Header: t("Company Name"),
                        accessor: "confirm_entity_name",
                      },
                      {
                        Header: t("Confirmer email"),
                        accessor: "confirmer_submit_email",
                      },
                      {
                        Header: t("Confirmer phone number"),
                        accessor: "confirmer_submit_phone",
                      },
                      {
                        Header: t("Agreeable"),
                        accessor: "agree",
                        Cell: ({ value }) => {
                          return (
                            <SuiBox
                              height="100%"
                              display="flex"
                              alignItems="center"
                            >
                              {value ? (
                                <Icon color="success">check_circle</Icon>
                              ) : (
                                <Icon color="error">cancel</Icon>
                              )}
                            </SuiBox>
                          );
                        },
                      },
                    ],
                    rows: responseRows,
                  }}
                />
              </SuiBox>
            </CdxCard>
          )}
          {noresponseRows.length > 0 && (
            <CdxCard mt={2}>
              <SuiBox p={3}>
                <SuiTypography variant="h6">
                  {t("No response yet")}
                </SuiTypography>
                <CdxDataTable
                  entriesPerPage={{
                    defaultValue: 5,
                  }}
                  showSelectEntriesPerPage={false}
                  table={{
                    columns: [
                      {
                        Header: t("Action"),
                        accessor: "action",
                        width: "10%",
                      },
                      {
                        Header: t("Company Name"),
                        accessor: "confirm_entity_name",
                      },
                      {
                        Header: t("Recipient"),
                        accessor: "recipients",
                        Cell: ({ value }) => {
                          return value.at(0).email;
                        },
                      },
                      {
                        Header: t("Create at"),
                        accessor: "timestamp",
                        Cell: ({ value }) => {
                          return isoTimeToLocaleTimeString(value);
                        },
                      },
                      {
                        Header: t("Expire at"),
                        accessor: "expire_at",
                        Cell: ({ value }) => {
                          return isoTimeToLocaleTimeString(value);
                        },
                      },
                      {
                        Header: t("# email sent"),
                        accessor: "no_email_sent",
                      },
                      {
                        Header: t("# email successful"),
                        accessor: "no_email_success_sent",
                      },
                    ],
                    rows: noresponseRows,
                  }}
                />
              </SuiBox>
            </CdxCard>
          )}
          {expireResponseRows.length > 0 && (
            <CdxCard mt={2}>
              <SuiBox p={3}>
                <SuiTypography variant="h6">{t("Expire")}</SuiTypography>
                <CdxDataTable
                  entriesPerPage={{
                    defaultValue: 5,
                  }}
                  showSelectEntriesPerPage={false}
                  table={{
                    columns: [
                      {
                        Header: t("Action"),
                        accessor: "action",
                        width: "10%",
                      },
                      {
                        Header: t("Company Name"),
                        accessor: "confirm_entity_name",
                      },
                      {
                        Header: t("Recipient"),
                        accessor: "recipients",
                        Cell: ({ value }) => {
                          return value.at(0).email;
                        },
                      },
                      {
                        Header: t("Expire at"),
                        accessor: "expire_at",
                        Cell: ({ value }) => {
                          return isoTimeToLocaleTimeString(value);
                        },
                      },
                    ],
                    rows: expireResponseRows,
                  }}
                />
              </SuiBox>
            </CdxCard>
          )}
        </SuiBox>
      )}
    </EngagementRedirect>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementTrack);
