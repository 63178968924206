import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SuiButton from "components/sui/SuiButton";
import Icon from "@mui/material/Icon";

import CodexAPI from "app/api/codexapi";
import { deploySnackbar, businessMember } from "actions";
import { codexApiCatchError } from "actions/snackBar";

const RemoveButton = ({
  email,
  business_uuid,
  deploySnackbar,
  codexApiCatchError,
  businessMember,
}) => {
  const { t } = useTranslation();
  const handleOnClick = async () => {
    try {
      const response = await CodexAPI().post("business/member-manage/remove/", {
        email,
        business_uuid,
      });
      businessMember(business_uuid);
      const snackBar = {
        color: "success",
        icon: "done",
        title: t("Successfully remove user from business"),
        content: `${email} ${t("has been remove from business")}`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  return (
    <SuiButton
      size="small"
      variant="gradient"
      color="error"
      iconOnly
      circular
      onClick={handleOnClick}
    >
      <Icon>delete</Icon>
    </SuiButton>
  );
};
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
  businessMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveButton);
