import { authOtp } from "./config";

import { signOut as fbSignOut } from "firebase/auth";

const signOutOtp = () => {
  fbSignOut(authOtp)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};

export default signOutOtp;
