import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AuthLayout from "components/layouts/AuthLayout";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import Separator from "components/layouts/AuthLayout/Separator";

import SignInForm from "./form";

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <SuiBox
        p={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        width="100%"
      >
        <SuiBox>
          <SuiTypography variant="h5">{t("Sign in")}</SuiTypography>
        </SuiBox>
        <SignInForm />

        <SuiBox width="100%">
          <Separator />
        </SuiBox>
        <SuiBox mt={1} mb={1} width="100%">
          <SuiButton
            component={Link}
            to="/sign-up"
            variant="gradient"
            color="dark"
            fullWidth
          >
            {t("Create account")}
          </SuiButton>
        </SuiBox>
        <SuiBox mt={1} mb={1} width="100%">
          <SuiButton
            component={Link}
            to="/reset-password"
            variant="gradient"
            color="warning"
            fullWidth
          >
            {t("Reset password")}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </AuthLayout>
  );
};

export default SignIn;
