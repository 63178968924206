import SuiButton from "components/sui/SuiButton";

import { Grid, Icon } from "@mui/material";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { BASE_PATH } from "settings";

const BusinessFunctionBar = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Grid
        spacing={2}
        container
        item
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        md={9}
        lg={7}
      >
        <Grid item>
          <SuiButton
            size="small"
            color="primary"
            variant="gradient"
            onClick={() => {
              navigate(`${BASE_PATH}/overview/business/create-new-business`);
            }}
          >
            <Icon>add</Icon>&nbsp;{t("Add new business")}
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessFunctionBar;
