import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NonAuthHome = () => {
  const { t } = useTranslation();
  return (
    <SuiBox
      py={20}
      textAlign="center"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <SuiBox p={2}>
        <SuiTypography variant="h1">
          <Icon fontSize="inherit">directions_walk</Icon>
        </SuiTypography>
      </SuiBox>
      <SuiBox mb={3}>
        <SuiTypography>{t("Who are you?")}</SuiTypography>
      </SuiBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <NavLink to="/confirmer/sign-in">
            <SuiButton variant="gradient" color="success">
              {t("Confirmer")}
            </SuiButton>
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink to="/sign-in">
            <SuiButton variant="gradient" color="info">
              {t("Auditor or corporate")}
            </SuiButton>
          </NavLink>
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default NonAuthHome;
