import { useState, useEffect } from "react";

import * as XLSX from "xlsx/xlsx.mjs";

import _ from "lodash";

import excelDateToISO from "app/utils/excelDateToISO";
import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import checkTemplateCompleteness from "app/utils/checkTemplateCompleteness";
import CdxBadgeDot from "components/cdx/CdxBadgeDot";
import CdxDataTable from "components/cdx/CdxDataTable";
import CdxCard from "components/cdx/CdxCard";
import SubmitAddConfirmButton from "../SubmitAddConfirmButton";

const AddConfirmProcess = ({
  workbook,
  setInvalidFile,
  engagementUUID,
  engagementData,
}) => {
  const { t } = useTranslation();
  const [processValid, setProcessValid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processLetter, setProcessLetter] = useState([]);

  useEffect(() => {
    if (workbook) {
      setIsProcessing(true);
      let errorList = [];
      if (!workbook.SheetNames.includes("letter")) {
        errorList = [...errorList, "Missing sheet name letter"];
      }
      if (!workbook.SheetNames.includes("balance")) {
        errorList = [...errorList, "Missing sheet name balance"];
      }
      if (!workbook.SheetNames.includes("transaction")) {
        errorList = [...errorList, "Missing sheet name transaction"];
      }

      if (errorList.length > 0) {
        setInvalidFile([...new Set(errorList)]);
        setIsProcessing(false);
        return;
      }

      const templates = Object.keys(engagementData.information.templates);

      let letter = XLSX.utils.sheet_to_json(workbook.Sheets["letter"]);

      if (letter.filter((e) => !e.confirm_entity_code).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a missing confirm entity code",
        ];
      }

      if (_.uniqBy(letter, "confirm_entity_code").length !== letter.length) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a duplicate confirm entity code",
        ];
      }

      letter.forEach((e) => {
        if (
          engagementData.letters.findIndex(
            (k) => k.confirm_entity_code === e.confirm_entity_code
          ) >= 0
        ) {
          errorList = [
            ...errorList,
            "Some row in sheet letter has a duplicate confirm entity code with existing letter",
          ];
        } else if (
          engagementData.requests.findIndex(
            (k) => k.confirm_entity_code === e.confirm_entity_code
          ) >= 0
        ) {
          errorList = [
            ...errorList,
            "Some row in sheet letter has a duplicate confirm entity code with existing request",
          ];
        }
      });

      if (letter.filter((e) => !e.confirm_entity_name).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a missing confirm entity name",
        ];
      }

      if (
        letter.filter((e) => !e.template || !templates.includes(e.template))
          .length > 0
      ) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a missing/non-exist template",
        ];
      }

      if (letter.filter((e) => !e.expire_date).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a missing expire date",
        ];
      }

      letter.forEach((e) => {
        console.log(e.expire_date);
        let expire_date = e.expire_date;
        if (expire_date) {
          try {
            expire_date = excelDateToISO(expire_date);
            if (
              new Date(expire_date).getTime() + 1000 * 60 * 60 * 24 - 1 <
              Date.now()
            ) {
              errorList = [
                ...errorList,
                "Some row in sheet letter has a back-dating expire date",
              ];
            }
          } catch {
            errorList = [
              ...errorList,
              "Some row in sheet letter has an incorrect expire date (not excel date)",
            ];
          }
        }
      });

      if (letter.filter((e) => !e.recipient_1_email).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a missing recipient email",
        ];
      }

      if (letter.filter((e) => !e.recipient_1_name).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet letter has a missing recipient name",
        ];
      }

      const balance = XLSX.utils.sheet_to_json(workbook.Sheets["balance"]);

      if (balance.filter((e) => !e.confirm_entity_code).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet balance has a missing confirm entity code",
        ];
      }

      if (balance.filter((e) => !e.balance_key).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet balance has a missing balance key",
        ];
      }

      if (balance.filter((e) => !e.doc_no).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet balance has a missing document number",
        ];
      }

      if (balance.filter((e) => !e.doc_date).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet balance has a missing document date",
        ];
      }

      if (balance.filter((e) => !e.cur).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet balance has a missing currency",
        ];
      }

      if (balance.filter((e) => !e.amount).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet balance has a missing amount",
        ];
      }

      const transaction = XLSX.utils.sheet_to_json(
        workbook.Sheets["transaction"]
      );

      if (transaction.filter((e) => !e.confirm_entity_code).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet transaction has a missing confirm entity code",
        ];
      }

      if (transaction.filter((e) => !e.transaction_key).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet transaction has a missing transaction key",
        ];
      }

      if (transaction.filter((e) => !e.doc_no).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet transaction has a missing document number",
        ];
      }

      if (transaction.filter((e) => !e.doc_date).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet transaction has a missing document date",
        ];
      }

      if (transaction.filter((e) => !e.cur).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet transaction has a missing currency",
        ];
      }

      if (transaction.filter((e) => !e.amount).length > 0) {
        errorList = [
          ...errorList,
          "Some row in sheet transaction has a missing amount",
        ];
      }

      if (errorList.length > 0) {
        setIsProcessing(false);
        setInvalidFile([...new Set(errorList)]);
        return;
      }

      const idx_map = {};

      letter = letter.map((row, idx) => {
        let recipients = [];
        if (row.recipient_1_email) {
          recipients = [
            ...recipients,
            {
              email: row.recipient_1_email,
              name: row.recipient_1_name,
              position: row.recipient_1_position,
              phone: row.recipient_1_phone,
            },
          ];
        }
        if (row.recipient_2_email) {
          recipients = [
            ...recipients,
            {
              email: row.recipient_2_email,
              name: row.recipient_2_name,
              position: row.recipient_2_position,
              phone: row.recipient_2_phone,
            },
          ];
        }
        if (row.recipient_3_email) {
          recipients = [
            ...recipients,
            {
              email: row.recipient_3_email,
              name: row.recipient_3_name,
              position: row.recipient_3_position,
              phone: row.recipient_3_phone,
            },
          ];
        }
        idx_map[row.confirm_entity_code] = idx;
        return {
          confirm_information: {
            confirm_entity_code: row.confirm_entity_code,
            confirm_entity_name: row.confirm_entity_name,
            balance: {},
            transaction: {},
            balance_details: {},
            transaction_details: {},
            template: row.template,
          },
          recipients,
          expire_date: excelDateToISO(row.expire_date),
        };
      });

      balance.forEach((row) => {
        let doc_date = row.doc_date;
        try {
          doc_date = excelDateToISO(doc_date);
        } catch {
          errorList = [
            ...errorList,
            "Some row in sheet balance has an incorrect doc date (not excel date)",
          ];
        }
        const to_idx = idx_map[row.confirm_entity_code];

        if (!to_idx && to_idx !== 0) {
          errorList = [
            ...errorList,
            "Some row in sheet balance has an incorrect confirm entity code",
          ];
          return;
        }

        const payload = {
          doc_no: row.doc_no,
          doc_date,
          cur: row.cur,
          amount: row.amount,
          remark: row.remark,
        };

        const temp = letter[to_idx];
        if (temp["confirm_information"]["balance"][row.balance_key]) {
          temp["confirm_information"]["balance"][row.balance_key] += row.amount;
          temp["confirm_information"]["balance_details"][row.balance_key] = [
            ...temp["confirm_information"]["balance_details"][row.balance_key],
            payload,
          ];
        } else {
          temp["confirm_information"]["balance"][row.balance_key] = row.amount;
          temp["confirm_information"]["balance_details"][row.balance_key] = [
            payload,
          ];
        }

        letter[to_idx] = temp;
      });

      transaction.forEach((row) => {
        let doc_date = row.doc_date;
        try {
          doc_date = excelDateToISO(doc_date);
        } catch {
          errorList = [
            ...errorList,
            "Some row in sheet transaction has an incorrect doc date (not excel date)",
          ];
        }
        const to_idx = idx_map[row.confirm_entity_code];

        if (!to_idx && to_idx !== 0) {
          errorList = [
            ...errorList,
            "Some row in sheet transaction has an incorrect confirm entity code",
          ];
          return;
        }

        const payload = {
          doc_no: row.doc_no,
          doc_date,
          cur: row.cur,
          amount: row.amount,
          remark: row.remark,
        };

        const temp = letter[to_idx];
        if (temp["confirm_information"]["transaction"][row.transaction_key]) {
          temp["confirm_information"]["transaction"][row.transaction_key] +=
            row.amount;
          temp["confirm_information"]["transaction_details"][
            row.transaction_key
          ] = [
            ...temp["confirm_information"]["transaction_details"][
              row.transaction_key
            ],
            payload,
          ];
        } else {
          temp["confirm_information"]["transaction"][row.transaction_key] =
            row.amount;
          temp["confirm_information"]["transaction_details"][
            row.transaction_key
          ] = [payload];
        }

        letter[to_idx] = temp;
      });

      letter.forEach((row) => {
        row.recipients.forEach((e) => {
          const values = {
            recipient_name: e.name,
            recipient_email: e.email,
            recipient_position: e.position,
            recipient_phone: e.phone,
            confirm_entity_name: row.confirm_entity_name,
          };
          Object.entries(row.confirm_information.balance).forEach(([k, v]) => {
            values[`balance__${k}`] = v;
          });
          Object.entries(row.confirm_information.transaction).forEach(
            ([k, v]) => {
              values[`transaction__${k}`] = v;
            }
          );
          if (
            !checkTemplateCompleteness(
              engagementData.information.templates[
                row.confirm_information.template
              ].template,
              values
            )
          ) {
            errorList = [
              ...errorList,
              "Could not complete render the template for some row",
            ];
          }
        });
      });

      if (errorList.length > 0) {
        setIsProcessing(false);
        setInvalidFile([...new Set(errorList)]);
        return;
      }
      setProcessLetter(letter);
      setIsProcessing(false);
      setProcessValid(true);
      setTimeout(() => {
        document.getElementById("root").click();
      }, 100);
    } else {
      setIsProcessing(false);
      setProcessValid(false);
    }
  }, [workbook]);

  return (
    <>
      {processValid && (
        <SuiBox mt={3}>
          {processLetter.length > 0 && (
            <CdxCard mt={2}>
              <SuiBox p={3}>
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <SuiBox>
                    <SuiTypography variant="h6">
                      {t("Summary processed letter")}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <SubmitAddConfirmButton
                      letters={processLetter}
                      engagementUUID={engagementUUID}
                    />
                  </SuiBox>
                </SuiBox>

                <CdxDataTable
                  entriesPerPage={{
                    defaultValue: -1,
                  }}
                  showSelectEntriesPerPage={false}
                  table={{
                    columns: [
                      {
                        Header: t("Company Name"),
                        accessor: "confirm_information.confirm_entity_name",
                      },
                      {
                        Header: t("Confirmer"),
                        accessor: "recipients",
                        Cell: ({ value }) => {
                          return value.map((e) => {
                            const content = e.phone
                              ? `${e.email} (${e.phone})`
                              : e.email;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={content}
                              />
                            );
                          });
                        },
                      },
                      {
                        Header: t("Template"),
                        accessor: "confirm_information.template",
                      },
                      {
                        Header: t("Balance"),
                        accessor: "confirm_information.balance",
                        Cell: ({ value }) => {
                          return Object.entries(value).map(([k, v]) => {
                            const label =
                              ((engagementData.information || {}).key_mapping ||
                                {})[k] || k;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={`${label} - ${v.toLocaleString()}`}
                              />
                            );
                          });
                        },
                      },
                      {
                        Header: t("Transaction"),
                        accessor: "confirm_information.transaction",
                        Cell: ({ value }) => {
                          return Object.entries(value).map(([k, v]) => {
                            const label =
                              ((engagementData.information || {}).key_mapping ||
                                {})[k] || k;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={`${label} - ${v.toLocaleString()}`}
                              />
                            );
                          });
                        },
                      },
                    ],
                    rows: processLetter,
                  }}
                />
              </SuiBox>
            </CdxCard>
          )}
        </SuiBox>
      )}
      {isProcessing && (
        <SuiBox mt={3}>
          <SuiTypography variant="h6">
            {t("Processing upload file ...")}
          </SuiTypography>
        </SuiBox>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
    engagementUUID: state.app.activeEngagementUUID,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddConfirmProcess);
