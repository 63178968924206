import SuiButton from "components/sui/SuiButton";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import { loadEngagementData, deploySnackbar } from "actions";
import CodexAPI from "app/api/codexapi";

import { codexApiCatchError } from "actions/snackBar";
import { useNavigate } from "react-router-dom";
import { BASE_PATH } from "settings";

const SubmitAddConfirmButton = ({
  letters,
  engagementUUID,
  loadEngagementData,
  codexApiCatchError,
  deploySnackbar,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (letters.length > 0) {
      try {
        const response = await CodexAPI().post("/engagement/letters/add/", {
          letters: letters,
          engagement_uuid: engagementUUID,
        });
        loadEngagementData(engagementUUID);
        const snackBar = {
          color: "success",
          icon: "done",
          title: "Successfully add letter request",
          content: `Add new confirm. Please review and approve it.`,
        };
        deploySnackbar(snackBar);
        navigate(`${BASE_PATH}/engagement/under-review`);
      } catch (error) {
        codexApiCatchError(error);
      }
    }
  };

  return (
    <SuiButton
      size="small"
      color="success"
      variant="gradient"
      disabled={!letters.length > 0}
      onClick={handleClick}
    >
      {t("Accept")}
    </SuiButton>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  loadEngagementData,
  codexApiCatchError,
  deploySnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitAddConfirmButton);
