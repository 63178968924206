import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";
import { useTranslation } from "react-i18next";

import SettingForm from "./form";

const Setting = ({ setting }) => {
  const { t } = useTranslation();

  return (
    <CdxCard>
      <SuiBox p={3} pb={2}>
        <SuiTypography variant="h6">{t("Setting")}</SuiTypography>
      </SuiBox>
      <SuiBox px={3} pb={3}>
        <SettingForm />
      </SuiBox>
    </CdxCard>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(Setting);
