import { authConfirmer } from "../config";

import isSignInWithEmailLink from "../isSignInWithEmailLink";

import { signInWithEmailLink as fbSignInWithEmailLink } from "firebase/auth";
import encrypt from "app/utils/encrypt";

import { LSK_EMAIL_FOR_SIGN_IN } from "settings/localStorageKeys";

import reloadToken from "app/fb/confirmer/reloadToken";

import store from "store";

import { deploySnackbar } from "actions";

const signInWithEmailLink = async (email = null) => {
  let result = {};
  if (email === null) {
    email = window.localStorage.getItem(LSK_EMAIL_FOR_SIGN_IN);
  }

  if (isSignInWithEmailLink()) {
    await fbSignInWithEmailLink(authConfirmer, email, window.location.href)
      .then((fbResult) => {
        // Clear email from storage.
        window.localStorage.removeItem(LSK_EMAIL_FOR_SIGN_IN);

        reloadToken();

        result = { type: "success", data: { ...fbResult } };

        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        reloadToken();
        result = { type: "error", data: { ...error } };
      });
  } else {
    result = { type: "error", data: { code: "invalid link" } };
  }

  return result;
};

export default signInWithEmailLink;
