import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid, Icon } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";
import CdxDataTable from "components/cdx/CdxDataTable";
import DefaultCounterCard from "components/cdx/CdxCounterCards/DefaultCounterCard";
import CodexAPI from "app/api/codexapi";

import ManageSelectBusinessFunctionBar from "./FunctionBar";

import { businessMember } from "actions";
import { BUSINESS_MEMBERSHIP_TYPE } from "settings/confirmation";

import BusinessNameHook from "app/hooks/components/BusinessNameHook";

import AcceptButton from "./AcceptButton";
import RejectButton from "./RejectButton";
import RemoveButton from "./RemoveButton";
import ChangeButton from "./ChangeButton";
import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const ManageSelectBusiness = ({ uuid, members, businessMember, myEmail }) => {
  const { t } = useTranslation();
  const [entityNotFound, setEntityNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [counter, setCounter] = useState({});
  const [loadMember, setLoadMember] = useState({});
  const [member, setMember] = useState([]);
  const [memberRequest, setMemberRequest] = useState([]);
  const [memberRows, setMemberRows] = useState([]);
  const [memberRequestRows, setMemberRequestRows] = useState([]);
  const [myType, setMyType] = useState();

  useEffect(async () => {
    if (uuid) {
      businessMember(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    const m = members[uuid];
    if (m) {
      setLoadMember(m);
    }
  }, [members]);

  useEffect(() => {
    if (myEmail && member.length > 0) {
      const type = member.find((e) => {
        return e.email === myEmail;
      }).type;
      setMyType(type);
    }
  }, [myEmail, member]);

  const actionForMember = (email, type) => {
    if (email === myEmail) {
      return (
        <SuiTypography color="secondary" variant="caption">
          {t("This is you")}
        </SuiTypography>
      );
    } else if (type > myType) {
      return (
        <SuiTypography color="secondary" variant="caption">
          {t("Higher rank")}
        </SuiTypography>
      );
    }
    return (
      <Grid container spacing={0.5}>
        <Grid item>
          <RemoveButton email={email} business_uuid={uuid} />
        </Grid>
        <Grid item>
          <ChangeButton
            email={email}
            business_uuid={uuid}
            curType={type}
            myType={myType}
          />
        </Grid>
      </Grid>
    );
  };

  const actionForRequest = (email, request_uuid, isAccept) => {
    return (
      <Grid container spacing={0.5}>
        <Grid item>
          <RejectButton
            email={email}
            request_uuid={request_uuid}
            business_uuid={uuid}
          />
        </Grid>
        {!isAccept && (
          <Grid item>
            <AcceptButton
              email={email}
              request_uuid={request_uuid}
              business_uuid={uuid}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const rebuildMember = (m) => {
    return m.map((e) => {
      return {
        ...e,
        action: actionForMember(e.email, e.type),
      };
    });
  };

  const rebuildRequest = (r) => {
    return r.map((e) => {
      return {
        ...e,
        action: actionForRequest(e.email, e.requestUUID, e.admin_accepted),
      };
    });
  };

  useEffect(() => {
    if (loadMember.is_invalid_uuid) {
      setEntityNotFound(true);
    }
    if (Object.values(loadMember).length > 0) {
      setMember(rebuildMember(loadMember.members));
      setMemberRequest(rebuildRequest(loadMember.requests));
      setCounter({
        owner: loadMember.members.filter((e) => e.type === 3).length,
        admin: loadMember.members.filter((e) => e.type === 2).length,
        manager: loadMember.members.filter((e) => e.type === 1).length,
        member: loadMember.members.filter((e) => e.type === 0).length,
        request: loadMember.requests.length,
      });
    }
  }, [loadMember, myType]);

  useEffect(() => {
    if (Object.values(loadMember).length > 0) {
      setCounter({
        owner: loadMember.members.filter((e) => e.type === 3).length,
        admin: loadMember.members.filter((e) => e.type === 2).length,
        manager: loadMember.members.filter((e) => e.type === 1).length,
        member: loadMember.members.filter((e) => e.type === 0).length,
        request: loadMember.requests.length,
      });
    }
  }, [loadMember]);

  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("Manage")} <BusinessNameHook uuid={uuid} />
              </SuiTypography>
            </Grid>
          </Grid>
          {entityNotFound && (
            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="error">
                {t("Invalid business uuid. Please recheck your business uuid.")}
              </SuiTypography>
            </SuiBox>
          )}
          {forbidden && (
            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="error">
                {t("Only admin account can manage business")}
              </SuiTypography>
            </SuiBox>
          )}
          {!entityNotFound && !forbidden && (
            <ManageSelectBusinessFunctionBar
              business_uuid={uuid}
              member={member}
              memberRequest={memberRequest}
              setMemberRows={setMemberRows}
              setMemberRequestRows={setMemberRequestRows}
              myType={myType}
            />
          )}
        </SuiBox>
      </CdxCard>
      {Object.values(counter).length > 0 && (
        <SuiBox mt={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <DefaultCounterCard count={counter.owner} title={t("Owner")} />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard count={counter.admin} title={t("Admin")} />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard
                count={counter.manager}
                title={t("Manager")}
              />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard count={counter.member} title={t("Member")} />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard
                count={counter.request}
                title={t("Request")}
                color="warning"
              />
            </Grid>
          </Grid>
        </SuiBox>
      )}
      {memberRows.length > 0 && (
        <CdxCard mt={2}>
          <SuiBox p={3}>
            <SuiTypography variant="h6">{t("Members")}</SuiTypography>
            <CdxDataTable
              entriesPerPage={{
                defaultValue: 5,
              }}
              showSelectEntriesPerPage={false}
              table={{
                columns: [
                  { Header: t("Action"), accessor: "action", width: "10%" },
                  { Header: t("Email"), accessor: "email" },
                  {
                    Header: t("Type"),
                    accessor: "type",
                    Cell: ({ value }) => {
                      return t(BUSINESS_MEMBERSHIP_TYPE.at(value));
                    },
                  },
                  { Header: t("Accept by"), accessor: "activate_by_email" },
                  {
                    Header: t("Member Since"),
                    accessor: "activate_at",
                    Cell: ({ value }) => {
                      return isoTimeToLocaleTimeString(value);
                    },
                  },
                ],
                rows: memberRows,
              }}
            />
          </SuiBox>
        </CdxCard>
      )}
      {memberRequestRows.length > 0 && (
        <CdxCard mt={2}>
          <SuiBox p={3}>
            <SuiTypography variant="h6">{t("Requests")}</SuiTypography>
            <CdxDataTable
              entriesPerPage={{
                defaultValue: 5,
              }}
              showSelectEntriesPerPage={false}
              table={{
                columns: [
                  { Header: t("Action"), accessor: "action", width: "10%" },
                  { Header: t("Email"), accessor: "email" },
                  {
                    Header: t("Type"),
                    accessor: "type",
                    Cell: ({ value }) => {
                      return t(BUSINESS_MEMBERSHIP_TYPE.at(value));
                    },
                  },
                  {
                    Header: t("User accept"),
                    accessor: "user_accepted",
                    Cell: ({ value }) => {
                      return (
                        <SuiBox
                          height="100%"
                          display="flex"
                          alignItems="center"
                        >
                          {value === null ? (
                            <></>
                          ) : (
                            <>
                              {value ? (
                                <Icon color="success">check_circle</Icon>
                              ) : (
                                <Icon color="error">cancel</Icon>
                              )}
                            </>
                          )}
                        </SuiBox>
                      );
                    },
                  },
                  {
                    Header: t("Admin accept"),
                    accessor: "admin_accepted",
                    Cell: ({ value }) => {
                      return (
                        <SuiBox
                          height="100%"
                          display="flex"
                          alignItems="center"
                        >
                          {value === null ? (
                            <></>
                          ) : (
                            <>
                              {value ? (
                                <Icon color="success">check_circle</Icon>
                              ) : (
                                <Icon color="error">cancel</Icon>
                              )}
                            </>
                          )}
                        </SuiBox>
                      );
                    },
                  },
                ],
                rows: memberRequestRows,
              }}
            />
          </SuiBox>
        </CdxCard>
      )}
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return { myEmail: state.user.email, members: state.business.businessMember };
};

const mapDispatchToProps = { businessMember };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSelectBusiness);
