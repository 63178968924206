import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxInput from "components/cdx/CdxInput";
import CdxPasswordInput from "components/cdx/CdxPasswordInput";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import checkObjectEqual from "app/utils/checkObjectEqual";

const PasswordForm = ({
  form: { currentValues, setValues, setDisabledNext },
}) => {
  const { t } = useTranslation();

  const validateForm = (values) => {
    const error = {};

    if (values.password) {
      if (values.password.length < 8) {
        error.password = "At least 8 character";
      } else if (values.password.search(/[A-Z]/) < 0) {
        error.password =
          "Your password must be contained at least one upper case letter";
      } else if (values.password.search(/[a-z]/) < 0) {
        error.password =
          "Your password must be contained at least one lower case letter";
      } else if (values.password.search(/\d/) < 0) {
        error.password = "Your password must be contained at least one number";
      } else if (values.password.search(/[!@#$%^&*()+-/.]/) < 0) {
        error.password =
          "Your password must be contained at least one special character";
      }
    } else {
      error.password = "Please enter a password";
    }
    if (values.re_password) {
      if (values.re_password !== values.password) {
        error.re_password = "Password do not match";
      }
    } else {
      error.re_password = "Please confirm a password";
    }

    if (Object.values(error).length > 0) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
      if (
        !checkObjectEqual(values, currentValues) &&
        Object.values(values).length > 0
      ) {
        setValues(values);
      }
    }

    return error;
  };

  const onSubmit = (values) => {};

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      initialValues={{ ...currentValues }}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <Grid
            container
            columnSpacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={6} lg={5} xxl={4}>
              <Field name="password" type="password">
                {({ input, meta }) => (
                  <CdxPasswordInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={2}
                    label={t("Password*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6} lg={5} xxl={4}>
              <Field name="re_password" type="password">
                {({ input, meta }) => (
                  <CdxPasswordInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={2}
                    label={t("Confirm password*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default PasswordForm;
