import { useState } from "react";
import { connect } from "react-redux";

import CdxDataTable from "components/cdx/CdxDataTable";

import SuiBox from "components/sui/SuiBox";

import SearchNFilter from "./SearchNFilter";
import { Divider } from "@mui/material";

import { useTranslation } from "react-i18next";
import { BUSINESS_MEMBERSHIP_TYPE } from "settings/confirmation";

const BusinessTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  return (
    <SuiBox mt={2}>
      <SearchNFilter setRows={setRows} setPageSize={setPageSize} />
      <Divider />
      <CdxDataTable
        entriesPerPage={{
          defaultValue: pageSize,
          entries: [{ value: pageSize, label: pageSize }],
        }}
        showSelectEntriesPerPage={false}
        table={{
          columns: [
            { Header: t("Action"), accessor: "action", width: "10%" },
            { Header: t("Business Name"), accessor: "name" },
            {
              Header: t("Your membership"),
              accessor: "type",
              Cell: ({ value }) => {
                return t(BUSINESS_MEMBERSHIP_TYPE.at(value));
              },
            },
            { Header: t("Visibility"), accessor: "visibility" },
            { Header: t("Verified"), accessor: "is_verified" },
          ],
          rows: rows,
        }}
      />
    </SuiBox>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTable);
