import { connect } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import { selectActiveEngagement, loadEngagementData } from "actions";

import { useTranslation } from "react-i18next";
import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { LSK_CDX_ACTIVE_ENGAGEMENT } from "settings/localStorageKeys";

import decryptToObject from "app/utils/decryptToObject";

const EngagementRedirect = ({
  activeEngagement,
  engagementData,
  myEmail,
  loadEngagementData,
  selectActiveEngagement,
  children,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [uuid, setUuid] = useState(
    (searchParams.get("uuid") || "").replace(/-/gi, "")
  );
  const [isLoadData, setIsLoadData] = useState(false);
  const [isInvalid, setIsInvalid] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    if (searchParams.get("uuid")) {
      setUuid((searchParams.get("uuid") || "").replace(/-/gi, ""));
    } else {
      let localUuid = window.localStorage.getItem(LSK_CDX_ACTIVE_ENGAGEMENT);
      try {
        localUuid = decryptToObject(localUuid);
      } catch {
        localUuid = {};
      }
      if (localUuid.email === myEmail) {
        setUuid(localUuid.uuid);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (uuid && activeEngagement == null) {
      selectActiveEngagement(uuid);
      loadEngagementData(uuid);
    } else if (uuid && uuid !== activeEngagement) {
      selectActiveEngagement(uuid);
      loadEngagementData(uuid);
    } else if (uuid) {
      loadEngagementData(uuid);
    }
  }, [uuid, pathname]);

  useEffect(() => {
    if (Object.values(engagementData).length > 0) {
      const thisEngagement = engagementData[uuid || activeEngagement];
      setIsLoadData(true);
      if (!thisEngagement) {
        setIsInvalid(t("Something went wrong. Please try again."));
      } else {
        setIsInvalid("");
      }
    }
  }, [engagementData]);

  return (
    <>
      {!activeEngagement ? (
        <SuiBox mt={3}>
          <SuiTypography variant="h5">
            {t("Please provide engagement uuid")}
          </SuiTypography>
        </SuiBox>
      ) : (
        <>
          {isLoadData ? (
            <>
              {!isInvalid ? (
                <>{children}</>
              ) : (
                <SuiBox mt={3}>
                  <SuiTypography variant="h5">{t(isInvalid)}</SuiTypography>
                </SuiBox>
              )}
            </>
          ) : (
            <SuiBox mt={3}>
              <SuiTypography variant="h5">
                {t("Loading engagement data...")}
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    myEmail: state.user.email,
    activeEngagement: state.app.activeEngagementUUID,
    engagementData: state.engagement.engagementData,
  };
};

const mapDispatchToProps = { loadEngagementData, selectActiveEngagement };

export default connect(mapStateToProps, mapDispatchToProps)(EngagementRedirect);
