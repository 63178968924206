import { connect } from "react-redux";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthLayout from "components/layouts/AuthLayout";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import SignUpForm from "./form";
import isSignInWithEmailLink from "app/fb/isSignInWithEmailLink";

import CompleteSignUpInApp from "./in-app";

const CompleteSignUp = ({ isSignIn }) => {
  const { t } = useTranslation();
  const [validLink, setValidLink] = useState(false);

  useEffect(() => {
    setValidLink(isSignInWithEmailLink());
  }, []);

  return (
    <>
      {!isSignIn ? (
        <AuthLayout>
          <SuiBox
            p={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            width="100%"
          >
            <SuiBox>
              <SuiTypography variant="h5">{t("Sign up")}</SuiTypography>
            </SuiBox>
            {validLink ? (
              <SignUpForm />
            ) : (
              <SuiBox mt={3} width="100%">
                <SuiTypography variant="body2" color="error">
                  {t("Invalid link")}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </AuthLayout>
      ) : (
        <CompleteSignUpInApp />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { isSignIn: state.app.isSignIn };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSignUp);
