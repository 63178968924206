import {
  ENGAGEMENT_DATA,
  LIST_MY_ENGAGEMENT,
  CLEAR_ENGAGEMENT_DATA,
  ENGAGEMENT_MEMBER,
  CLEAR_ENGAGEMENT_MEMBER,
  ENGAGEMENT_LETTER,
  CLEAR_ENGAGEMENT_LETTER,
  SIGN_OUT,
  CONF_SIGN_OUT,
} from "actions/types";

const engagementDefault = {
  myEngagement: [],
  engagementData: {},
  engagementMember: {},
  letters: [],
};

const engagementReducer = (engagement = engagementDefault, action) => {
  switch (action.type) {
    case LIST_MY_ENGAGEMENT:
      return { ...engagement, myEngagement: action.payload };
    case ENGAGEMENT_DATA:
      return {
        ...engagement,
        engagementData: { ...engagement.engagementData, ...action.payload },
      };
    case ENGAGEMENT_MEMBER:
      return {
        ...engagement,
        engagementMember: { ...engagement.engagementMember, ...action.payload },
      };
    case CLEAR_ENGAGEMENT_DATA:
      return {
        ...engagement,
        engagementData: action.payload.engagementData,
      };
    case CLEAR_ENGAGEMENT_MEMBER:
      return {
        ...engagement,
        engagementMember: action.payload.engagementMember,
      };
    case ENGAGEMENT_LETTER:
      return {
        ...engagement,
        letters: { ...engagement.letters, ...action.payload },
      };
    case CLEAR_ENGAGEMENT_LETTER:
      return {
        ...engagement,
        letters: action.payload.letters,
      };
    case SIGN_OUT:
      return {
        ...engagementDefault,
      };
    case CONF_SIGN_OUT:
      return {
        ...engagementDefault,
      };
    default:
      return engagement;
  }
};

export default engagementReducer;
