import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid, Icon } from "@mui/material";

import CdxInput from "components/cdx/CdxInput";

import { confirmerInbox } from "actions";

import getNames from "app/utils/getNames";

import GotoButton from "../GotoButton";

const ConfirmerInboxFunctionBar = ({
  setRows,
  inbox,
  confirmerInbox,
  deploySnackbar,
}) => {
  const { t } = useTranslation();
  const curLang = useTranslation().i18n.language;
  const [search, setSearch] = useState("");
  const [inboxRow, setInboxRow] = useState([]);

  useEffect(() => {
    confirmerInbox();
  }, []);

  useEffect(() => {
    const rows = inbox.map((e) => {
      return {
        ...e,
        client_name: getNames(e.client_names),
        auditor_name: getNames(e.auditor_names),
        action: <GotoButton letterUUID={e.letterUUID} />,
      };
    });
    setInboxRow(rows);
  }, [inbox, curLang]);

  useEffect(() => {
    if (search) {
      const searchRegExp = new RegExp(search, "i");
      let arr1 = inboxRow.filter((e) => {
        let valid = e.confirm_entity_name.search(searchRegExp) >= 0;
        valid =
          valid ||
          e.client_names.filter((n) => n.name.search(searchRegExp) >= 0)
            .length > 0;
        valid =
          valid ||
          e.auditor_names.filter((n) => n.name.search(searchRegExp) >= 0)
            .length > 0;
        return valid;
      });

      const combineArr = [...new Set([...arr1])];
      arr1 = null;
      setRows(combineArr);
    } else {
      setRows(inboxRow);
    }
  }, [inboxRow, search]);

  return (
    <>
      <Grid mt={1} container spacing={2} direction="row">
        <Grid item xs>
          <CdxInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            icon={{
              component: "search",
              direction: "left",
            }}
            // error={!!errorSearchMessage}
            // message={errorSearchMessage || searchMessage}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inbox: state.confirmer.inbox,
  };
};

const mapDispatchToProps = { confirmerInbox };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmerInboxFunctionBar);
