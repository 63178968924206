import { Fragment, useEffect, useState } from "react";

import CdxCard from "components/cdx/CdxCard";

import CdxInput from "components/cdx/CdxInput";

import CdxButton from "components/cdx/CdxButton";

import requestOtp from "app/confirmation/requestOtp";

import submitConfirmation from "app/confirmation/submitConfirmation";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";
import { Grid } from "@mui/material";
import CdxSelect from "components/cdx/CdxSelect";
import CdxSwitch from "components/cdx/CdxSwitch";

const SubmitResponseCard = ({ letter, email }) => {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [disabledPhone, setDisabledPhone] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [requestingOtp, setRequestingOtp] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [markPhone, setMarkPhone] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");

  useEffect(() => {
    if (letter.recipients) {
      const recipient_info = letter.recipients.find(
        (e) => e.email === email.toLowerCase()
      );
      if ((recipient_info || {}).phone) {
        if (typeof recipient_info.phone === "string") {
          setPhoneNumber(recipient_info.phone);
          setDisabledPhone(true);
        } else {
          const op = recipient_info.phone.map((e) => {
            return { value: e, label: e };
          });
          setPhoneOptions(op);
        }
      }
    }
  }, [letter]);

  const otpRequest = () => {
    setPhoneErrorMessage("");
    setOtpErrorMessage("");
    requestOtp(
      phoneNumber,
      letter.letterUUID,
      letter.confirmer_response,
      setIsOtpSent,
      setPhoneErrorMessage,
      setRequestingOtp
    );
  };

  const submitConfirm = () => {
    setPhoneErrorMessage("");
    setOtpErrorMessage("");
    submitConfirmation(
      otp,
      letter.letterUUID,
      letter.confirmer_response,
      markPhone,
      setOtpErrorMessage
    );
  };

  return (
    <CdxCard p={3}>
      <SuiTypography variant="h6">{t("Submit your response")}</SuiTypography>
      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {phoneOptions.length > 0 ? (
              <CdxSelect
                label={t("Select your phone number")}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e);
                }}
                disabled={isOtpSent}
                options={phoneOptions}
              />
            ) : (
              <CdxInput
                label={t("Your phone number")}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled={disabledPhone || isOtpSent}
                error={!!phoneErrorMessage}
                message={phoneErrorMessage}
              />
            )}
          </Grid>
          {isOtpSent && (
            <Grid item xs={12} sm={6} md={4}>
              <CdxInput
                label={t("One time password")}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                error={!!otpErrorMessage}
                message={otpErrorMessage}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {" "}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              {!isOtpSent ? (
                <CdxButton
                  onClick={otpRequest}
                  progress={requestingOtp}
                  color="info"
                  variant="gradient"
                >
                  Request OTP
                </CdxButton>
              ) : (
                <CdxButton
                  onClick={submitConfirm}
                  color="primary"
                  variant="gradient"
                >
                  Confirm OTP
                </CdxButton>
              )}
            </SuiBox>
          </Grid>
          {!(phoneOptions.length > 0) && (
            <Grid item xs={12}>
              <CdxSwitch
                value={markPhone}
                onChange={() => {
                  setMarkPhone(!markPhone);
                }}
                label={[
                  t("Mark last 4 phone number digits as ****"),
                  t("Not mark phone number"),
                ]}
                spaceLabelAtTop={false}
              />
            </Grid>
          )}
        </Grid>
      </SuiBox>
    </CdxCard>
  );
};

const mapStateToProps = (state) => {
  return { email: state.confUser.email };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitResponseCard);
