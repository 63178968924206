import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";
import CdxDataTable from "components/cdx/CdxDataTable";
import DefaultCounterCard from "components/cdx/CdxCounterCards/DefaultCounterCard";

import ManageSelectBusinessFunctionBar from "./FunctionBar";

import { listMyEngagement, engagementMember } from "actions";
import { BUSINESS_MEMBERSHIP_TYPE } from "settings/confirmation";

import RemoveButton from "./RemoveButton";
import ChangeButton from "./ChangeButton";
import { ENGAGEMENT_TEAM_TYPE } from "settings/confirmation";
import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const ManageSelectBusiness = ({
  uuid,
  members,
  engagementMember,
  myEngagement,
  listMyEngagement,
  myEmail,
}) => {
  const { t } = useTranslation();

  const [entityNotFound, setEntityNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [counter, setCounter] = useState({});
  const [loadMember, setLoadMember] = useState({});
  const [member, setMember] = useState([]);
  const [counterMember, setCounterMember] = useState([]);
  const [memberRows, setMemberRows] = useState([]);
  const [counterMemberRows, setCounterMemberRows] = useState([]);
  const [myType, setMyType] = useState(null);
  const [myTeam, setMyTeam] = useState(null);

  const [engagementName, setEngagementName] = useState(t("engagement"));

  useEffect(async () => {
    if (uuid) {
      listMyEngagement();
      engagementMember(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    const m = members[uuid];
    if (m) {
      setLoadMember(m);
    }
  }, [members]);

  useEffect(() => {
    if (myEngagement.length > 0) {
      setEngagementName(
        myEngagement.find((e) => e.engagementUUID === uuid) ||
          {}.engagementMutualName
      );
    }
  }, [myEngagement]);

  useEffect(() => {
    if (myEmail && loadMember.length > 0) {
      const me = loadMember.find((e) => {
        return e.email === myEmail;
      });

      if (me) {
        setMyType(me.type);
        setMyTeam(me.team);
      } else {
        setMyType(2);
        setMyTeam(
          (
            myEngagement.find(
              (e) => e.engagementUUID.replace(/-/g, "") === uuid
            ) || {}
          ).team
        );
      }
    }
  }, [myEmail, loadMember, myEngagement]);

  const actionForMember = (email, type) => {
    if (email === myEmail) {
      return (
        <SuiTypography color="secondary" variant="caption">
          {t("This is you")}
        </SuiTypography>
      );
    } else if (type > myType) {
      return (
        <SuiTypography color="secondary" variant="caption">
          {t("Higher rank")}
        </SuiTypography>
      );
    }
    return (
      <Grid container spacing={0.5}>
        <Grid item>
          <RemoveButton email={email} engagement_uuid={uuid} />
        </Grid>
        <Grid item>
          <ChangeButton
            email={email}
            engagement_uuid={uuid}
            curType={type}
            myType={myType}
          />
        </Grid>
      </Grid>
    );
  };

  const rebuildMember = (m) => {
    return m.map((e) => {
      return {
        ...e,
        action: actionForMember(e.email, e.type),
      };
    });
  };

  const rebuildCounterparty = (r) => {
    return r.map((e) => {
      return {
        ...e,
      };
    });
  };

  useEffect(() => {
    if (loadMember.is_invalid_uuid) {
      setEntityNotFound(true);
    } else {
      if ((Object.values(loadMember).length > 0) & (myTeam !== null)) {
        setMember(rebuildMember(loadMember.filter((e) => e.team === myTeam)));
        setCounterMember(
          rebuildCounterparty(loadMember.filter((e) => e.team !== myTeam))
        );
        setCounter({
          cstaff: loadMember.filter((e) => e.type === 0 && e.team === 0).length,
          cManager: loadMember.filter((e) => e.type === 1 && e.team === 0)
            .length,
          aStaff: loadMember.filter((e) => e.type === 0 && e.team === 1).length,
          aManager: loadMember.filter((e) => e.type === 1 && e.team === 1)
            .length,
        });
      }
    }
  }, [loadMember, myTeam]);

  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("Manage")} {engagementName}
              </SuiTypography>
            </Grid>
          </Grid>
          {entityNotFound && (
            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="error">
                {t(
                  "Invalid engagement uuid. Please recheck your engagement uuid."
                )}
              </SuiTypography>
            </SuiBox>
          )}
          {forbidden && (
            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="error">
                {t("Only manager or business admin can manage engagement")}
              </SuiTypography>
            </SuiBox>
          )}
          {!entityNotFound && !forbidden && (
            <ManageSelectBusinessFunctionBar
              engagement_uuid={uuid}
              member={member}
              counterMember={counterMember}
              setMemberRows={setMemberRows}
              setCounterMemberRows={setCounterMemberRows}
              myType={myType}
            />
          )}
        </SuiBox>
      </CdxCard>
      {Object.values(counter).length > 0 && (
        <SuiBox mt={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <DefaultCounterCard
                count={counter.cStaff || 0}
                title={t("Client Staff")}
              />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard
                count={counter.cManager || 0}
                title={t("Client Admin")}
              />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard
                count={counter.aStaff || 0}
                color="primary"
                title={t("Auditor Staff")}
              />
            </Grid>
            <Grid item xs>
              <DefaultCounterCard
                count={counter.aManager || 0}
                color="primary"
                title={t("Auditor Manager")}
              />
            </Grid>
          </Grid>
        </SuiBox>
      )}
      {memberRows.length > 0 && (
        <CdxCard mt={2}>
          <SuiBox p={3}>
            <SuiTypography variant="h6">
              {t("Members")} - {t(ENGAGEMENT_TEAM_TYPE.at(myTeam))}
            </SuiTypography>
            <CdxDataTable
              entriesPerPage={{
                defaultValue: 5,
              }}
              showSelectEntriesPerPage={false}
              table={{
                columns: [
                  { Header: t("Action"), accessor: "action", width: "10%" },
                  { Header: t("Email"), accessor: "email" },
                  {
                    Header: t("Type"),
                    accessor: "type",
                    Cell: ({ value }) => {
                      return t(BUSINESS_MEMBERSHIP_TYPE.at(value));
                    },
                  },
                  { Header: t("Accept by"), accessor: "activate_by_email" },
                  {
                    Header: t("Member Since"),
                    accessor: "activate_at",
                    Cell: ({ value }) => {
                      return isoTimeToLocaleTimeString(value);
                    },
                  },
                ],
                rows: memberRows,
              }}
            />
          </SuiBox>
        </CdxCard>
      )}
      {counterMemberRows.length > 0 && (
        <CdxCard mt={2}>
          <SuiBox p={3}>
            <SuiTypography variant="h6">
              {t("Counterparty members")} -{" "}
              {t(ENGAGEMENT_TEAM_TYPE.at(!myTeam))}
            </SuiTypography>
            <CdxDataTable
              entriesPerPage={{
                defaultValue: 5,
              }}
              showSelectEntriesPerPage={false}
              table={{
                columns: [
                  { Header: t("Email"), accessor: "email" },
                  {
                    Header: t("Type"),
                    accessor: "type",
                    Cell: ({ value }) => {
                      return t(BUSINESS_MEMBERSHIP_TYPE.at(value));
                    },
                  },
                  { Header: t("Accept by"), accessor: "activate_by_email" },
                  {
                    Header: t("Member Since"),
                    accessor: "activate_at",
                    Cell: ({ value }) => {
                      return isoTimeToLocaleTimeString(value);
                    },
                  },
                ],
                rows: counterMemberRows,
              }}
            />
          </SuiBox>
        </CdxCard>
      )}
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    myEmail: state.user.email,
    myEngagement: state.engagement.myEngagement,
    members: state.engagement.engagementMember,
  };
};

const mapDispatchToProps = { listMyEngagement, engagementMember };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSelectBusiness);
