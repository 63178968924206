import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";

import { useTranslation } from "react-i18next";
import BusinessFunctionBar from "./BusinessFunctionBar";
import BusinessTable from "./BusinessTable";

const ListMyBusiness = () => {
  const { t } = useTranslation();
  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} md={3} lg={5} mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("List of my business")}
              </SuiTypography>
            </Grid>
            <BusinessFunctionBar />
          </Grid>
          <BusinessTable />
        </SuiBox>
      </CdxCard>
    </SuiBox>
  );
};

export default ListMyBusiness;
