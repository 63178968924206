import { Grid } from "@mui/material";
import { useState } from "react";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";

import { useTranslation } from "react-i18next";
import EngagementFunctionBar from "./EngagementFunctionBar";
import EngagementTable from "./EngagementTable";

const ListMyEngagement = () => {
  const { t } = useTranslation();
  const [displayOption, setDisplayOption] = useState({
    admin: false,
    archive: false,
  });
  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} md={3} lg={5} mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("List of my engagement")}
              </SuiTypography>
            </Grid>
            <EngagementFunctionBar
              displayOption={displayOption}
              setDisplayOption={setDisplayOption}
            />
          </Grid>
          <EngagementTable displayOption={displayOption} />
        </SuiBox>
      </CdxCard>
    </SuiBox>
  );
};

export default ListMyEngagement;
