import CdxCard from "components/cdx/CdxCard";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { connect } from "react-redux";
import { Divider } from "@mui/material";

import ConfirmationTemplateParse from "app/utils/ConfirmationTemplateParse";

import { loadEngagementData, loadEngagementLetter } from "actions";
import { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { BASE_PATH } from "settings";

const EngagementLetterPreview = ({
  engagementData,
  letters,
  loadEngagementData,
  loadEngagementLetter,
}) => {
  const { t } = useTranslation();
  const [loadLetter, setLoadLetter] = useState({});
  const [template, setTemplate] = useState("");
  const [value, setValue] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [letter_uuid, setLetterUuid] = useState(
    (searchParams.get("letter_uuid") || "").replace(/-/gi, "")
  );

  useEffect(() => {
    setLetterUuid((searchParams.get("letter_uuid") || "").replace(/-/gi, ""));
  }, [searchParams]);

  useEffect(() => {
    if (Object.values(letters).length > 0 && letter_uuid) {
      setLoadLetter(letters[letter_uuid]);
    } else {
      setLoadLetter({});
    }
  }, [letters, letter_uuid]);

  useEffect(() => {
    if (!letters[letter_uuid]) {
      setLoadLetter({});
    }
    if (letter_uuid) {
      loadEngagementLetter(letter_uuid, "preview");
    }
  }, [letter_uuid]);

  useEffect(() => {
    if (Object.keys(loadLetter).length > 0) {
      if (!loadLetter.is_invalid_uuid) {
        if (!engagementData[loadLetter.engagementUUID.replace(/-/gi, "")]) {
          loadEngagementData(loadLetter.engagementUUID);
        } else {
          setTemplate(
            engagementData[loadLetter.engagementUUID.replace(/-/gi, "")]
              .information.templates[loadLetter.confirm_information.template]
              .template
          );
          const val = {};
          Object.entries(loadLetter.confirm_information.balance).forEach(
            ([k, v]) => {
              val[`balance__${k}`] = v.toLocaleString();
            }
          );
          Object.entries(loadLetter.confirm_information.transaction).forEach(
            ([k, v]) => {
              val[`transaction__${k}`] = v.toLocaleString();
            }
          );
          val["recipient_name"] = loadLetter.recipients.at(0).name;
          val["confirm_entity_name"] =
            loadLetter.confirm_information.confirm_entity_name;

          setValue(val);
        }
      } else {
        setTemplate("");
        setValue({});
      }
    }
  }, [loadLetter, engagementData]);

  return (
    <>
      {Object.keys(loadLetter).length > 0 && loadLetter.timestamp && (
        <Navigate
          to={`${BASE_PATH}/engagement/track/view?letter_uuid=${letter_uuid}`}
        />
      )}
      <SuiBox mt={3}>
        {loadLetter.is_invalid_uuid ? (
          <SuiTypography variant="h6">{t("Invalid letter uuid")}</SuiTypography>
        ) : (
          <CdxCard p={3}>
            <SuiTypography variant="h6">{t("Preview letter")}</SuiTypography>
            <Divider />
            <ConfirmationTemplateParse template={template} values={value} />
          </CdxCard>
        )}
      </SuiBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementUUID: state.app.activeEngagementUUID,
    engagementData: state.engagement.engagementData,
    letters: state.engagement.letters,
  };
};

const mapDispatchToProps = { loadEngagementLetter, loadEngagementData };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementLetterPreview);
