import { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";
import SuiInput from "components/sui/SuiInput";
import SuiSelect from "components/sui/SuiSelect";
import SuiTypography from "components/sui/SuiTypography";

import { codexApiCatchError } from "actions/snackBar";
import { listMyBusiness } from "actions";

import { useTranslation } from "react-i18next";

import ViewButton from "../ViewButton";
import EditButton from "../EditButton";
import AdminButton from "../AdminButton";

import getNames from "app/utils/getNames";
import { Icon } from "@mui/material";

const SearchNFilter = ({
  setRows,
  setPageSize,
  codexApiCatchError,
  myBusiness,
  listMyBusiness,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [myBusinessAdj, setMyBusinessAdj] = useState([]);

  useEffect(() => {
    listMyBusiness();
  }, []);

  const actionButton = (type, is_verified, businessUUID) => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <ViewButton businessUUID={businessUUID} />
        </Grid>
        {type >= 2 && (
          <>
            <Grid item>
              <AdminButton businessUUID={businessUUID} />
            </Grid>
            {!is_verified && (
              <Grid item>
                <EditButton businessUUID={businessUUID} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  };

  const rebuildBusiness = (businesses) => {
    return businesses.map((business) => {
      const name = getNames(business.names);

      return {
        type: business.membershipType,
        name: name,
        names: business.names,
        action: actionButton(
          business.membershipType,
          business.is_verified,
          business.businessUUID
        ),
        visibility: business.visibility === 1 ? t("Public") : t("Private"),
        is_verified: business.is_verified ? (
          <Icon color="success">verified_user</Icon>
        ) : (
          <Icon color="error">dangerous</Icon>
        ),
      };
    });
  };

  const filterBusiness = (space) => {
    const searchRegExp = new RegExp(search, "i");
    let arr1 = space.filter((e) => {
      let valid = e.name.search(searchRegExp) >= 0;
      valid =
        valid ||
        e.names.filter((e) => e.name.search(searchRegExp) >= 0).length > 0;
      return valid;
    });

    const combineArr = [...new Set([...arr1])];
    arr1 = null;

    return combineArr;
  };

  useEffect(() => {
    setMyBusinessAdj(rebuildBusiness(myBusiness));
  }, [myBusiness]);

  useEffect(() => {
    if (!search) {
      setRows(myBusinessAdj);
    } else {
      setRows(filterBusiness(myBusinessAdj));
    }
  }, [myBusinessAdj, search]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} lg={5} xl={4}>
        <SuiInput
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          icon={{
            component: "search",
            direction: "left",
          }}
        />
      </Grid>
      <Grid item xs={12} lg={7} xl={8}>
        <Grid
          container
          columnSpacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <SuiBox display="flex" alignItems="center">
              <SuiSelect
                defaultValue={{ value: 10, label: 10 }}
                options={entries.map((entry) => ({
                  value: entry.value,
                  label: entry.label,
                }))}
                onChange={(e) => {
                  setPageSize(e.value);
                }}
                size="small"
              />
              <SuiTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const entries = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: -1, label: "Show all" },
];

const mapStateToProps = (state) => {
  return { myBusiness: state.business.myBusiness };
};

const mapDispatchToProps = { codexApiCatchError, listMyBusiness };

export default connect(mapStateToProps, mapDispatchToProps)(SearchNFilter);
