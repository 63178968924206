import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxDataTable from "components/cdx/CdxDataTable";
import CdxCard from "components/cdx/CdxCard";
import { useState } from "react";

import { useTranslation } from "react-i18next";

import ConfirmerInboxFunctionBar from "./FunctionBar";

import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

const ConfirmerInbox = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

  return (
    <>
      <SuiBox mt={3}>
        <CdxCard p={3}>
          <SuiBox mb={2}>
            <SuiTypography variant="h6">Confirmer inbox</SuiTypography>
            <ConfirmerInboxFunctionBar setRows={setRows} />
          </SuiBox>
          <CdxDataTable
            entriesPerPage={{
              defaultValue: 5,
            }}
            showSelectEntriesPerPage={false}
            table={{
              columns: [
                {
                  Header: t("Action"),
                  accessor: "action",
                  width: "10%",
                },
                {
                  Header: t("Confirm entity"),
                  accessor: "confirm_entity_name",
                },
                {
                  Header: t("Sender name"),
                  accessor: "client_name",
                },
                {
                  Header: t("Auditor name"),
                  accessor: "auditor_name",
                },
                {
                  Header: t("Receive at"),
                  accessor: "timestamp",
                  Cell: ({ value }) => {
                    return isoTimeToLocaleTimeString(value);
                  },
                },
                {
                  Header: t("Expire at"),
                  accessor: "expire_at",
                  Cell: ({ value }) => {
                    if (value) {
                      return isoTimeToLocaleTimeString(value);
                    } else {
                      return t("No expire date");
                    }
                  },
                },
              ],
              rows: rows,
            }}
          />
        </CdxCard>
      </SuiBox>
    </>
  );
};

export default ConfirmerInbox;
