import SuiButton from "components/sui/SuiButton";
import SuiBox from "components/sui/SuiBox";
import { CircularProgress } from "@mui/material";

const CdxButton = ({ progress = false, children, ...rest }) => {
  if (progress) {
    return (
      <SuiButton {...rest} disabled>
        <SuiBox display="flex" alignItems="center" mr={1}>
          <CircularProgress size={10} color="white" />
        </SuiBox>
        {children}
      </SuiButton>
    );
  } else {
    return <SuiButton {...rest}>{children}</SuiButton>;
  }
};

export default CdxButton;
