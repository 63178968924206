import { auth, authConfirmer, authOtp } from "./config";

import { onAuthStateChanged } from "firebase/auth";

import store from "store";

import { signOut, reloadToken as actionReloadToken } from "actions";

import {
  confSignOut,
  confReloadToken as confirmerActionReloadToken,
} from "actions/confUser";

import { TIME_TO_RELOAD_TOKEN } from "settings/firebase";
import { loadCdxUser } from "actions/user";

import reloadToken from "app/fb/reloadToken";
import confReloadToken from "app/fb/confirmer/reloadToken";

const authState = () => {
  const dispatch = store.dispatch;

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      dispatch(actionReloadToken(user));
      reloadToken();
      setInterval(() => {
        reloadToken();
      }, TIME_TO_RELOAD_TOKEN);

      if (user.displayName) {
        dispatch(loadCdxUser());
      }
    } else {
      // User is not signed in
      dispatch(signOut());
    }
  });

  onAuthStateChanged(authConfirmer, (user) => {
    if (user) {
      // User is signed in
      dispatch(confirmerActionReloadToken(user));
      confReloadToken();
      setInterval(() => {
        confReloadToken();
      }, TIME_TO_RELOAD_TOKEN);
    } else {
      // User is not signed in
      dispatch(confSignOut());
    }
  });

  onAuthStateChanged(authOtp, (user) => {
    if (user) {
      // User is signed in
      // const uid = user.uid;
      // console.log("detect login Otp");
      // console.log(uid);
      // console.log(user);
    } else {
      // User is not signed in
      // console.log("Log out Otp");
    }
  });
};

export default authState;
