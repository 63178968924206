import { auth } from "./config";

import { signOut as fbSignOut } from "firebase/auth";


const signOut = () => {
  // const dispatch = store.dispatch;

  fbSignOut(auth)
    .then(() => {
      // Sign-out successful.
      // dispatch(dispatchSignOut());
    })
    .catch((error) => {
      // An error happened.
    });

  return <></>;
};

export default signOut;
