import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Divider, Grid } from "@mui/material";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";

import { Form, Field } from "react-final-form";

import { LANGUAGES_OPTIONS } from "settings/i18n";

import { rootElement } from "settings";
import CdxSwitch from "components/cdx/CdxSwitch";

import SuiButton from "components/sui/SuiButton";

import CodexAPI from "app/api/codexapi";

import { connect } from "react-redux";
import { deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

import { BASE_PATH } from "settings";

const CreateNewBusinessForm = ({ deploySnackbar, codexApiCatchError }) => {
  const { t } = useTranslation();
  const tl = useTranslation("languages").t;

  const navigate = useNavigate();

  const lang = LANGUAGES_OPTIONS.map((e) => {
    return { value: e.value, label: tl(e.label) };
  });
  const defaultLng = lang.find((e) => e.value === "en");
  const langOptions = lang.filter((e) => e.value !== "en");

  const validateForm = (values) => {
    const error = {};
    if (!values.name1) {
      error.name1 = "Please enter company name";
    }
    if (!values.nationality) {
      error.nationality = "Please enter company nationality";
    }
    return error;
  };

  const onSubmit = async (values) => {
    try {
      const data = {
        information: { names: [{ lang: values.lang1, name: values.name1 }] },
        visibility: values.visibility,
      };

      if (values.lang2 && values.name2) {
        data.information.names = [
          ...data.information.names,
          { lang: values.lang2, name: values.name2 },
        ];
      }

      data.information.entity_id = values.entity_id;
      data.information.nationality = values.nationality;
      data.private_information = {
        website: values.website,
        address: values.address,
        description: values.description,
      };

      const response = await CodexAPI().post("business/create-business/", data);
      const uuid = response.data.data.uuid;
      setTimeout(() => {
        navigate(`${BASE_PATH}/overview/business/view-business?uuid=${uuid}`);
      }, 1000);
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Successfully add new business",
        content: `New business is added. Redirect to view business.`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <Grid
            container
            columnSpacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={4}>
              <Field name="lang1" initialValue={defaultLng.value}>
                {({ input, meta }) => (
                  <CdxInput
                    value={defaultLng.label}
                    label={t("Language")}
                    disabled={true}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={8}>
              <Field name="name1">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Business name*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="lang2">
                {({ input, meta }) => (
                  <CdxSelect
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    options={langOptions}
                    label={t("Other language")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                    menuPortalTarget={rootElement}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={8}>
              <Field name="name2">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Business name (optional)")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="nationality">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Nationality*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="entity_id">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Entity id such as tax id")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <SuiBox px={2}>
                <SuiTypography variant="h6">
                  {t("Private information section")}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} md={5}>
              <Field name="website">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Website")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={7}>
              <Field name="address">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Address")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="description">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Description")}
                    multiline
                    rows={5}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="visibility" initialValue={false}>
                {({ input, meta }) => (
                  <CdxSwitch
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    options={langOptions}
                    label={[t("Public"), t("Private")]}
                    spaceLabelAtTop={false}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SuiButton
                variant="gradient"
                color="primary"
                type="submit"
                fullWidth
                disabled={
                  submitting ||
                  pristine ||
                  Object.values(formState.errors).length > 0
                }
              >
                {t("Create new business")}
              </SuiButton>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewBusinessForm);
