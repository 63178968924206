import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Grid } from "@mui/material";

import SuiTypography from "components/sui/SuiTypography";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";

import { Form, Field } from "react-final-form";

import { rootElement } from "settings";

import SuiButton from "components/sui/SuiButton";

import CodexAPI from "app/api/codexapi";

import { connect } from "react-redux";
import { listMyBusiness, deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

import { ENGAGEMENT_TEAM_OPTIONS } from "settings/confirmation";

import { BASE_PATH } from "settings";
import getNames from "app/utils/getNames";

const CreateNewEngagementForm = ({
  myBusiness,
  listMyBusiness,
  deploySnackbar,
  codexApiCatchError,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [counterUuid, setCounterUuid] = useState(
    searchParams.get("counter_uuid") || ""
  );

  const [myBusinessOptions, setMyBusinessOptions] = useState([]);

  useEffect(() => {
    setCounterUuid(searchParams.get("counter_uuid") || "");
  }, [searchParams]);

  useEffect(() => {
    if (myBusiness) {
      const myAdminBusiness = myBusiness
        .filter((e) => e.membershipType >= 2)
        .map((e) => {
          const businessName = getNames(e.names);
          return { value: e.businessUUID, label: businessName };
        });
      setMyBusinessOptions(myAdminBusiness);
    }
  }, [myBusiness]);

  useEffect(() => {
    listMyBusiness();
  }, []);

  const navigate = useNavigate();

  const validateForm = (values) => {
    const error = {};
    if (!values.mutual_engagement_name) {
      error.mutual_engagement_name = "Please enter a mutual engagement name";
    }
    return error;
  };

  const onSubmit = async (values) => {
    try {
      const data = { ...values };
      const response = await CodexAPI().post(
        "engagement/create-engagement/",
        data
      );
      setTimeout(() => {
        navigate(`${BASE_PATH}/overview/engagement/manage-engagement`);
      }, 1000);
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Successfully add new engagement",
        content: `New engagement is added. Redirect to manage engagement.`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <Grid
            container
            columnSpacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={6} lg={4}>
              <Field name="mutual_engagement_name">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Mutual engagement name*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="create_as">
                {({ input, meta }) => (
                  <CdxSelect
                    label={t("You are*")}
                    options={ENGAGEMENT_TEAM_OPTIONS}
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                    menuPortalTarget={rootElement}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="business_uuid">
                {({ input, meta }) => (
                  <CdxSelect
                    label={t("Your business*")}
                    options={myBusinessOptions}
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                    menuPortalTarget={rootElement}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} md={4}>
              <Field name="counterparty_uuid" initialValue={counterUuid}>
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Counterparty uuid*")}
                    disabled={!!counterUuid}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="counterparty_email">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Counterparty manager email*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SuiButton
                variant="gradient"
                color="primary"
                type="submit"
                fullWidth
                disabled={
                  submitting ||
                  pristine ||
                  Object.values(formState.errors).length > 0
                }
              >
                {t("Create a new engagement")}
              </SuiButton>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    myBusiness: state.business.myBusiness,
  };
};

const mapDispatchToProps = {
  listMyBusiness,
  deploySnackbar,
  codexApiCatchError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewEngagementForm);
