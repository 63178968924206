import { useState } from "react";

import { connect } from "react-redux";

import Icon from "@mui/material/Icon";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import FullNameForm from "./FullNameForm";

const FullName = ({ displayName }) => {
  const [onEdit, setOnEdit] = useState(false);

  return (
    <SuiBox>
      {onEdit && <FullNameForm setOnEdit={setOnEdit} />}
      {!onEdit && (
        <SuiTypography variant="h6">
          {displayName}
          {"\u00A0"}
          <Icon onClick={() => setOnEdit(true)} sx={{ cursor: "pointer" }}>
            edit
          </Icon>
        </SuiTypography>
      )}
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    displayName: state.user.fbData.displayName,
  };
};

export default connect(mapStateToProps)(FullName);
