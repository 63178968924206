import { connect } from "react-redux";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSearchParams, Navigate, useNavigate } from "react-router-dom";

import AuthLayout from "components/layouts/AuthLayout";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import isSignInWithEmailLink from "app/fb/isSignInWithEmailLink";

import EmailSignInForm from "./form";

const EmailSignIn = ({ isSignIn, isEmailSignIn }) => {
  const { t } = useTranslation();
  const [validLink, setValidLink] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState(
    searchParams.get("redirect_to") || "/confirmer"
  );

  const navigate = useNavigate();

  useEffect(() => {
    setValidLink(isSignInWithEmailLink());
  }, []);

  return (
    <>
      {!isEmailSignIn ? (
        <AuthLayout>
          <SuiBox
            p={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            width="100%"
          >
            <SuiBox>
              <SuiTypography variant="h5">{t("Email sign in")}</SuiTypography>
            </SuiBox>
            {validLink ? (
              <EmailSignInForm />
            ) : (
              <SuiBox mt={3} width="100%">
                <SuiTypography variant="body2" color="error">
                  {t("Invalid link")}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </AuthLayout>
      ) : (
        <Navigate to={redirectTo} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignIn: state.app.isSignIn,
    isEmailSignIn: state.app.isEmailSignIn,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSignIn);
