import { Navigate } from "react-router-dom";

import NonAuthHome from "pages/non-auth/home";

import SignIn from "pages/sign-in";
import SignUp from "pages/sign-up";
import SignOut from "pages/sign-out";
import EmailSignIn from "pages/email-sign-in";
import ResetPassword from "pages/reset-password";
import ConfirmerSignIn from "pages/confirmer/sign-in";

import CompleteSignUp from "pages/complete-sign-up";
import CompleteResetPassword from "pages/complete-reset-password";

import Home from "pages/overview/home";
import ListMyBusiness from "pages/overview/business/list-my-business";
import CreateNewBusiness from "pages/overview/business/create-new-business";
import ManageBusiness from "pages/overview/business/manage-business";
import ViewBusiness from "pages/overview/business/view-business";
import EditBusiness from "pages/overview/business/edit-business";

import ListMyEngagement from "pages/overview/engagement/list-my-engagement";
import CreateNewEngagement from "pages/overview/engagement/create-new-engagement";
import ManageEngagement from "pages/overview/engagement/manage-engagement";

import EngagementHome from "pages/engagement/home";
import EngagementEdit from "pages/engagement/edit";
import EngagementAddConfirm from "pages/engagement/add-confirm";
import EngagementReviewLetter from "pages/engagement/review";
import EngagementTrack from "pages/engagement/track";
import EngagementRedirect from "pages/engagement/redirect";
import EngagementLetterPreview from "pages/engagement/letter/preview";
import EngagementLetterView from "pages/engagement/letter/view";

import ConfirmerHome from "pages/confirmer/home";
import ConfirmerInbox from "pages/confirmer/inbox";
import ConfirmerHistory from "pages/confirmer/history";
import ConfirmerLetter from "pages/confirmer/letter";
import ConfirmerSignOut from "pages/confirmer/sign-out";

import NotFound404 from "pages/NotFound404";
import RegisterRequired from "pages/RegisterRequired";

import Profile from "pages/profile";

import { BASE_PATH } from "settings";

export const normalRoutes = [
  {
    key: "/complete-sign-up",
    path: `/complete-sign-up`,
    element: <CompleteSignUp />,
  },
  {
    key: "/complete-reset-password",
    path: `/complete-reset-password`,
    element: <CompleteResetPassword />,
  },
  {
    key: "/confirmer/sign-in",
    path: `/confirmer/sign-in`,
    element: <ConfirmerSignIn />,
  },
  {
    key: "/email-sign-in",
    path: `/email-sign-in`,
    element: <EmailSignIn />,
  },
];

export const nonAuthRoutes = [
  { key: "/sign-in", path: `/sign-in`, element: <SignIn /> },
  { key: "/sign-up", path: `/sign-up`, element: <SignUp /> },
  {
    key: "/reset-password",
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  { key: "/overview/*", path: `/overview/*`, signInRequired: true },
  { key: "/engagement/*", path: `/engagement/*`, signInRequired: true },
  {
    key: "/confirmer/*",
    path: `/confirmer/*`,
    signInRequired: true,
    signInPage: "/confirmer/sign-in",
  },
  {
    key: "/non-auth-home",
    path: `/`,
    element: <NonAuthHome />,
  },
  { key: "/not-found/nonAuth", path: `*`, element: <NotFound404 /> },
];

export const overviewRoutes = [
  { type: "title", title: "Overview", key: "/overview" },
  {
    type: "nocollapse",
    name: "Home",
    icon: "home",
    key: "/overview/home",
    path: `${BASE_PATH}/overview/home`,
    element: <Home />,
  },
  {
    type: "collapse",
    name: "Business",
    icon: "business",
    key: "/overview/business",
    path: "/overview/business",
    collapse: [
      {
        name: "List my business",
        key: "/overview/business/list-my-business",
        path: `${BASE_PATH}/overview/business/list-my-business`,
        element: <ListMyBusiness />,
      },
      {
        name: "Create new business",
        key: "/overview/business/create-new-business",
        path: `${BASE_PATH}/overview/business/create-new-business`,
        element: <CreateNewBusiness />,
      },
      {
        name: "Manage business",
        key: "/overview/business/manage-business",
        path: `${BASE_PATH}/overview/business/manage-business`,
        element: <ManageBusiness />,
      },
      {
        name: "View business",
        key: "/overview/business/view-business",
        path: `${BASE_PATH}/overview/business/view-business`,
        element: <ViewBusiness />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Engagement",
    icon: "work",
    key: "/overview/engagement",
    path: "/overview/engagement",
    collapse: [
      {
        name: "List my engagement",
        key: "/overview/engagement/list-my-engagement",
        path: `${BASE_PATH}/overview/engagement/list-my-engagement`,
        element: <ListMyEngagement />,
      },
      {
        name: "Create new engagement",
        key: "/overview/engagement/create-new-engagement",
        path: `${BASE_PATH}/overview/engagement/create-new-engagement`,
        element: <CreateNewEngagement />,
      },
      {
        name: "Manage engagement",
        key: "/overview/engagement/manage-engagement",
        path: `${BASE_PATH}/overview/engagement/manage-engagement`,
        element: <ManageEngagement />,
      },
    ],
  },
  {
    key: "/overview/business/edit-business",
    path: `${BASE_PATH}/overview/business/edit-business`,
    element: <EditBusiness />,
  },
  { type: "divider", key: "divider-overview" },
];

export const engagementRoutes = [
  { type: "title", title: "Engagement", key: "/engagement" },
  {
    type: "nocollapse",
    name: "Engagement home",
    icon: "home",
    key: "/engagement/engagement-home",
    path: `${BASE_PATH}/engagement/engagement-home`,
    element: <EngagementHome />,
  },
  {
    type: "nocollapse",
    name: "Edit engagement",
    icon: "edit",
    key: "/engagement/edit-engagement",
    path: `${BASE_PATH}/engagement/edit-engagement`,
    archiveNotShow: true,
    element: <EngagementEdit />,
  },
  {
    type: "nocollapse",
    name: "Add confirm",
    icon: "add_circle",
    key: "/engagement/add-confirm",
    path: `${BASE_PATH}/engagement/add-confirm`,
    archiveNotShow: true,
    element: <EngagementAddConfirm />,
  },
  {
    type: "nocollapse",
    name: "Under review",
    icon: "preview",
    key: "/engagement/under-review",
    path: `${BASE_PATH}/engagement/under-review`,
    archiveNotShow: true,
    element: <EngagementReviewLetter />,
  },
  {
    type: "nocollapse",
    name: "Track",
    icon: "inventory_2",
    key: "/engagement/track",
    path: `${BASE_PATH}/engagement/track`,
    element: <EngagementTrack />,
  },
  { type: "divider", key: "divider-engagement" },
  {
    key: "/engagement/under-review/preview",
    path: `${BASE_PATH}/engagement/under-review/preview`,
    element: <EngagementLetterPreview />,
  },
  {
    key: "/engagement/track/view",
    path: `${BASE_PATH}/engagement/track/view`,
    element: <EngagementLetterView />,
  },
  {
    key: "/engagement/track/response",
    path: `${BASE_PATH}/engagement/track/response`,
    element: <EngagementLetterView />,
  },
  {
    key: "/engagement/*",
    path: `${BASE_PATH}/engagement/*`,
    element: <NotFound404 />,
  },
];

export const confirmerRoutes = [
  { type: "title", title: "Confirmer", key: "/confirmer" },
  {
    type: "nocollapse",
    name: "Home",
    icon: "home",
    key: "/confirmer/home",
    path: `${BASE_PATH}/confirmer/home`,
    element: <ConfirmerHome />,
  },
  {
    type: "nocollapse",
    name: "Inbox",
    icon: "inbox",
    key: "/confirmer/inbox",
    path: `${BASE_PATH}/confirmer/inbox`,
    element: <ConfirmerInbox />,
  },
  {
    key: "/confirmer/inbox/letter",
    path: `${BASE_PATH}/confirmer/inbox/letter`,
    element: <ConfirmerLetter />,
  },
  {
    key: "/confirmer/inbox/letter/response",
    path: `${BASE_PATH}/confirmer/inbox/letter/response`,
    element: <ConfirmerLetter />,
  },
  {
    type: "nocollapse",
    name: "History",
    icon: "history",
    key: "/confirmer/history",
    path: `${BASE_PATH}/confirmer/history`,
    element: <ConfirmerHistory />,
  },
  {
    key: "/confirmer/history/letter",
    path: `${BASE_PATH}/confirmer/history/letter`,
    element: <ConfirmerLetter />,
  },
  {
    key: "/confirmer/history/letter/response",
    path: `${BASE_PATH}/confirmer/history/letter/response`,
    element: <ConfirmerLetter />,
  },
  {
    key: "/confirmer/view-business",
    path: `${BASE_PATH}/confirmer/view-business`,
    element: <ViewBusiness />,
  },
  {
    key: "/confirmer",
    path: `${BASE_PATH}/confirmer`,
    element: <Navigate to={`${BASE_PATH}/confirmer/home`} />,
  },
  {
    key: "/confirmer/sign-in",
    path: `/confirmer/sign-in`,
    element: <Navigate to={`${BASE_PATH}/confirmer/home`} />,
  },
  {
    key: "/confirmer/sign-out",
    path: `${BASE_PATH}/confirmer/sign-out`,
    element: <ConfirmerSignOut />,
  },
  {
    key: "/confirmer/*",
    path: `${BASE_PATH}/confirmer/*`,
    element: <NotFound404 />,
  },
];

export const appRoutes = [
  ...normalRoutes,
  { type: "title", title: "Resource", key: "/resource" },
  {
    type: "nocollapse",
    name: "Help center",
    icon: "help",
    key: "/help-center",
    path: `${BASE_PATH}/help-center`,
    element: <NotFound404 />,
  },
  {
    key: "/profile-&-setting",
    path: `${BASE_PATH}/profile-&-setting`,
    element: <Profile />,
  },
  {
    key: "/engagement/redirect",
    path: `${BASE_PATH}/engagement/*`,
    element: <EngagementRedirect />,
  },
  {
    key: "/home",
    path: `${BASE_PATH}/home`,
    element: <Navigate to={`${BASE_PATH}/overview/home`} />,
  },
  {
    key: "/overview",
    path: `${BASE_PATH}/overview`,
    element: <Navigate to={`${BASE_PATH}/overview/home`} />,
  },
  {
    key: "/sign-in",
    path: `${BASE_PATH}/sign-in`,
    element: <Navigate to={`${BASE_PATH}/overview/home`} />,
  },
  { key: "/sign-out", path: `${BASE_PATH}/sign-out`, element: <SignOut /> },
  { key: "/not-found/app", path: `${BASE_PATH}*`, element: <NotFound404 /> },
];
