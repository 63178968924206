import { connect } from "react-redux";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";
import { useTranslation } from "react-i18next";

import FullName from "./FullName";
// import ProfileImage from "./ProfileImage";

const Profile = ({ email }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CdxCard>
          <SuiBox p={3}>
            <SuiBox mb={2}>
              <SuiTypography variant="h6">{t("Profile")}</SuiTypography>
            </SuiBox>
            <SuiBox sx={{ display: "flex", alignItems: "center" }}>
              <SuiBox>
                {/* <ProfileImage /> */}
              </SuiBox>
              <SuiBox ml={4} sx={{ flexGrow: 1 }}>
                <FullName />
                <SuiTypography variant="button">{email}</SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </CdxCard>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
  };
};

export default connect(mapStateToProps)(Profile);
