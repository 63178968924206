import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid, Icon } from "@mui/material";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";
import CdxButton from "components/cdx/CdxButton";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";

import { BUSINESS_MEMBERSHIP_OPTIONS } from "settings/confirmation";
import CodexAPI from "app/api/codexapi";

import { businessMember } from "actions";
import { deploySnackbar } from "actions";
import { rootElement } from "settings";

import GotoButton from "../GotoButton";
import EditButton from "../EditButton";

const LetterReviewFunctionBar = ({
  setOurRows,
  setCounterpartyRows,
  engagementData,
  deploySnackbar,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [rawOurRows, setRawOurRows] = useState([]);
  const [rawCounterRows, setRawCounterRows] = useState([]);

  useEffect(() => {
    const team = engagementData.you.team;
    const billing = engagementData.billingUUID === engagementData.auditorUUID;
    let auditorPending = [];
    let clientPending = [];
    if (billing) {
      auditorPending = engagementData.requests.filter((e) => {
        return e.client_accepted && !e.auditor_accepted;
      });
      clientPending = engagementData.requests.filter((e) => {
        return !e.client_accepted;
      });
    } else {
      auditorPending = engagementData.requests.filter((e) => {
        return !e.auditor_accepted;
      });
      clientPending = engagementData.requests.filter((e) => {
        return e.auditor_accepted && !e.client_accepted;
      });
    }

    if (team) {
      auditorPending = auditorPending.map((e) => {
        return {
          ...e,
          action: (
            <Grid container spacing={1}>
              <Grid item>
                <GotoButton letterUUID={e.letterUUID} />
              </Grid>
            </Grid>
          ),
        };
      });
      clientPending = clientPending.map((e) => {
        return { ...e, action: <GotoButton letterUUID={e.letterUUID} /> };
      });
      setRawOurRows(auditorPending);
      setRawCounterRows(clientPending);
    } else {
      clientPending = clientPending.map((e) => {
        return {
          ...e,
          action: (
            <Grid container spacing={1}>
              <Grid item>
                <GotoButton letterUUID={e.letterUUID} />
              </Grid>
            </Grid>
          ),
        };
      });
      auditorPending = auditorPending.map((e) => {
        return { ...e, action: <GotoButton letterUUID={e.letterUUID} /> };
      });
      setRawOurRows(clientPending);
      setRawCounterRows(auditorPending);
    }
  }, [engagementData]);

  useEffect(() => {
    if (search) {
      const searchLower = search.toLowerCase();
      const arr1 = [...rawOurRows].filter((e) => {
        return (
          e.confirm_entity_code.toLowerCase().includes(searchLower) ||
          e.confirm_entity_name.toLowerCase().includes(searchLower)
        );
      });
      const arr2 = [...rawOurRows].filter((e) => {
        return e.template.includes(search);
      });
      const arr3 = [...rawOurRows].filter((e) => {
        let find = false;
        e.recipients.forEach((r) => {
          if (r.name.toLowerCase().includes(searchLower)) {
            find = true;
          } else if (r.email.toLowerCase().includes(searchLower)) {
            find = true;
          }
        });
        return find;
      });
      const combineArr = [...new Set([...arr1, ...arr2, ...arr3])];
      setOurRows(combineArr);
    } else {
      setOurRows(rawOurRows);
    }
  }, [rawOurRows, search]);

  useEffect(() => {
    if (search) {
      const searchLower = search.toLowerCase();
      const arr1 = [...rawCounterRows].filter((e) => {
        return (
          e.confirm_entity_code.toLowerCase().includes(searchLower) ||
          e.confirm_entity_name.toLowerCase().includes(searchLower)
        );
      });
      const arr2 = [...rawCounterRows].filter((e) => {
        return e.template.includes(search);
      });
      const arr3 = [...rawCounterRows].filter((e) => {
        let find = false;
        e.recipients.forEach((r) => {
          if (r.name.toLowerCase().includes(searchLower)) {
            find = true;
          } else if (r.email.toLowerCase().includes(searchLower)) {
            find = true;
          }
        });
        return find;
      });
      const combineArr = [...new Set([...arr1, ...arr2, ...arr3])];
      setCounterpartyRows(combineArr);
    } else {
      setCounterpartyRows(rawCounterRows);
    }
  }, [rawCounterRows, search]);

  return (
    <>
      <Grid mt={1} container spacing={2} direction="row">
        <Grid item xs>
          <CdxInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            icon={{
              component: "search",
              direction: "left",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {
  deploySnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LetterReviewFunctionBar);
