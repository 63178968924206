import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import EngagementRedirect from "pages/engagement/redirect";

import CdxCard from "components/cdx/CdxCard";

import { connect } from "react-redux";
import CdxDataTable from "components/cdx/CdxDataTable";

import { Grid, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import LetterTrackFunctionBar from "./FunctionBar";
import isoTimeToLocaleTimeString from "app/utils/isoTimeToLocaleTimeString";

import getNames from "app/utils/getNames";
import CdxBadgeDot from "components/cdx/CdxBadgeDot";

import Checkbox from "@mui/material/Checkbox";
import AcceptLettersButton from "./AcceptLettersButton";
import RejectLettersButton from "./RejectLettersButton";
import ApproveLettersButton from "./ApproveLettersButton";

const EngagementReviewLetter = ({ engagementUUID, engagementData }) => {
  const { t } = useTranslation();
  const [ourRows, setOurRows] = useState([]);
  const [counterpartyRows, setCounterpartyRows] = useState([]);

  const [ourTitle, setOurTitle] = useState("Our turn");
  const [counterpartyTitle, setCounterpartyTitle] =
    useState("Counteryparty turn");

  const [ourName, setOurName] = useState("You");
  const [counterName, setCounterName] = useState("Counterparty");
  const [selectLetter, setSelectLetter] = useState([]);
  const [selectCounterLetter, setSelectCounterLetter] = useState([]);

  const [myApprove, setMyApprove] = useState(false);

  const onCheckBoxClick = (check, uuid) => {
    if (check) {
      if (!selectLetter.includes(uuid)) {
        setSelectLetter([...selectLetter, uuid]);
      }
    } else {
      if (selectLetter.includes(uuid)) {
        setSelectLetter([...selectLetter.filter((e) => e !== uuid)]);
      }
    }
  };

  const onCounterCheckBoxClick = (check, uuid) => {
    if (check) {
      if (!selectCounterLetter.includes(uuid)) {
        setSelectCounterLetter([...selectCounterLetter, uuid]);
      }
    } else {
      if (selectCounterLetter.includes(uuid)) {
        setSelectCounterLetter([
          ...selectCounterLetter.filter((e) => e !== uuid),
        ]);
      }
    }
  };

  useEffect(() => {
    if (engagementData) {
      const billing = engagementData.billingUUID === engagementData.auditorUUID;
      if (billing == engagementData.you.team) {
        setOurTitle("To approve by you");
        setCounterpartyTitle("To accept by counterparty");
        setMyApprove(true);
      } else {
        setOurTitle("To accept by you");
        setCounterpartyTitle("To approve by counterparty");
        setMyApprove(false);
      }
      if (engagementData.you.team) {
        setOurName(getNames(engagementData.auditorNames));
        setCounterName(getNames(engagementData.clientNames));
      } else {
        setOurName(getNames(engagementData.clientNames));
        setCounterName(getNames(engagementData.auditorNames));
      }
    }
  }, [engagementData]);

  return (
    <EngagementRedirect>
      {engagementData && (
        <SuiBox mt={3}>
          <CdxCard>
            <SuiBox p={3}>
              <SuiTypography variant="h5">
                {t("Review")} - {engagementData.mutual_engagement_name}
              </SuiTypography>
              <LetterTrackFunctionBar
                setOurRows={setOurRows}
                setCounterpartyRows={setCounterpartyRows}
              />
            </SuiBox>
          </CdxCard>
          {ourRows.length > 0 && (
            <CdxCard mt={2}>
              <SuiBox p={3}>
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <SuiBox>
                    <SuiTypography variant="h6">
                      {t(ourTitle)} - {ourName}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <Grid container spacing={1}>
                      <Grid item>
                        <RejectLettersButton
                          selectLetter={selectLetter}
                          engagementUUID={engagementUUID}
                          setSelectLetter={setSelectLetter}
                        />
                      </Grid>
                      <Grid item>
                        {myApprove ? (
                          <ApproveLettersButton
                            selectLetter={selectLetter}
                            engagementUUID={engagementUUID}
                            setSelectLetter={setSelectLetter}
                          />
                        ) : (
                          <AcceptLettersButton
                            selectLetter={selectLetter}
                            engagementUUID={engagementUUID}
                            setSelectLetter={setSelectLetter}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </SuiBox>
                </SuiBox>

                <CdxDataTable
                  entriesPerPage={{
                    defaultValue: 5,
                  }}
                  showSelectEntriesPerPage={false}
                  table={{
                    columns: [
                      {
                        Header: t("Select"),
                        accessor: "letterUUID",
                        width: "5%",
                        Cell: ({ value }) => {
                          return (
                            <Checkbox
                              checked={!!selectLetter.find((e) => e === value)}
                              onChange={(e) => {
                                onCheckBoxClick(e.target.checked, value);
                              }}
                            />
                          );
                        },
                      },
                      {
                        Header: t("Action"),
                        accessor: "action",
                        width: "10%",
                      },
                      {
                        Header: t("Company Name"),
                        accessor: "confirm_entity_name",
                      },
                      {
                        Header: t("Confirmer"),
                        accessor: "recipients",
                        Cell: ({ value }) => {
                          return value.map((e) => {
                            const content = e.phone
                              ? `${e.email} (${e.phone})`
                              : e.email;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={content}
                              />
                            );
                          });
                        },
                      },
                      {
                        Header: t("Template"),
                        accessor: "template",
                      },
                      {
                        Header: t("Balance"),
                        accessor: "confirm_balance",
                        Cell: ({ value }) => {
                          return Object.entries(value).map(([k, v]) => {
                            const label =
                              ((engagementData.information || {}).key_mapping ||
                                {})[k] || k;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={`${label} - ${v.toLocaleString()}`}
                              />
                            );
                          });
                        },
                      },
                      {
                        Header: t("Last update at"),
                        accessor: "last_updated_at",
                        Cell: ({ value }) => {
                          return isoTimeToLocaleTimeString(value);
                        },
                      },
                    ],
                    rows: ourRows,
                  }}
                />
              </SuiBox>
            </CdxCard>
          )}
          {counterpartyRows.length > 0 && (
            <CdxCard mt={2}>
              <SuiBox p={3}>
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <SuiBox>
                    <SuiTypography variant="h6">
                      {t(counterpartyTitle)} - {counterName}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <RejectLettersButton
                      selectLetter={selectCounterLetter}
                      engagementUUID={engagementUUID}
                      setSelectLetter={setSelectCounterLetter}
                    />
                  </SuiBox>
                </SuiBox>
                <CdxDataTable
                  entriesPerPage={{
                    defaultValue: 5,
                  }}
                  showSelectEntriesPerPage={false}
                  table={{
                    columns: [
                      {
                        Header: t("Select"),
                        accessor: "letterUUID",
                        width: "5%",
                        Cell: ({ value }) => {
                          return (
                            <Checkbox
                              checked={
                                !!selectCounterLetter.find((e) => e === value)
                              }
                              onChange={(e) => {
                                onCounterCheckBoxClick(e.target.checked, value);
                              }}
                            />
                          );
                        },
                      },
                      {
                        Header: t("Action"),
                        accessor: "action",
                        width: "10%",
                      },
                      {
                        Header: t("Company Name"),
                        accessor: "confirm_entity_name",
                      },
                      {
                        Header: t("Confirmer"),
                        accessor: "recipients",
                        Cell: ({ value }) => {
                          return value.map((e) => {
                            const content = e.phone
                              ? `${e.email} (${e.phone})`
                              : e.email;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={content}
                              />
                            );
                          });
                        },
                      },
                      {
                        Header: t("Template"),
                        accessor: "template",
                      },
                      {
                        Header: t("Balance"),
                        accessor: "confirm_balance",
                        Cell: ({ value }) => {
                          return Object.entries(value).map(([k, v]) => {
                            const label =
                              ((engagementData.information || {}).key_mapping ||
                                {})[k] || k;
                            return (
                              <CdxBadgeDot
                                key={Math.random()}
                                badgeContent={`${label} - ${v.toLocaleString()}`}
                              />
                            );
                          });
                        },
                      },
                      {
                        Header: t("Last update at"),
                        accessor: "last_updated_at",
                        Cell: ({ value }) => {
                          return isoTimeToLocaleTimeString(value);
                        },
                      },
                    ],
                    rows: counterpartyRows,
                  }}
                />
              </SuiBox>
            </CdxCard>
          )}

          {ourRows.length === 0 && counterpartyRows.length === 0 && (
            <SuiBox mt={3}>
              <SuiTypography variant="h6">
                {t("No pending letter request found")}
              </SuiTypography>
            </SuiBox>
          )}
        </SuiBox>
      )}
    </EngagementRedirect>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementUUID: state.app.activeEngagementUUID,
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementReviewLetter);
