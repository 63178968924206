import {
  SIGN_IN,
  SIGN_OUT,
  RELOAD_TOKEN,
  LOAD_CDX_USER,
  UPDATE_USER_SETTING,
  CONF_SIGN_OUT,
} from "actions/types";

import CodexAPI from "app/api/codexapi";
import { deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

import encrypt from "app/utils/encrypt";
import decryptToObject from "app/utils/decryptToObject";
import changeLanguage from "app/utils/changeLanguage";

import { LSK_CDX_USER_INFO_HASH } from "settings/localStorageKeys";
import checkObjectEqual from "app/utils/checkObjectEqual";

export const signIn = (fbData) => {
  return {
    type: SIGN_IN,
    payload: {
      user: {
        uid: fbData.uid,
        email: fbData.email,
        fbData,
        token: fbData.accessToken,
        tokenExpireAt: fbData.stsTokenManager.expirationTime,
      },
    },
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const reloadToken = (fbData) => (dispatch, getState) => {
  const confEmail = getState().confUser.email;
  if (confEmail !== fbData.email && confEmail !== "") {
    dispatch({
      type: SIGN_OUT,
    });
    dispatch({
      type: CONF_SIGN_OUT,
    });
    const snackBar = {
      color: "error",
      icon: "report_problem",
      title: "Other account is signed in",
      content: `Automatic sign off for both accounts, please sign in again.`,
    };
    dispatch(deploySnackbar(snackBar));
  } else {
    dispatch({
      type: RELOAD_TOKEN,
      payload: {
        user: {
          uid: fbData.uid,
          email: fbData.email,
          fbData,
          token: fbData.accessToken,
          tokenExpireAt: fbData.stsTokenManager.expirationTime,
        },
      },
    });
  }
};

export const loadCdxUser = () => async (dispatch, getState) => {
  const email = getState().user.email || "noemail";
  let dataHash = window.localStorage.getItem(LSK_CDX_USER_INFO_HASH);

  const dispatchLoadCdxUser = (data) => {
    dispatch({ type: LOAD_CDX_USER, payload: { ...data } });
  };

  try {
    dataHash = decryptToObject(dataHash);
  } catch {
    dataHash = {};
  }
  if (dataHash.email === email) {
    try {
      const result = await CodexAPI().get("/user/info/", {
        params: { hash: dataHash.hash },
      });
      dispatchLoadCdxUser(result.data.data);
      if (result.data.data.userSetting) {
        changeLanguage(result.data.data.userSetting.language);
      }
    } catch (error) {
      dispatch(codexApiCatchError(error));
    }
  }

  try {
    const hashResult = await CodexAPI().get("/user/info/hash/");
    const hash = hashResult.data.data;
    if (dataHash.hash !== hash) {
      const result = await CodexAPI().get("/user/info/", {
        params: { hash: hash },
      });
      dispatchLoadCdxUser(result.data.data);
      if (result.data.data.userSetting) {
        changeLanguage(result.data.data.userSetting.language);
      }
      window.localStorage.setItem(
        LSK_CDX_USER_INFO_HASH,
        encrypt({ email: email, hash: hash })
      );
    }
  } catch (error) {
    dispatch(codexApiCatchError(error));
  }
};

export const updateUserSetting = (setting) => (dispatch, getState) => {
  if (checkObjectEqual(setting, getState().user.userSetting || {})) {
    return;
  }

  try {
    const response = CodexAPI().post("user/update-setting/", setting);
    const snackBar = {
      color: "success",
      icon: "done",
      title: "Successfully update setting",
      content: `Your setting has been updated.`,
    };
    dispatch(deploySnackbar(snackBar));
    if (setting.language) {
      changeLanguage(setting.language);
    }
    dispatch({
      type: UPDATE_USER_SETTING,
      payload: setting,
    });
  } catch (error) {
    dispatch(codexApiCatchError(error));
  }
};
