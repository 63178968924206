import { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { connect } from "react-redux";

import RoutesRender from "app/utils/RoutesRender";

import { normalRoutes, nonAuthRoutes } from "routes";

import FullPageLoading from "components/layouts/FullPageLoading";

import AuthApp from "AuthApp";

import { GA_TRACKING_ID } from "settings/ga";
import ConfirmerApp from "ConfirmerApp";

import gtag from "app/ga";

const AppRoute = ({ isSignIn, isConfirmerSignIn }) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryStr = searchParams.toString();
    if (queryStr) {
      gtag("config", GA_TRACKING_ID, {
        page_path: `${pathname}?${queryStr}`,
      });
    } else {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
      });
    }
  }, [pathname]);

  if (isSignIn === null || isConfirmerSignIn === null) {
    return (
      <Routes>
        <Route path="*" element={<FullPageLoading />} />
      </Routes>
    );
  }
  if (!isSignIn && !isConfirmerSignIn) {
    return <RoutesRender routes={[...normalRoutes, ...nonAuthRoutes]} />;
  } else {
    if (pathname.startsWith("/confirmer")) {
      if (isConfirmerSignIn) {
        return <ConfirmerApp />;
      } else {
        return <RoutesRender routes={[...normalRoutes, ...nonAuthRoutes]} />;
      }
    } else {
      if (isSignIn) {
        return <AuthApp />;
      } else {
        return <RoutesRender routes={[...normalRoutes, ...nonAuthRoutes]} />;
      }
    }
  }
};

const mapStateToProps = (state) => {
  return {
    isSignIn: state.app.isSignIn,
    isConfirmerSignIn: state.app.isConfirmerSignIn,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
