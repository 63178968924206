import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES_OPTIONS } from "settings/i18n";

import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";

import CdxSelect from "components/cdx/CdxSelect";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import changeLanguage from "app/utils/changeLanguage";
import { rootElement } from "settings";

export default function CdxChangeLanguage({ open, handleClose }) {
  const { t } = useTranslation("languages");
  const curLng = useTranslation().i18n.language;
  const [lng, setLng] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const [langOptions, setLangOptions] = useState(
    LANGUAGES_OPTIONS.map((e) => {
      return { value: e.value, label: t(e.label) };
    })
  );

  const [target, setTarget] = useState(rootElement);

  const modalElement = useRef(null);

  //   useEffect(() => {
  //     setTarget(modalElement.current);
  //   }, [modalElement]);

  useEffect(() => {
    setCurrentLanguage(
      LANGUAGES_OPTIONS.find((e) => e.value === curLng).label || "n/a"
    );
  }, [curLng]);

  useEffect(() => {
    const opt = LANGUAGES_OPTIONS.map((e) => {
      return { value: e.value, label: t(e.label) };
    });

    const timeOutId = setTimeout(() => {
      setLangOptions(opt);
    }, 500);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [open]);

  return (
    <>
      <Dialog
        ref={modalElement}
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={open}
        onClose={handleClose}
        sx={{ backdropFilter: "blur(5px)" }}
      >
        <SuiBox p={2}>
          <DialogTitle>{t("Change language")}</DialogTitle>
          <DialogContentText px={2}>
            {t("Current languages is")} {t(currentLanguage)}
          </DialogContentText>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <CdxSelect
                label={t("Change to")}
                value={lng}
                onChange={setLng}
                options={langOptions}
                menuPortalTarget={modalElement.current}
              />
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SuiButton
                  onClick={handleClose}
                  color="error"
                  variant="gradient"
                >
                  Close
                </SuiButton>
                <SuiButton
                  disabled={lng === curLng}
                  onClick={() => {
                    changeLanguage(lng);
                    handleClose();
                  }}
                  color="primary"
                  variant="gradient"
                >
                  Change
                </SuiButton>
              </Box>
            </Box>
          </DialogContent>
        </SuiBox>
      </Dialog>
    </>
  );
}
