import CdxCard from "components/cdx/CdxCard";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import { useTranslation } from "react-i18next";

import { Fragment, useEffect, useState } from "react";
import SuiButton from "components/sui/SuiButton";

import { connect } from "react-redux";
import { Divider } from "@mui/material";
import { Grid } from "@mui/material";
import CdxInput from "components/cdx/CdxInput";
import checkObjectEqual from "app/utils/checkObjectEqual";

import CodexAPI from "app/api/codexapi";

import { deploySnackbar, loadEngagementData } from "actions";

import { codexApiCatchError } from "actions/snackBar";

const BalanceMapping = ({
  engagementData,
  engagement_uuid,
  deploySnackbar,
  codexApiCatchError,
  loadEngagementData,
}) => {
  const [bM, setBM] = useState([]);
  const { t } = useTranslation();
  const [disabledSave, setDisabledSave] = useState();

  useEffect(() => {
    if (engagementData) {
      const balanceMapping =
        (engagementData.information || {}).key_mapping || {};
      setBM(
        Object.entries(balanceMapping).map(([k, v]) => {
          return { key: k, value: v, onEdit: false, editKey: k, editValue: v };
        })
      );
    } else {
      setBM([]);
    }
  }, [engagementData]);

  useEffect(() => {
    let isOk = true;
    const balanceMap = {};
    bM.forEach((e) => {
      if (e.onEdit) {
        isOk = false;
      }
      balanceMap[e.key] = e.value;
    });

    if (
      checkObjectEqual(
        balanceMap,
        (engagementData.information || {}).key_mapping || {}
      )
    ) {
      isOk = false;
    }

    setDisabledSave(!isOk);
  }, [bM, engagementData]);

  const addRow = () => {
    setBM([
      ...bM,
      { key: "", value: "", onEdit: true, editKey: "", editValue: "" },
    ]);
  };

  const saveMapping = async () => {
    try {
      const balanceMap = {};
      bM.forEach((e) => {
        balanceMap[e.key] = e.value;
      });

      const response = await CodexAPI().post("engagement/key_mapping/edit/", {
        engagement_uuid: engagement_uuid,
        key_mapping: balanceMap,
      });
      loadEngagementData(engagement_uuid);
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Successfully edit template",
        content: `Template has been edited`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  return (
    <CdxCard p={3}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiBox>
          <SuiTypography variant="h6">{t("Key mapping")}</SuiTypography>
        </SuiBox>
        <SuiBox>
          <Grid container spacing={1}>
            <Grid item>
              <SuiButton
                size="small"
                color="success"
                variant="gradient"
                onClick={saveMapping}
                disabled={disabledSave}
              >
                {t("Save mapping")}
              </SuiButton>
            </Grid>
            <Grid item>
              <SuiButton
                size="small"
                color="dark"
                variant="gradient"
                onClick={addRow}
                disabled={!(bM.at(-1) || {}).key && bM.length > 0}
              >
                {t("Add new mapping")}
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <SuiTypography variant="caption">
        {t("This is only for display in engagement view not the letter.")}
      </SuiTypography>
      <SuiBox>
        {bM.length > 0 && <Divider sx={{ my: 1, mt: 3 }} />}
        {bM.map((e, idx) => {
          if (e.onEdit) {
            return (
              <Fragment key={`input-${idx}`}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} sm={8} xl={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm>
                        <CdxInput
                          placeholder={t("Key")}
                          value={e.editKey}
                          onChange={(e) => {
                            setBM(
                              bM.map((e2, idx2) => {
                                return {
                                  ...e2,
                                  editKey:
                                    idx === idx2 ? e.target.value : e2.editKey,
                                };
                              })
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <CdxInput
                          placeholder={t("Value")}
                          value={e.editValue}
                          onChange={(e) => {
                            setBM(
                              bM.map((e2, idx2) => {
                                return {
                                  ...e2,
                                  editValue:
                                    idx === idx2
                                      ? e.target.value
                                      : e2.editValue,
                                };
                              })
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} xl={3}>
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        <SuiButton
                          size="small"
                          color="error"
                          variant="gradient"
                          onClick={() => {
                            setBM(
                              bM
                                .map((e2, idx2) => {
                                  return {
                                    ...e2,
                                    editKey: e2.key,
                                    editValue: e2.value,
                                    onEdit: idx === idx2 ? false : e2.onEdit,
                                  };
                                })
                                .filter(
                                  (e2) =>
                                    !(e2.key == null || e2.key === "") ||
                                    e2.onEdit
                                )
                            );
                          }}
                        >
                          {t("Cancel")}
                        </SuiButton>
                      </Grid>
                      <Grid item>
                        <SuiButton
                          size="small"
                          color="secondary"
                          variant="gradient"
                          disabled={
                            (e.key === e.editKey && e.value === e.editValue) ||
                            !e.editValue
                          }
                          onClick={() => {
                            setBM(
                              bM
                                .map((e2, idx2) => {
                                  return {
                                    ...e2,
                                    key: e2.editKey,
                                    value: e2.editValue,
                                    onEdit: idx === idx2 ? false : e2.onEdit,
                                  };
                                })
                                .filter(
                                  (e2) =>
                                    !(e2.key == null || e2.key === "") ||
                                    e2.onEdit
                                )
                            );
                          }}
                        >
                          {t("Confirm")}
                        </SuiButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
              </Fragment>
            );
          } else {
            return (
              <Fragment key={Math.random()}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} sm={8} xl={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm>
                        <SuiTypography variant="body2">{e.key}</SuiTypography>
                      </Grid>
                      <Grid item xs={12} sm>
                        <SuiTypography variant="body2">{e.value}</SuiTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} xl={3}>
                    <SuiBox
                      width="100%"
                      display="flex"
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                    >
                      <SuiButton
                        size="small"
                        color="warning"
                        variant="gradient"
                        onClick={() => {
                          setBM(
                            bM.map((e2, idx2) => {
                              return {
                                ...e2,
                                onEdit: idx === idx2 ? true : e2.onEdit,
                              };
                            })
                          );
                        }}
                      >
                        {t("Edit")}
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
              </Fragment>
            );
          }
        })}
      </SuiBox>
    </CdxCard>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
    engagement_uuid: state.app.activeEngagementUUID,
  };
};

const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
  loadEngagementData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceMapping);
