import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SuiTypography from "components/sui/SuiTypography";

import { useSoftUIController } from "context";

import breakpoints from "assets/theme/base/breakpoints";

import { BASE_PATH } from "settings";

import { useTranslation } from "react-i18next";

import { loadConfirmerLetter } from "actions";
import SuiBox from "components/sui/SuiBox";
import ConfirmerConfirmLetter from "./confirm";
import ConfirmerLetterView from "./letter";
import ConfirmerLetterResponse from "./response";

const ConfirmerLetter = ({ letters, loadConfirmerLetter }) => {
  const { t } = useTranslation();
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [letter_uuid, setUuid] = useState(searchParams.get("letter_uuid"));
  const { pathname } = useLocation();
  const [tabs, setTabs] = useState([]);
  const [letter, setLetter] = useState({});

  useEffect(() => {
    setUuid(searchParams.get("letter_uuid"));
  }, [searchParams]);

  const inboxTabs = [
    {
      name: "Letter",
      key: `/${BASE_PATH}/confirmer/inbox/letter`,
      route: `${BASE_PATH}/confirmer/inbox/letter`,
      element: <ConfirmerLetterView />,
    },
    {
      name: "Edit your response",
      key: `/${BASE_PATH}/confirmer/inbox/letter/response`,
      route: `${BASE_PATH}/confirmer/inbox/letter/response`,
      element: <ConfirmerLetterResponse />,
    },
  ];

  const historyTabs = [
    {
      name: "Letter",
      key: `/${BASE_PATH}/confirmer/history/letter`,
      route: `${BASE_PATH}/confirmer/history/letter`,
      element: <ConfirmerLetterView />,
    },
    {
      name: "Your response",
      key: `/${BASE_PATH}/confirmer/history/letter/response`,
      route: `${BASE_PATH}/confirmer/history/letter/response`,
      element: <ConfirmerLetterResponse />,
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const delaySetTabValue = (value, delay = 400) => {
    const dSetTabValue = () => {
      setTabValue(value === 0 ? 1 : value - 1);
      setTabValue(value);
    };

    const timeOutId = setTimeout(dSetTabValue, delay);
    return timeOutId;
  };

  useEffect(() => {
    if (pathname.startsWith("/confirmer/inbox")) {
      setTabs(inboxTabs);
    } else if (pathname.startsWith("/confirmer/history")) {
      setTabs(historyTabs);
    }
  }, [pathname]);

  useEffect(() => {
    const tempTab = [...tabs].reverse();
    const idx =
      tempTab.length -
      1 -
      tempTab.findIndex((t) => {
        return pathname.toLowerCase().startsWith(t.route.toLowerCase());
      });

    let timeOutId = undefined;
    if (idx >= 0) {
      setTabValue(idx);
      timeOutId = delaySetTabValue(idx, 500);
    }

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [pathname, tabs]);

  useEffect(() => {
    const currentTabValue = tabValue;
    const timeOutId = delaySetTabValue(currentTabValue);
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [miniSidenav]);

  useEffect(() => {
    if (letter_uuid) {
      loadConfirmerLetter(letter_uuid);
    }
  }, [letter_uuid]);

  useEffect(() => {
    const l = letters[letter_uuid.replace(/-/gi, "")];
    if (l) {
      let is_exp = false;
      if (l.expire_at) {
        let exp_at = l.expire_at;
        if (!l.expire_at.endsWith("Z")) {
          exp_at = l.expire_at + "Z";
        }
        exp_at = new Date(exp_at);
        is_exp = exp_at.getTime() < Date.now();
      }

      if (l.confirmer_submit || is_exp) {
        if (pathname.search("/inbox")) {
          const link = pathname
            .replace("/submit", "")
            .replace("/inbox/", "/history/");
          navigate(`${link}?letter_uuid=${letter_uuid}`);
        }
      } else {
        if (pathname.search("/history")) {
          const link = pathname.replace("/history/", "/inbox/");
          navigate(`${link}?letter_uuid=${letter_uuid}`);
        }
      }
      setLetter({ ...l });
    } else {
      setLetter({});
    }
  }, [letters, letter_uuid]);

  const renderTab = (e) => {
    return (
      <Tab
        key={e.key}
        label={t(e.name)}
        onClick={() => {
          navigate(`${e.route}?letter_uuid=${letter_uuid}`);
        }}
      />
    );
  };

  const renderPage = () => {
    return (tabs.at(tabValue) || {}).element;
  };

  return (
    <>
      {letter_uuid ? (
        <>
          {Object.keys(letter).length > 0 && (
            <>
              {letter.is_invalid_uuid ? (
                <SuiBox mt={2}>
                  <SuiTypography caption="h5">
                    {t("Invalid letter uuid")}
                  </SuiTypography>
                </SuiBox>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <AppBar position="static">
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                      >
                        {tabs.map((e) => renderTab(e))}
                      </Tabs>
                    </AppBar>
                  </Grid>
                  <Grid item xs>
                    {renderPage()}
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      ) : (
        <SuiTypography caption="h5">{t("No letter is selected")}</SuiTypography>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { letters: state.confirmer.letters };
};

const mapDispatchToProps = {
  loadConfirmerLetter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmerLetter);
