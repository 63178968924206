/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";

function Sidenav({ activeTab, setActiveTab }) {
  const { t } = useTranslation();
  const sidenavItems = [
    {
      icon: <Icon fontSize="lg">face</Icon>,
      label: t("Profile"),
      key: "profile",
    },
    {
      icon: <Icon fontSize="lg">settings</Icon>,
      label: t("Setting"),
      key: "setting",
    },
    {
      icon: <Icon fontSize="lg">lock_open</Icon>,
      label: t("Change Password"),
      key: "changePassword",
    },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, key }, idx) => {
    const itemKey = `item-${idx}`;
    const active = key === activeTab ? true : false;
    return (
      <SuiBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <SuiTypography
          onClick={() => {
            setActiveTab(key);
          }}
          variant="button"
          fontWeight={active ? "bold" : "regular"}
          color={active ? "info" : "text"}
          textGradient={active ? true : false}
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <SuiBox mr={1.5} lineHeight={1}>
            {icon}
          </SuiBox>
          {label}
        </SuiTypography>
      </SuiBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <SuiBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </SuiBox>
    </Card>
  );
}

export default Sidenav;
