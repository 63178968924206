import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxPasswordInput from "components/cdx/CdxPasswordInput";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { auth } from "app/fb/config";
import { updatePassword } from "firebase/auth";

import { deploySnackbar } from "actions";

import signIn from "app/fb/signIn";

import reloadToken from "app/fb/reloadToken";

const ChangePasswordForm = ({ setShowForm, deploySnackbar }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState({});

  const validateForm = (values) => {
    const error = {};

    if (!values.current_password) {
      error.current_password = "Please confirm your current password";
    }

    if (values.password) {
      if (values.password.length < 8) {
        error.password = "At least 8 character";
      } else if (values.password.search(/[A-Z]/) < 0) {
        error.password =
          "Your password must be contained at least one upper case letter";
      } else if (values.password.search(/[a-z]/) < 0) {
        error.password =
          "Your password must be contained at least one lower case letter";
      } else if (values.password.search(/\d/) < 0) {
        error.password = "Your password must be contained at least one number";
      } else if (values.password.search(/[!@#$%^&*()+-/.]/) < 0) {
        error.password =
          "Your password must be contained at least one special character";
      }
    } else {
      error.password = "Please enter a password";
    }
    if (values.re_password) {
      if (values.re_password !== values.password) {
        error.re_password = "Password do not match";
      }
    } else {
      error.re_password = "Please confirm a password";
    }

    return error;
  };

  const onSubmit = async (values) => {
    setMessage({ color: "dark", text: "Changing your password..." });
    const email = auth.currentUser.email;
    try {
      const result = await signIn(email, values.current_password);
      if (result.type === "error") {
        if (result.data.code === "auth/wrong-password") {
          setMessage({});
          return { current_password: "Incorrect current password" };
        } else if (result.data.code === "auth/too-many-requests") {
          setMessage({});
          return { [FORM_ERROR]: "Too many request. Please try again later." };
        }
      } else if (result.type === "success") {
        const user = auth.currentUser;
        await updatePassword(user, values.password);
        const snackBar = {
          color: "success",
          icon: "done",
          title: "Successfully change password",
          content: `${t("Your password has been changed.")}`,
        };
        deploySnackbar(snackBar);
        setShowForm(false);
        setTimeout(async () => {
          reloadToken();
        }, 500);
        return {};
      }
    } catch (error) {
      setMessage({});
      return { [FORM_ERROR]: "Unknown error. Please try again later." };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <Grid
            container
            columnSpacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={6} lg={5} xxl={4}>
              <Field name="current_password" type="password">
                {({ input, meta }) => (
                  <CdxPasswordInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Current password*")}
                    error={!!(meta.error && meta.touched) || !!meta.submitError}
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} lg={5} xxl={4}>
              <Field name="password" type="password">
                {({ input, meta }) => (
                  <CdxPasswordInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Password*")}
                    error={!!(meta.error && meta.touched) || !!meta.submitError}
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6} lg={5} xxl={4}>
              <Field name="re_password" type="password">
                {({ input, meta }) => (
                  <CdxPasswordInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    label={t("Confirm password*")}
                    error={!!(meta.error && meta.touched) || !!meta.submitError}
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            {submitError && (
              <Grid item xs={12} mt={0.5}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
            {message.text && (
              <Grid item xs={12} mt={0.5}>
                <SuiTypography
                  color={message.color || "info"}
                  variant="caption"
                >
                  {t(message.text)}
                </SuiTypography>
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SuiButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={
                  submitting || Object.values(formState.errors).length > 0
                }
              >
                {t("Change password")}
              </SuiButton>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = { deploySnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
