import store from "store";

import { auth } from "./config";

import { updateProfile } from "firebase/auth";

import { deploySnackbar } from "actions";

import reloadToken from "app/fb/reloadToken";

import { authServerError } from "actions/snackBar";

const updateUserProfile = async (data) => {
  const dispatch = store.dispatch;
  const user = auth.currentUser;
  console.log(user);
  try {
    await updateProfile(user, data)
      .then(() => {
        const snackBar = {
          color: "success",
          icon: "done",
          title: "Successfully change your profile",
          content: `Your profile has been changed.`,
        };
        dispatch(deploySnackbar(snackBar));
        reloadToken(user);
      })
      .catch((error) => {
        if (error.code === "auth/network-request-failed") {
          dispatch(authServerError());
          return;
        }
        const snackBar = {
          color: "error",
          icon: "report_problem",
          title: "Could not update your profile",
          content: `Please try again.`,
        };
        dispatch(deploySnackbar(snackBar));
      });
  } catch {
    dispatch(authServerError());
  }
};

export default updateUserProfile;
