import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import EngagementRedirect from "pages/engagement/redirect";

import { connect } from "react-redux";
import LetterSummary from "./Letter";
import RequestSummary from "./Request";
import ResponseSummary from "./Response";

const EngagementHome = ({ engagementData }) => {
  return (
    <EngagementRedirect>
      {engagementData && (
        <>
          <SuiBox mt={2}>
            <SuiTypography variant="h5">
              {engagementData.mutual_engagement_name}
            </SuiTypography>
          </SuiBox>
          <LetterSummary letters={engagementData.letters} />
          {!engagementData.archive_at && (
            <RequestSummary
              requests={engagementData.requests}
              billing={
                engagementData.billingUUID === engagementData.auditorUUID
                  ? "auditor"
                  : "client"
              }
            />
          )}
          <ResponseSummary
            letters={engagementData.letters}
            mapping={(engagementData.information || {}).key_mapping || {}}
          />
        </>
      )}
    </EngagementRedirect>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementHome);
