import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Divider, Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";

import VerifiedBusinessIcon from "./VerifiedBusinessIcon";
import BusinessViewFunctionBar from "./BusinessViewFunctionBar";
import BusinessViewSearch from "./BusinessViewSearch";
import NotStaffFunctionBar from "./NotStaffFunctionBar";

import { viewBusiness, viewBusinessByLetter } from "actions";

import BusinessCard from "./BusinessCard";
import getNames from "app/utils/getNames";

const ViewBusiness = ({
  email,
  viewBusiness,
  viewBusinessByLetter,
  businessesData,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isConfirmerView, setIsConfirmerView] = useState(
    pathname.startsWith("/confirmer")
  );

  useEffect(() => {
    setIsConfirmerView(pathname.startsWith("/confirmer"));
  }, [pathname]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [uuid, setUuid] = useState(
    (searchParams.get("uuid") || "").replace(/-/gi, "")
  );
  const [letterUuid, setLetterUuid] = useState(
    (searchParams.get("letter_uuid") || "").replace(/-/gi, "")
  );
  const [rawUuid, setRawUuid] = useState(searchParams.get("uuid") || "");

  const [entityNotFound, setEntityNotFound] = useState(false);
  const [businessData, setBusinessData] = useState({});
  const [isBusinessVerified, setIsBusinessVerified] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isNotStaff, setIsNotStaff] = useState(false);

  const [businessList, setBusinessList] = useState(null);

  const publicInformation = {
    entity_id: "Entity id",
    nationality: "Nationality",
  };

  const privateInformation = {
    website: "Website",
    address: "Address",
    description: "Description",
  };

  useEffect(() => {
    setUuid((searchParams.get("uuid") || "").replace(/-/gi, ""));
    setRawUuid(searchParams.get("uuid") || "");
    setLetterUuid((searchParams.get("letter_uuid") || "").replace(/-/gi, ""));
  }, [searchParams]);

  useEffect(() => {
    if (uuid && letterUuid && isConfirmerView) {
      viewBusinessByLetter(uuid, letterUuid);
    } else if (uuid) {
      viewBusiness(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (Object.keys(businessesData).length > 0) {
      const business = businessesData[uuid];
      if (business) {
        setBusinessData(business);
      } else {
        setBusinessData({});
      }
    }
  }, [businessesData, uuid]);

  useEffect(() => {
    if (Object.values(businessData).length > 0) {
      if (businessData.is_invalid_uuid) {
        setEntityNotFound(true);
      } else {
        setIsBusinessVerified(businessData.is_verified);

        if (businessData.admin && businessData.is_staff) {
          setIsAdmin(
            businessData.admin.filter((e) => e.email === email).length > 0
          );
        } else {
          setIsAdmin(false);
          setIsNotStaff(true);
        }
      }
    } else {
      setIsAdmin(false);
      setIsNotStaff(false);
    }
  }, [businessData, uuid]);

  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} lg={6} mb={{ xs: 2, md: 0 }}>
              {uuid ? (
                <SuiTypography variant="h5">
                  {getNames(
                    (businessData.information || {}).names || [
                      {
                        lang: "en",
                        name: t("View business"),
                      },
                    ]
                  )}{" "}
                  {isBusinessVerified && <VerifiedBusinessIcon />}
                </SuiTypography>
              ) : (
                <SuiTypography variant="h5">{t("Search")}</SuiTypography>
              )}
            </Grid>
            {!isConfirmerView && (
              <>
                {isAdmin && <BusinessViewFunctionBar uuid={uuid} />}
                {isNotStaff && (
                  <NotStaffFunctionBar uuid={uuid} rawUuid={rawUuid} />
                )}
              </>
            )}
          </Grid>
          {uuid ? (
            <>
              {entityNotFound && (
                <SuiBox mt={2}>
                  <SuiTypography variant="body2" color="error">
                    {isConfirmerView
                      ? t(
                          "Invalid business uuid or letter uuid. Please recheck your business uuid and letter uuid."
                        )
                      : t(
                          "Invalid business uuid. Please recheck your business uuid."
                        )}
                  </SuiTypography>
                </SuiBox>
              )}
              {Object.values(businessData).length > 0 &&
                !businessData.is_invalid_uuid && (
                  <SuiBox mt={3}>
                    {Object.keys(publicInformation).map((k) => {
                      return (
                        <SuiBox key={Math.random()} display="flex">
                          <SuiTypography variant="body2" fontWeight="bold">
                            {t(publicInformation[k])}
                          </SuiTypography>
                          <SuiTypography variant="body2">
                            {": "}
                            {t(businessData.information[k] || "Not provided")}
                          </SuiTypography>
                        </SuiBox>
                      );
                    })}
                  </SuiBox>
                )}
              <Divider />
              {Object.values(businessData).length > 0 &&
                !businessData.is_invalid_uuid && (
                  <SuiBox mt={3}>
                    {businessData.private_information == null ? (
                      <SuiTypography variant="body2">
                        {t("Other entity information is restricted")}
                      </SuiTypography>
                    ) : (
                      <>
                        {Object.keys(privateInformation).map((k) => {
                          return (
                            <SuiBox key={Math.random()} display="flex">
                              <SuiTypography variant="body2" fontWeight="bold">
                                {t(privateInformation[k])}
                              </SuiTypography>
                              <SuiTypography variant="body2">
                                {": "}
                                {t(
                                  businessData.private_information[k] ||
                                    "Not provided"
                                )}
                              </SuiTypography>
                            </SuiBox>
                          );
                        })}
                      </>
                    )}
                  </SuiBox>
                )}
            </>
          ) : (
            <SuiBox>
              <BusinessViewSearch setBusinessList={setBusinessList} />
            </SuiBox>
          )}
        </SuiBox>
      </CdxCard>
      {!uuid && (
        <>
          {businessList === null ? (
            <></>
          ) : (
            <>
              {businessList.length === 0 ? (
                <SuiBox mt={3} display="flex" justifyContent="center">
                  <SuiTypography variant="body2">
                    {t("No business found")}
                  </SuiTypography>
                </SuiBox>
              ) : (
                <>
                  <Grid container spacing={2} mt={2}>
                    {businessList.map((e) => (
                      <Grid key={e.uuid} item xs={12} md={6} lg={4} xxl={3}>
                        <BusinessCard business={e} key={e.uuid} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    businessesData: state.business.businessData,
  };
};

const mapDispatchToProps = { viewBusiness, viewBusinessByLetter };

export default connect(mapStateToProps, mapDispatchToProps)(ViewBusiness);
