import CodexAPI from "app/api/codexapi";
import { useState, useEffect, Fragment } from "react";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import CdxCard from "components/cdx/CdxCard";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import getNames from "app/utils/getNames";
import { Divider } from "@mui/material";

const Home = ({ email, names }) => {
  const { t } = useTranslation();

  const [homeInfo, setHomeInfo] = useState(null);

  const loadMyHomeSummary = async () => {
    const response = await CodexAPI().get("user/my-home-summary");
    setHomeInfo(response.data.data);
    // console.log(response.data.data);
  };
  useEffect(() => {
    loadMyHomeSummary();
  }, [window.location.href]);
  return (
    <SuiBox mt={3}>
      <CdxCard p={3}>
        <SuiTypography variant="h5">
          {t("Welcome")} {names && getNames(names)} {`(${email})`}
        </SuiTypography>

        <SuiBox pt={3}>
          {homeInfo && (
            <Fragment>
              <SuiTypography variant="body2">
                {t("You receive ")} {homeInfo.letterResponse.lastDay}{" "}
                {homeInfo.letterResponse.lastDay > 1
                  ? t("responses")
                  : t("response")}{" "}
                {t("in last 24 hours.")}
              </SuiTypography>
              <SuiTypography variant="body2">
                {t("You receive ")} {homeInfo.letterResponse.lastWeek}{" "}
                {homeInfo.letterResponse.lastWeek > 1
                  ? t("responses")
                  : t("response")}{" "}
                {t("in last 7 days.")}
              </SuiTypography>
              <SuiTypography variant="body2">
                {t("You receive ")} {homeInfo.letterResponse.lastMonth}{" "}
                {homeInfo.letterResponse.lastMonth > 1
                  ? t("responses")
                  : t("response")}{" "}
                {t("in last 30 days.")}
              </SuiTypography>
              <Divider />
              <SuiTypography variant="body2">
                {t("You have ")} {homeInfo.engagement.auditor}{" "}
                {t("on-going auditor")}{" "}
                {homeInfo.engagement.auditor > 1
                  ? t("engagements")
                  : t("engagement")}{" "}
                {t("and")} {homeInfo.engagement.client} {t("on-going client")}{" "}
                {homeInfo.engagement.client > 1
                  ? t("engagements")
                  : t("engagement")}
                {"."}
              </SuiTypography>
            </Fragment>
          )}
        </SuiBox>
      </CdxCard>
    </SuiBox>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    names: state.user.userData.names,
  };
};

export default connect(mapStateToProps)(Home);
