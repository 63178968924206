import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxInput from "components/cdx/CdxInput";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import signInWithEmailLink from "app/fb/signInWithEmailLink";

import { LSK_EMAIL_FOR_SIGN_UP } from "settings/localStorageKeys";

const CompleteSignUpForm = () => {
  const { t } = useTranslation();

  const validateForm = (values) => {
    const error = {};

    if (!values.email) {
      error.email = "Please enter email";
    } else {
      const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      };
      if (!validateEmail(values.email)) {
        error.email = "Please enter a valid email";
      }
    }

    return error;
  };

  const onSubmit = async (values) => {
    const error = {};
    const result = await signInWithEmailLink(values.email, values.password);
    if (result.type === "success") {
    } else if (result.type === "error") {
      if (result.data.code === "auth/invalid-email") {
        error.email = "Invalid email";
      } else if (result.data.code === "auth/invalid-action-code") {
        error[FORM_ERROR] =
          "Invalid sign up code, please make another sign up request.";
      } else {
        error[FORM_ERROR] = "Unable to sign up, please try again";
      }
    }
    return error;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <SuiBox
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Field
              name="email"
              initialValue={window.localStorage.getItem(LSK_EMAIL_FOR_SIGN_UP)}
            >
              {({ input, meta }) => (
                <CdxInput
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  mb={2}
                  label={t("Email")}
                  error={
                    !!(meta.error && meta.touched) ||
                    !!meta.submitError ||
                    !!submitError
                  }
                  message={
                    ((meta.error && meta.touched) || meta.submitError) &&
                    (t(meta.error) || t(meta.submitError))
                  }
                />
              )}
            </Field>
            {submitError && (
              <SuiBox>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </SuiBox>
            )}
            <SuiBox mt={3}>
              <SuiButton
                variant="gradient"
                color="info"
                type="submit"
                fullWidth
                disabled={
                  submitting || Object.values(formState.errors).length > 0
                }
              >
                {t("Confirm your email")}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        );
      }}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSignUpForm);
