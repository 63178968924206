import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid, Icon } from "@mui/material";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";
import CdxButton from "components/cdx/CdxButton";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";

import { BUSINESS_MEMBERSHIP_OPTIONS } from "settings/confirmation";
import CodexAPI from "app/api/codexapi";

import { businessMember } from "actions";
import { deploySnackbar } from "actions";
import { rootElement } from "settings";
import GotoButton from "../GotoButton";

const LetterTrackFunctionBar = ({
  setResponseRows,
  setNoresponseRows,
  setExpireResponseRows,
  engagementData,
  deploySnackbar,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [rawResponseRows, setRawResponseRows] = useState([]);
  const [rawNoresponseRows, setRawNoresponseRows] = useState([]);
  const [rawExpireResponseRows, setRawExpireResponseRows] = useState([]);

  useEffect(() => {
    const response = engagementData.letters
      .filter((e) => e.confirmer_submit)
      .map((e) => {
        return {
          ...e,
          action: <GotoButton letterUUID={e.letterUUID} />,
        };
      });
    setRawResponseRows(response);
    const now = Date.now();
    setRawNoresponseRows(
      engagementData.letters
        .filter((e) => !e.confirmer_submit && new Date(e.expire_at) > now)
        .map((e) => {
          return { ...e, action: <GotoButton letterUUID={e.letterUUID} /> };
        })
    );
    setRawExpireResponseRows(
      engagementData.letters
        .filter(
          (e) =>
            !e.confirmer_submit && new Date(e.expire_at) <= now && !!e.expire_at
        )
        .map((e) => {
          return { ...e, action: <GotoButton letterUUID={e.letterUUID} /> };
        })
    );
  }, [engagementData]);

  useEffect(() => {
    if (search) {
      const searchLower = search.toLowerCase();
      const arr1 = [...rawResponseRows].filter((e) => {
        return (
          e.confirm_entity_code.toLowerCase().includes(searchLower) ||
          e.confirm_entity_name.toLowerCase().includes(searchLower)
        );
      });
      const arr3 = [...rawResponseRows].filter((e) => {
        let find = false;
        e.recipients.forEach((r) => {
          if (r.name.toLowerCase().includes(searchLower)) {
            find = true;
          } else if (r.email.toLowerCase().includes(searchLower)) {
            find = true;
          }
        });
        return find;
      });
      const combineArr = [...new Set([...arr1, ...arr3])];
      setResponseRows(combineArr);
    } else {
      setResponseRows(rawResponseRows);
    }
  }, [rawResponseRows, search]);

  useEffect(() => {
    if (search) {
      console.log(rawNoresponseRows);
      const searchLower = search.toLowerCase();
      const arr1 = [...rawNoresponseRows].filter((e) => {
        return (
          e.confirm_entity_code.toLowerCase().includes(searchLower) ||
          e.confirm_entity_name.toLowerCase().includes(searchLower)
        );
      });
      const arr3 = [...rawNoresponseRows].filter((e) => {
        let find = false;
        e.recipients.forEach((r) => {
          if (r.name.toLowerCase().includes(searchLower)) {
            find = true;
          } else if (r.email.toLowerCase().includes(searchLower)) {
            find = true;
          }
        });
        return find;
      });
      const combineArr = [...new Set([...arr1, ...arr3])];
      setNoresponseRows(combineArr);
    } else {
      setNoresponseRows(rawNoresponseRows);
    }
  }, [rawNoresponseRows, search]);

  useEffect(() => {
    if (search) {
      console.log(rawExpireResponseRows);
      const searchLower = search.toLowerCase();
      const arr1 = [...rawExpireResponseRows].filter((e) => {
        return (
          e.confirm_entity_code.toLowerCase().includes(searchLower) ||
          e.confirm_entity_name.toLowerCase().includes(searchLower)
        );
      });
      const arr3 = [...rawExpireResponseRows].filter((e) => {
        let find = false;
        e.recipients.forEach((r) => {
          if (r.name.toLowerCase().includes(searchLower)) {
            find = true;
          } else if (r.email.toLowerCase().includes(searchLower)) {
            find = true;
          }
        });
        return find;
      });
      const combineArr = [...new Set([...arr1, ...arr3])];
      setExpireResponseRows(combineArr);
    } else {
      setExpireResponseRows(rawExpireResponseRows);
    }
  }, [rawExpireResponseRows, search]);

  return (
    <>
      <Grid mt={1} container spacing={2} direction="row">
        <Grid item xs>
          <CdxInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            icon={{
              component: "search",
              direction: "left",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
  };
};

const mapDispatchToProps = {
  deploySnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LetterTrackFunctionBar);
