import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import PasswordForm from "./form";
import PasswordPolicy from "pages/complete-reset-password/passwordPolicy";

function SetUpPassword({ form }) {
  return (
    <SuiBox>
      <SuiTypography variant="h5">Set up password</SuiTypography>
      <PasswordForm form={form} />
      <PasswordPolicy />
    </SuiBox>
  );
}

export default SetUpPassword;
