import { Grid } from "@mui/material";
import { useState, useEffect } from "react";

import CdxDropzone from "components/cdx/CdxDropzone";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import { useTranslation } from "react-i18next";
import CdxSwitch from "components/cdx/CdxSwitch";
import SuiButton from "components/sui/SuiButton";
import CdxBadgeDot from "components/cdx/CdxBadgeDot";

import * as XLSX from "xlsx/xlsx.mjs";

const ConfirmerEditResponse = ({
  letter,
  agree,
  setAgree,
  editResponse,
  setEditResponse,
  onDraftResponse,
}) => {
  const { t } = useTranslation();
  const [localEditResponse, setLocalEditResponse] = useState(
    agree ? {} : editResponse
  );
  const [file, setFile] = useState();
  const [workbook, setWorkbook] = useState();
  const [invalidFile, setInvalidFile] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (agree) {
      setEditResponse({});
    } else {
      setEditResponse(localEditResponse);
    }
  }, [agree, localEditResponse]);

  useEffect(async () => {
    if (file) {
      setIsUpload(true);
      try {
        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        setWorkbook(wb);
      } catch {
        setInvalidFile(["Invalid file type"]);
      }
    } else {
      setIsUpload(false);
    }
  }, [file]);

  useEffect(() => {
    if (workbook) {
      setIsProcessing(true);
      const s1 = Object.keys(letter.confirm_information.balance).map(
        (e) => `balance_${e}`
      );
      const s2 = Object.keys(letter.confirm_information.transaction).map(
        (e) => `transaction_${e}`
      );

      const required_sheet = [...s1, ...s2];
      let errorList = [];
      required_sheet.forEach((e) => {
        if (!workbook.SheetNames.includes(e)) {
          errorList = [
            ...errorList,
            "Missing some sheet name. Please use the response xlsx template.",
          ];
        }
      });

      if (errorList.length > 0) {
        setInvalidFile([...new Set(errorList)]);
        setIsProcessing(false);
        return;
      }

      let worksheet = null;
      const resp = {
        agree: false,
        balance: {},
        balance_details: {},
        transaction: {},
        transaction_details: {},
      };
      workbook.SheetNames.forEach((sheetName) => {
        if (sheetName.startsWith("balance_")) {
          worksheet = workbook.Sheets[sheetName];
          let b = 0;
          const balance_detail = XLSX.utils
            .sheet_to_json(worksheet)
            .map((row) => {
              b += row.confirm_amount;
              return {
                doc_no: row.doc_no,
                doc_date: row.doc_date,
                cur: row.confirmer_cur,
                confirm_amount: row.confirm_amount,
                response_remark: row.response_remark,
              };
            });
          resp["balance_details"][sheetName.replace("balance_", "")] =
            balance_detail;
          resp["balance"][sheetName.replace("balance_", "")] = b;
        }
        if (sheetName.startsWith("transaction_")) {
          worksheet = workbook.Sheets[sheetName];
          let b = 0;
          const transaction_detail = XLSX.utils
            .sheet_to_json(worksheet)
            .map((row) => {
              b += row.confirm_amount;
              return {
                doc_no: row.doc_no,
                doc_date: row.doc_date,
                cur: row.confirmer_cur,
                confirm_amount: row.confirm_amount,
                response_remark: row.response_remark,
              };
            });
          resp["transaction_details"][sheetName.replace("transaction_", "")] =
            transaction_detail;
          resp["transaction"][sheetName.replace("transaction_", "")] = b;
        }
      });

      Object.entries(letter.confirm_information.balance_details).forEach(
        ([k, v]) => {
          v.forEach((e) => {
            if (
              resp.balance_details[k].findIndex((r) => r.doc_no === e.doc_no) <
              0
            ) {
              errorList = [
                ...errorList,
                "Missing some document. Please use the response xlsx template.",
              ];
            }
          });
        }
      );

      Object.entries(letter.confirm_information.transaction_details).forEach(
        ([k, v]) => {
          v.forEach((e) => {
            if (
              resp.transaction_details[k].findIndex(
                (r) => r.doc_no === e.doc_no
              ) < 0
            ) {
              errorList = [
                ...errorList,
                "Missing some document. Please use the response xlsx template.",
              ];
            }
          });
        }
      );

      if (errorList.length > 0) {
        setInvalidFile([...new Set(errorList)]);
        setIsProcessing(false);
        return;
      }

      setIsProcessing(false);
      setLocalEditResponse(resp);
    }
  }, [workbook]);

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs>
        <SuiBox lineHeight={0}>
          <SuiTypography variant="caption" fontWeight="bold">
            {t("I confirm that the information provided in the letter is")}
          </SuiTypography>
        </SuiBox>

        <CdxSwitch
          label={[t("correct"), t("incorrect")]}
          value={agree}
          onChange={() => {
            setAgree(!agree);
          }}
          spaceLabelAtTop={false}
        />
        {!agree && (
          <SuiBox lineHeight={0}>
            <SuiTypography variant="caption" color="error">
              {t(
                "In case the information is not correct, please provide a excel response (which you can download from the letter tab) for the correct amount."
              )}
            </SuiTypography>
          </SuiBox>
        )}
      </Grid>
      {!agree && (
        <Grid item xs={12} sm={6}>
          {!isUpload ? (
            <>
              <SuiBox lineHeight={0} pb={2}>
                <SuiTypography
                  variant="caption"
                  fontWeight="bold"
                  lineHeight={0}
                >
                  {t("Upload response file (.xlsx)")}
                </SuiTypography>
              </SuiBox>

              <CdxDropzone setFile={setFile} />
            </>
          ) : (
            <>
              {isProcessing ? (
                <SuiBox lineHeight={0}>
                  <SuiTypography variant="caption" fontWeight="bold">
                    {t("Processing file...")}
                  </SuiTypography>
                </SuiBox>
              ) : (
                <>
                  {invalidFile.length > 0 ? (
                    <>
                      <SuiBox lineHeight={0}>
                        <SuiTypography variant="caption" fontWeight="bold">
                          {t(
                            "Your file is invalid, please make another upload."
                          )}
                        </SuiTypography>
                        {invalidFile.map((e) => {
                          return (
                            <CdxBadgeDot
                              key={`error-upload-${e}`}
                              badgeContent={t(e)}
                              size="sm"
                              color="error"
                              font={{ color: "error" }}
                            />
                          );
                        })}
                      </SuiBox>
                    </>
                  ) : (
                    <SuiBox lineHeight={0}>
                      <SuiTypography variant="caption" fontWeight="bold">
                        {t(
                          "You've uploaded response file. If you would like to make change please make another upload."
                        )}
                      </SuiTypography>
                    </SuiBox>
                  )}
                  <SuiBox mt={2}>
                    <SuiButton
                      variant="gradient"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setIsUpload(false);
                        setFile();
                        setLocalEditResponse({});
                      }}
                    >
                      {t("Upload response file again")}
                    </SuiButton>
                  </SuiBox>
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmerEditResponse;
