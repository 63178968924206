import { useState } from "react";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";

import Sidenav from "./Sidenav";
import MyProfile from "./MyProfile";
import Setting from "./Setting";
import ChangePassword from "./ChangePassword";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("profile");

  return (
    <>
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3} >
            <Sidenav activeTab={activeTab} setActiveTab={setActiveTab} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                {activeTab === "profile" && (
                  <Grid item xs={12}>
                    <MyProfile />
                  </Grid>
                )}
                {activeTab === "setting" && (
                  <Grid item xs={12}>
                    <Setting />
                  </Grid>
                )}
                {activeTab === "changePassword" && (
                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                )}
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

export default Profile;
