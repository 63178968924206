const checkTemplateCompleteness = (template, values) => {
  let rV = template.replaceAll("\\$", "");
  let hasAllBalanceAndTransaction = true;
  Object.entries(values).forEach(([k, v]) => {
    if (k.startsWith("balance") || k.startsWith("transaction")) {
      hasAllBalanceAndTransaction =
        hasAllBalanceAndTransaction && rV.indexOf(`\$${k}`) >= 0;
    }
    rV = rV.replaceAll(`\$${k}`, v);
  });
  if (rV.indexOf("$") > 0 || !hasAllBalanceAndTransaction) {
    return false;
  } else {
    return true;
  }
};

export default checkTemplateCompleteness;
