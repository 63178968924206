import {
  VIEW_BUSINESS,
  LIST_MY_BUSINESS,
  CLEAR_VIEW_BUSINESS,
  BUSINESS_MEMBER,
  CLEAR_BUSINESS_MEMBER,
  SIGN_OUT,
  CONF_SIGN_OUT,
} from "actions/types";

const businessDefault = {
  myBusiness: [],
  businessData: {},
  businessMember: {},
};

const businessReducer = (business = businessDefault, action) => {
  switch (action.type) {
    case LIST_MY_BUSINESS:
      return { ...business, myBusiness: action.payload };
    case VIEW_BUSINESS:
      return {
        ...business,
        businessData: { ...business.businessData, ...action.payload },
      };
    case BUSINESS_MEMBER:
      return {
        ...business,
        businessMember: { ...business.businessMember, ...action.payload },
      };
    case CLEAR_VIEW_BUSINESS:
      return {
        ...business,
        businessData: action.payload.businessData,
      };
    case CLEAR_BUSINESS_MEMBER:
      return {
        ...business,
        businessMember: action.payload.businessMember,
      };
    case SIGN_OUT:
      return {
        ...businessDefault,
      };
    case CONF_SIGN_OUT:
      return {
        ...businessDefault,
      };
    default:
      return business;
  }
};

export default businessReducer;
