import CdxCard from "components/cdx/CdxCard";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";
import { Icon } from "@mui/material";

import { connect } from "react-redux";

import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CdxButton from "components/cdx/CdxButton";

import CdxDataTable from "components/cdx/CdxDataTable";

const EngagementLetterResponse = ({ letters, email }) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useState("");
  const [value, setValue] = useState({});
  const [letter, setLetter] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [letter_uuid, setUuid] = useState(searchParams.get("letter_uuid"));
  const [confirmerResponse, setConfirmerResponse] = useState({});

  const [agree, setAgree] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setUuid(searchParams.get("letter_uuid"));
  }, [searchParams]);

  useEffect(() => {
    const temp = letters[letter_uuid.replace(/-/gi, "")];
    if (temp) {
      setLetter(temp);
    } else {
      setLetter({});
    }
  }, [letters, letter_uuid]);

  useEffect(() => {
    if (letter) {
      setConfirmerResponse(letter.confirmer_response || {});
    }
  }, [letter]);

  useEffect(() => {
    let new_rows = [];
    if (Object.keys(letter).length > 0) {
      if ((letter.confirmer_response || {}).agree) {
        Object.entries(letter.confirm_information.balance).forEach(([k, v]) => {
          new_rows = [
            ...new_rows,
            {
              type: t("Balance"),
              key: k,
              amount: v,
              confirm_amount: v,
              diff: 0,
              has_diff: false,
            },
          ];
        });
        Object.entries(letter.confirm_information.transaction).forEach(
          ([k, v]) => {
            new_rows = [
              ...new_rows,
              {
                type: t("Transaction"),
                key: k,
                amount: v,
                confirm_amount: v,
                diff: 0,
                has_diff: false,
              },
            ];
          }
        );
      } else {
        Object.entries(letter.confirm_information.balance).forEach(([k, v]) => {
          const cb = (confirmerResponse.balance || {})[k] || 0;
          let has_diff = v !== cb;
          if (!has_diff) {
            letter.confirm_information.balance_details[k].forEach((row) => {
              const responseSameRow = confirmerResponse.balance_details[k].find(
                (r) => r.doc_no === row.doc_no
              );
              has_diff =
                has_diff ||
                responseSameRow.confirm_amount !== row.amount ||
                responseSameRow.cur !== row.cur;
            });
          }
          new_rows = [
            ...new_rows,
            {
              type: t("Balance"),
              key: k,
              amount: v,
              confirm_amount: cb,
              diff: v - cb,
              has_diff: has_diff,
            },
          ];
        });
        Object.entries(letter.confirm_information.transaction).forEach(
          ([k, v]) => {
            const ct = (confirmerResponse.transaction || {})[k] || 0;
            let has_diff = v !== ct;
            if (!has_diff) {
              letter.confirm_information.transaction_details[k].forEach(
                (row) => {
                  const responseSameRow = confirmerResponse.transaction_details[
                    k
                  ].find((r) => r.doc_no === row.doc_no);
                  has_diff =
                    has_diff ||
                    responseSameRow.confirm_amount !== row.amount ||
                    responseSameRow.cur !== row.cur;
                }
              );
            }
            new_rows = [
              ...new_rows,
              {
                type: t("Transaction"),
                key: k,
                amount: v,
                confirm_amount: ct,
                diff: v - ct,
                has_diff: has_diff,
              },
            ];
          }
        );
        Object.entries(confirmerResponse.balance || {}).forEach(([k, v]) => {
          if (
            new_rows.findIndex((e) => e.type === t("Balance") && e.key === k) <
            0
          ) {
            new_rows = [
              ...new_rows,
              {
                type: t("Balance"),
                key: k,
                amount: 0,
                confirm_amount: v,
                diff: 0 - v,
                has_diff: true,
              },
            ];
          }
        });
        Object.entries(confirmerResponse.transaction || {}).forEach(
          ([k, v]) => {
            if (
              new_rows.findIndex(
                (e) => e.type === t("Transaction") && e.key === k
              ) < 0
            ) {
              new_rows = [
                ...new_rows,
                {
                  type: t("Transaction"),
                  key: k,
                  amount: 0,
                  confirm_amount: v,
                  diff: 0 - v,
                  has_diff: true,
                },
              ];
            }
          }
        );
      }
    }
    setRows(new_rows);
  }, [agree, confirmerResponse]);

  return (
    <>
      <SuiBox mt={3}>
        {letter.is_invalid_uuid ? (
          <SuiTypography variant="h6">{t("Invalid letter uuid")}</SuiTypography>
        ) : (
          <>
            {letter.confirmer_submit ? (
              <>
                <CdxCard p={3}>
                  <SuiBox>
                    <SuiTypography variant="h6">
                      {t("Confirmer response")}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={2}>
                    <SuiTypography variant="body2">
                      {(letter.confirmer_response || {}).agree
                        ? t(
                            "Confirmer confirm that all information in the letter is correct."
                          )
                        : t(
                            "Confirmer suggest that information in the letter is incorrect."
                          )}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={3}>
                    <CdxDataTable
                      entriesPerPage={{
                        defaultValue: -1,
                      }}
                      showSelectEntriesPerPage={false}
                      table={{
                        columns: [
                          { Header: t("Type"), accessor: "type" },
                          { Header: t("Key"), accessor: "key" },
                          {
                            Header: t("Letter amount"),
                            accessor: "amount",
                            Cell: ({ value }) =>
                              parseFloat(value.toFixed(2)).toLocaleString(),
                          },
                          {
                            Header: t("Confirm amount"),
                            accessor: "confirm_amount",
                            Cell: ({ value }) =>
                              parseFloat(value.toFixed(2)).toLocaleString(),
                          },
                          {
                            Header: t("Difference"),
                            accessor: "diff",
                            Cell: ({ value }) =>
                              parseFloat(value.toFixed(2)).toLocaleString(),
                          },
                          {
                            Header: t("Agreeable"),
                            accessor: "has_diff",
                            Cell: ({ value }) => {
                              return (
                                <SuiBox
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                >
                                  {value ? (
                                    <Icon color="error">cancel</Icon>
                                  ) : (
                                    <Icon color="success">check_circle</Icon>
                                  )}
                                </SuiBox>
                              );
                            },
                          },
                        ],
                        rows: rows,
                      }}
                    />
                  </SuiBox>
                </CdxCard>
              </>
            ) : (
              <CdxCard p={3}>
                <SuiTypography variant="h6">
                  {t("Confirmer has not yet submitted the response.")}
                </SuiTypography>
              </CdxCard>
            )}
          </>
        )}
      </SuiBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return { letters: state.engagement.letters };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementLetterResponse);
