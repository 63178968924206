import {
  SIGN_IN,
  SIGN_OUT,
  RELOAD_TOKEN,
  UPDATE_CSRF_TOKEN,
  DEPLOY_SNACKBAR,
  UPDATE_SNACKBAR,
  SELECT_ACTIVE_ENGAGEMENT,
  DESELECT_ACTIVE_ENGAGEMENT,
  CONF_SIGN_IN,
  CONF_SIGN_OUT,
  CONF_RELOAD_TOKEN,
} from "actions/types";

const appDefault = {
  isSignIn: null,
  isConfirmerSignIn: null,
  userLastActive: Date.now(),
  snackBars: [],
  csrfToken: "",
  activeEngagementUUID: "",
};

const appReducer = (app = appDefault, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...app, isSignIn: true };
    case SIGN_OUT:
      return { ...app, isSignIn: false, activeEngagementUUID: "" };
    case RELOAD_TOKEN:
      return { ...app, isSignIn: true };
    case CONF_SIGN_IN:
      return { ...app, isConfirmerSignIn: true };
    case CONF_SIGN_OUT:
      return { ...app, isConfirmerSignIn: false };
    case CONF_RELOAD_TOKEN:
      return { ...app, isConfirmerSignIn: true };
    case UPDATE_CSRF_TOKEN:
      return { ...app, csrfToken: action.payload.csrfToken };
    case DEPLOY_SNACKBAR:
      return {
        ...app,
        snackBars: [...app.snackBars, action.payload.newSnackBar],
      };
    case UPDATE_SNACKBAR:
      return {
        ...app,
        snackBars: action.payload.newSnackBars,
      };
    case SELECT_ACTIVE_ENGAGEMENT:
      return { ...app, activeEngagementUUID: action.payload };
    case DESELECT_ACTIVE_ENGAGEMENT:
      return { ...app, activeEngagementUUID: appDefault.activeEngagementUUID };
    default:
      return app;
  }
};

export default appReducer;
