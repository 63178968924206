import { useState } from "react";
import { connect } from "react-redux";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import CdxCard from "components/cdx/CdxCard";
import { useTranslation } from "react-i18next";

import ChangePasswordForm from "./form";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  return (
    <CdxCard>
      <SuiBox p={3}>
        <SuiBox mb={2}>
          <SuiTypography variant="h6">{t("Change password")}</SuiTypography>
        </SuiBox>
        <SuiBox>
          {showForm ? (
            <ChangePasswordForm setShowForm={setShowForm} />
          ) : (
            <SuiButton
              variant="gradient"
              color="warning"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Open change password form
            </SuiButton>
          )}
        </SuiBox>
      </SuiBox>
    </CdxCard>
  );
};

export default ChangePassword;
