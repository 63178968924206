import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import { I18N_COOKIE_NAME } from "settings/i18n";

const getNames = (names) => {
  const curLng =
    Cookies.get(I18N_COOKIE_NAME) ||
    window.localStorage.getItem(I18N_COOKIE_NAME) ||
    "en";
  let name = names.find((e) => e.lang === "en").name;
  if (curLng !== "en") {
    try {
      name = names.find((e) => e.lang === curLng).name;
    } catch {}
  }

  return name;
};

export default getNames;
