import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";

const VerifiedBusinessIcon = () => {
  const { t } = useTranslation();
  return (
    <SuiTypography color="success" textGradient>
      <Tooltip title={t("This business has verified")}>
        <Icon>verified_user</Icon>
      </Tooltip>
    </SuiTypography>
  );
};

export default VerifiedBusinessIcon;
