import {
  LIST_MY_BUSINESS_HASH,
  VIEW_BUSINESS_HASH,
  CLEAR_VIEW_BUSINESS,
  BUSINESS_MEMBER_HASH,
  CLEAR_BUSINESS_MEMBER,
  LIST_MY_ENGAGEMENT_HASH,
  ENGAGEMENT_MEMBER_HASH,
  CLEAR_ENGAGEMENT_MEMBER,
  ENGAGEMENT_DATA_HASH,
  CLEAR_ENGAGEMENT_DATA,
  CONFIRMER_INBOX_HASH,
  CONFIRMER_HISTORY_HASH,
  CONFIRMER_LETTER_HASH,
  CLEAR_CONFIRMER_LETTER,
  ENGAGEMENT_LETTER_HASH,
  CLEAR_ENGAGEMENT_LETTER,
  SIGN_OUT,
  CONF_SIGN_OUT,
} from "actions/types";

const hashDictionaryDefault = {
  LIST_MY_BUSINESS_HASH: "",
  VIEW_BUSINESS_HASH: {},
  BUSINESS_MEMBER_HASH: {},
  LIST_MY_ENGAGEMENT_HASH: "",
  ENGAGEMENT_MEMBER_HASH: {},
  ENGAGEMENT_DATA_HASH: {},
  CONFIRMER_INBOX_HASH: "",
  CONFIRMER_HISTORY_HASH: "",
  CONFIRMER_LETTER_HASH: {},
  ENGAGEMENT_LETTER_HASH: {},
};

const clearHashMap = {
  CLEAR_VIEW_BUSINESS: VIEW_BUSINESS_HASH,
  CLEAR_BUSINESS_MEMBER: BUSINESS_MEMBER_HASH,
  CLEAR_ENGAGEMENT_MEMBER: ENGAGEMENT_MEMBER_HASH,
  CLEAR_ENGAGEMENT_DATA: ENGAGEMENT_DATA_HASH,
  CLEAR_CONFIRMER_LETTER: CONFIRMER_LETTER_HASH,
  CLEAR_ENGAGEMENT_LETTER: ENGAGEMENT_LETTER_HASH,
};

const hashDictionaryReducer = (
  hashDictionary = hashDictionaryDefault,
  action
) => {
  if (Object.keys(hashDictionaryDefault).includes(action.type)) {
    if (typeof hashDictionary[action.type] === "object") {
      return {
        ...hashDictionary,
        [action.type]: {
          ...hashDictionary[action.type],
          ...action.payload,
        },
      };
    } else {
      return { ...hashDictionary, [action.type]: action.payload };
    }
  }

  if (Object.keys(clearHashMap).includes(action.type)) {
    return {
      ...hashDictionary,
      [clearHashMap[action.type]]: action.payload.hashDictionary,
    };
  }

  if ([SIGN_OUT, CONF_SIGN_OUT].includes(action.type)) {
    return { ...hashDictionaryDefault };
  }

  return hashDictionary;
};

export default hashDictionaryReducer;
