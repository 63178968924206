import parse, { domToReact } from "html-react-parser";
import SuiTypography from "components/sui/SuiTypography";
import { useEffect, useState } from "react";

const ConfirmationTemplateParse = ({ template, values = {} }) => {
  const [replaceValue, setReplaceValue] = useState("");

  useEffect(() => {
    let rV = template.replaceAll("\\$", "^$/");
    Object.entries(values).forEach(([k, v]) => {
      rV = rV.replaceAll(`\$${k}`, v);
    });
    rV = rV.replace("^$/", "$");
    setReplaceValue(rV);
  }, [template, values]);

  return (
    <>
      {parse(replaceValue, {
        replace: ({ name, children }) => {
          if (name === "p") {
            return (
              <SuiTypography variant="body2">
                {domToReact(children)}
              </SuiTypography>
            );
          }
          if (name === "h1") {
            return (
              <SuiTypography variant="h4">{domToReact(children)}</SuiTypography>
            );
          }
          if (name === "h2") {
            return (
              <SuiTypography variant="h5">{domToReact(children)}</SuiTypography>
            );
          }
        },
      })}
    </>
  );
};

export default ConfirmationTemplateParse;
