import { useState, Fragment } from "react";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";

import Sidenav from "./Sidenav";
import LetterTemplate from "./letter-template";
import BalanceMapping from "./balance-mapping";
import ArchiveEngagement from "./archive-engagment";

import EngagementRedirect from "pages/engagement/redirect";

const EngagementEdit = () => {
  const [activeTab, setActiveTab] = useState("letterTemplate");

  return (
    <EngagementRedirect>
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav activeTab={activeTab} setActiveTab={setActiveTab} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                {activeTab === "letterTemplate" && (
                  <Grid item xs={12}>
                    <LetterTemplate />
                  </Grid>
                )}
                {activeTab === "balanceMapping" && (
                  <Grid item xs={12}>
                    <BalanceMapping />
                  </Grid>
                )}
                {activeTab === "archiveEngagement" && (
                  <Grid item xs={12}>
                    <ArchiveEngagement />
                  </Grid>
                )}
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </EngagementRedirect>
  );
};

export default EngagementEdit;
