import { useState, useEffect } from "react";
import { connect } from "react-redux";

// @material-ui core components
import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";

import CodexAPI from "app/api/codexapi";

import SuiAvatar from "components/sui/SuiAvatar";
import stringAvatar from "app/utils/stringAvatar";

import axios from "axios";

function CdxUserAvatar({ user, confUser, onClick, size = "sm", app = "app" }) {
  let fullName = "";
  if (app === "confirmer") {
    fullName = confUser.fbData.displayName || confUser.email;
  } else {
    fullName = user.fbData.displayName || user.email;
  }

  const [avatar, setAvatar] = useState();
  const [imageUrl, setImageUrl] = useState(user.fbData.photoURL || "");

  const [imageData, setImageData] = useState();

  useEffect(async () => {
    if (imageUrl) {
      const response = await axios.get(imageUrl);
      try {
        setImageData(response.data);
      } catch {
        setImageData(null);
      }
    } else {
      setImageData(null);
    }
  }, [imageUrl]);

  useEffect(() => {
    setImageUrl(user.fbData.photoURL || "");
  }, [user.fbData.photoURL]);

  useEffect(() => {
    const renderAvatar = () => {
      if (imageData) {
        console.log(imageUrl);
        return (
          <>
            {imageData && (
              <SuiAvatar
                src={imageUrl}
                alt="Avatar"
                size={size}
                mx={5}
                onClick={onClick}
                sx={{ cursor: onClick ? "pointer" : "default" }}
              />
            )}
          </>
        );
      }
      if (fullName !== "Loading user information ...") {
        const sizeDict = {
          xs: 24,
          sm: 36,
          md: 48,
          lg: 58,
          xl: 74,
          xxl: 110,
        };

        const avatarSize = parseInt(size) ? parseInt(size) : sizeDict[size];

        let av = stringAvatar(fullName);
        av = {
          ...av,
          sx: {
            ...av.sx,
            cursor: onClick ? "pointer" : "default",
            width: avatarSize,
            height: avatarSize,
            fontSize: parseInt(avatarSize * 0.5),
          },
        };
        return (
          <>
            <Avatar {...av} mx={5} onClick={onClick} />
          </>
        );
      }
      return (
        <SuiAvatar
          size={size}
          mx={5}
          onClick={onClick}
          sx={{ cursor: onClick ? "pointer" : "default" }}
          bgColor="secondary"
        >
          <Icon>person</Icon>
        </SuiAvatar>
      );
    };
    setAvatar(renderAvatar());
  }, [user, fullName, imageUrl, imageData, onClick]);

  return <>{avatar}</>;
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    confUser: state.confUser,
  };
};

export default connect(mapStateToProps)(CdxUserAvatar);
