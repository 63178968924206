import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SuiButton from "components/sui/SuiButton";

import CodexAPI from "app/api/codexapi";
import { deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

const AcceptButton = ({
  name,
  request_uuid,
  getRequestList,
  deploySnackbar,
  codexApiCatchError,
}) => {
  const { t } = useTranslation();
  const handleOnClick = async () => {
    try {
      const response = await CodexAPI().post(
        "business/member-request-by-user/accept/",
        {
          request_uuid,
        }
      );
      getRequestList();
      const snackBar = {
        color: "success",
        icon: "done",
        title: t("Successfully accept membership request"),
        content: `${t("Member request for")} ${name} ${t("is accepted")}`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };
  return (
    <SuiButton
      size="small"
      variant="gradient"
      color="success"
      onClick={handleOnClick}
    >
      {t("Accept")}
    </SuiButton>
  );
};
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  deploySnackbar,
  codexApiCatchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptButton);
