import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CdxInput from "components/cdx/CdxInput";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CodexAPI from "app/api/codexapi";

const BusinessViewSearch = ({ setBusinessList }) => {
  const { t } = useTranslation();

  const [searchMessage, setSearchMessage] = useState(
    t("Press enter to search")
  );
  const [isSearching, setIsSearching] = useState(false);

  const search = async (val) => {
    if (!val) {
      setSearchMessage(t("Press enter to search"));
      setBusinessList(null);
      return;
    }

    setSearchMessage(`${t("Current search term")}: ${val}`);
    setIsSearching(true);
    const response = await CodexAPI().get("business/find-business/", {
      params: { search: val },
    });
    setBusinessList(response.data.data);
    setIsSearching(false);
  };

  return (
    <>
      <SuiBox mt={2} sx={{ display: "flex", justifyContent: "center" }}>
        <SuiBox sx={{ width: "50rem" }}>
          <CdxInput
            placeholder={t("Keyword or business uuid")}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search(e.target.value);
              }
            }}
            icon={{
              component: "search",
              direction: "left",
            }}
            message={searchMessage}
          />
        </SuiBox>
      </SuiBox>
      {isSearching && (
        <SuiBox
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SuiBox>
            <SuiTypography variant="button">Searching...</SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
    </>
  );
};

export default BusinessViewSearch;
