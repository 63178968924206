import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import CdxInput from "components/cdx/CdxInput";

import CdxCard from "components/cdx/CdxCard";
import { Fragment, useState, useEffect } from "react";

import CodexAPI from "app/api/codexapi";

import { codexApiCatchError } from "actions/snackBar";
import { loadEngagementData, deploySnackbar } from "actions";
import { useNavigate } from "react-router-dom";

import { BASE_PATH } from "settings";

const ArchiveEngagement = ({
  engagementData,
  engagement_uuid,
  loadEngagementData,
  deploySnackbar,
  codexApiCatchError,
}) => {
  const { t } = useTranslation();

  const [isAuditManager, setIsAuditManager] = useState(null);
  const [confirm, setConfirm] = useState("");

  const navigate = useNavigate();

  const submitArchiveEngagement = async () => {
    try {
      const response = await CodexAPI().post(
        "/engagement/archive-engagement/",
        { engagement_uuid: engagement_uuid, confirm_code: confirm }
      );
      loadEngagementData(engagement_uuid);
      navigate(`${BASE_PATH}/engagement/engagement-home`);
      const snackBar = {
        color: "success",
        icon: "done",
        title: "Successfully archive engagement",
        content: `Your engagement has been archived.`,
      };
      deploySnackbar(snackBar);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  useEffect(() => {
    if (engagementData) {
      if (engagementData.you) {
        if (engagementData.you.team === 1 && engagementData.you.type >= 1) {
          setIsAuditManager(true);
        } else {
          setIsAuditManager(false);
        }
      } else {
        setIsAuditManager(false);
      }
    } else {
      setIsAuditManager(false);
    }
  }, [engagementData]);

  return (
    <CdxCard p={3}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6">{t("Archive engagement")}</SuiTypography>
      </SuiBox>
      {isAuditManager ? (
        <Fragment>
          <SuiBox mt={2}>
            <CdxInput
              value={confirm}
              onChange={(e) => {
                setConfirm(e.target.value);
              }}
              placeholder={t(
                "Type archive-engagement to confirm that you want to archive engagement"
              )}
              message={
                confirm !== "archive-engagement" &&
                confirm.length > 0 &&
                t(
                  "Please type archive-engagement to confirm that you want to archive engagement"
                )
              }
              error={confirm !== "archive-engagement" && confirm.length > 0}
            />
          </SuiBox>
          <SuiBox mt={2}>
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              disabled={confirm !== "archive-engagement"}
              onClick={submitArchiveEngagement}
            >
              {t("Archive engagement")}
            </SuiButton>
          </SuiBox>
        </Fragment>
      ) : (
        <SuiBox mt={1}>
          <SuiTypography variant="caption" color="error">
            {t("Only audit manager can archive the engagement")}
          </SuiTypography>
        </SuiBox>
      )}
    </CdxCard>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementData:
      state.engagement.engagementData[state.app.activeEngagementUUID],
    engagement_uuid: state.app.activeEngagementUUID,
  };
};

const mapDispatchToProps = {
  loadEngagementData,
  deploySnackbar,
  codexApiCatchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveEngagement);
