import { useTranslation } from "react-i18next";

import Link from "@mui/material/Link";

import { Grid } from "@mui/material";

import SuiTypography from "components/sui/SuiTypography";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";

import { Form, Field } from "react-final-form";

import checkObjectEqual from "app/utils/checkObjectEqual";

import { PRIVACY_POLICY, TERM_OF_USE } from "settings/routes";
import { LANGUAGES_OPTIONS } from "settings/i18n";

import { rootElement } from "settings";

const InformationForm = ({
  form: { currentValues, setValues, setDisabledNext },
}) => {
  const { t } = useTranslation();
  const tl = useTranslation("languages").t;

  const lang = LANGUAGES_OPTIONS.map((e) => {
    return { value: e.value, label: tl(e.label) };
  });
  const defaultLng = lang.find((e) => e.value === "en");
  const langOptions = lang.filter((e) => e.value !== "en");

  const validateForm = (values) => {
    const error = {};

    if (!values.name1) {
      error.name1 = "Please enter your full name";
    }

    if (Object.values(error).length > 0) {
      setTimeout(() => {
        setDisabledNext(true);
      }, 100);
    } else {
      setDisabledNext(false);
      if (
        !checkObjectEqual(values, currentValues) &&
        Object.values(values).length > 0
      ) {
        setValues(values);
      }
    }
    return error;
  };

  const onSubmit = (values) => {};

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      initialValues={currentValues}
      render={({ handleSubmit, form, submitError, submitting, pristine }) => {
        const formState = form.getState();
        return (
          <Grid
            container
            columnSpacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            component="form"
            role="form"
            width="100%"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={4}>
              <Field name="lang1" initialValue={defaultLng.value}>
                {({ input, meta }) => (
                  <CdxInput
                    value={defaultLng.label}
                    mb={2}
                    label={t("Language")}
                    disabled={true}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={8}>
              <Field name="name1">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={2}
                    label={t("Full name*")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="lang2">
                {({ input, meta }) => (
                  <CdxSelect
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={2}
                    options={langOptions}
                    label={t("Other language")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                    menuPortalTarget={rootElement}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={8}>
              <Field name="name2">
                {({ input, meta }) => (
                  <CdxInput
                    name={input.name}
                    type={input.type}
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    mb={2}
                    label={t("Full name (optional)")}
                    error={
                      !!(meta.error && meta.touched) ||
                      !!meta.submitError ||
                      !!submitError
                    }
                    message={
                      ((meta.error && meta.touched) || meta.submitError) &&
                      (t(meta.error) || t(meta.submitError))
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <SuiTypography color="dark" variant="caption">
                {t(
                  "This information is required and will included in the confirmation."
                )}{" "}
                {t("Please read our ")}
                <Link href={PRIVACY_POLICY} target="_blank">
                  <SuiTypography
                    color="dark"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("privacy policy")}
                  </SuiTypography>
                </Link>
                {t(". By click submit, you're agree with our ")}
                <Link href={TERM_OF_USE} target="_blank">
                  <SuiTypography
                    color="dark"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("term of use")}
                  </SuiTypography>
                </Link>
                {t(".")}
              </SuiTypography>
            </Grid>
            {submitError && (
              <Grid item xs={12}>
                <SuiTypography color="error" variant="caption">
                  {t(submitError)}
                </SuiTypography>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default InformationForm;
