import { combineReducers } from "redux";
import appReducer from "reducers/app";
import userReducer from "reducers/user";
import businessReducer from "reducers/business";
import engagementReducer from "reducers/engagement";
import hashDictionaryReducer from "reducers/hashDictionary";
import confirmerReducer from "reducers/confirmer";
import confUserReducer from "reducers/confUser";

export default combineReducers({
  app: appReducer,
  business: businessReducer,
  engagement: engagementReducer,
  hashDictionary: hashDictionaryReducer,
  user: userReducer,
  confirmer: confirmerReducer,
  confUser: confUserReducer,
});
