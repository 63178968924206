import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import SuiButton from "components/sui/SuiButton";
import CdxInput from "components/cdx/CdxInput";

import updateUserProfile from "app/fb/updateUserProfile";

const FullNameForm = ({ displayName, setOnEdit }) => {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState(displayName);

  const handleSubmit = async () => {
    if (fullName !== displayName) {
      await updateUserProfile({ displayName: fullName });
    }
    setOnEdit(false);
  };

  return (
    <>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} md={6} xxl={4}>
          <CdxInput
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            label={t("Full name")}
          />
        </Grid>
        <Grid item xs={12} md={5} mb={0.8}>
          <Grid container spacing={1}>
            <Grid item>
              <SuiButton
                color="white"
                iconOnly
                circular
                onClick={() => setOnEdit(false)}
              >
                <Icon color="error">close</Icon>
              </SuiButton>
            </Grid>
            <Grid item>
              <SuiButton
                color="white"
                iconOnly
                circular
                onClick={handleSubmit}
                disabled={!fullName || fullName === displayName}
              >
                <Icon
                  color={
                    !fullName || fullName === displayName
                      ? "secondary"
                      : "success"
                  }
                >
                  check
                </Icon>
              </SuiButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return { displayName: state.user.fbData.displayName };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FullNameForm);
