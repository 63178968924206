import { CONF_SIGN_IN, CONF_SIGN_OUT, CONF_RELOAD_TOKEN } from "actions/types";

const confUserDefault = {
  email: "",
  fbData: {},
  token: "",
  tokenExpireAt: "",
  confUserData: {},
  userSetting: {},
};

const userReducer = (confUser = confUserDefault, action) => {
  switch (action.type) {
    case CONF_SIGN_IN:
      return { ...confUser, ...action.payload.user };
    case CONF_RELOAD_TOKEN:
      return { ...confUser, ...action.payload.user };
    case CONF_SIGN_OUT:
      return { ...confUserDefault };
    default:
      return confUser;
  }
};

export default userReducer;
