import SuiButton from "components/sui/SuiButton";

import { Grid, Icon } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { BASE_PATH } from "settings";

const NotStaffFunctionBar = ({ uuid, rawUuid }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Grid
        columnSpacing={2}
        rowSpacing={1}
        container
        item
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
        xs={12}
        lg={6}
      >
        <Grid item>
          <SuiButton
            size="small"
            color="secondary"
            variant="gradient"
            onClick={() => {
              navigate(
                `${BASE_PATH}/overview/business/manage-business?request_uuid=${rawUuid}`
              );
            }}
          >
            {t("Request to join")}
          </SuiButton>
        </Grid>
        <Grid item>
          <SuiButton
            size="small"
            color="dark"
            variant="gradient"
            onClick={() => {
              navigate(
                `${BASE_PATH}/overview/engagement/create-new-engagement?counter_uuid=${rawUuid}`
              );
            }}
          >
            {t("Create an engagement")}
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
};

export default NotStaffFunctionBar;
