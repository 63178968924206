import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";

import { useTranslation } from "react-i18next";
import CreateNewBusinessForm from "./CreateNewEngagementForm";

const CreateNewEngagement = () => {
  const { t } = useTranslation();
  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">
                {t("Create new engagement")}
              </SuiTypography>
            </Grid>
          </Grid>
          <CreateNewBusinessForm />
        </SuiBox>
      </CdxCard>
    </SuiBox>
  );
};

export default CreateNewEngagement;
