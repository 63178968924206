import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import SuiButton from "components/sui/SuiButton";
import Icon from "@mui/material/Icon";

import { BASE_PATH } from "settings";

import { selectActiveEngagement } from "actions";

const GotoButton = ({ engagementUUID, selectActiveEngagement }) => {
  const navigate = useNavigate();
  return (
    <SuiButton
      size="small"
      variant="gradient"
      color="secondary"
      iconOnly
      circular
      onClick={() => {
        selectActiveEngagement(engagementUUID);
        navigate(
          `${BASE_PATH}/engagement/engagement-home?uuid=${engagementUUID}`
        );
      }}
    >
      <Icon>visibility</Icon>
    </SuiButton>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = { selectActiveEngagement };

export default connect(mapStateToProps, mapDispatchToProps)(GotoButton);
