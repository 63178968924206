import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxCard from "components/cdx/CdxCard";
import CdxInput from "components/cdx/CdxInput";
import { connect } from "react-redux";
import CodexAPI from "app/api/codexapi";
import { deploySnackbar } from "actions";
import { codexApiCatchError } from "actions/snackBar";

import timeSince from "app/utils/timeSince";

import { BUSINESS_MEMBERSHIP_TYPE } from "settings/confirmation";
import UserAcceptButton from "./UserAcceptButton";
import UserRejectButton from "./UserRejectButton";
import UserCancelButton from "./UserCancelButton";

import AdminAcceptButton from "./AdminAcceptButton";
import AdminRejectButton from "./AdminRejectButton";
import AdminCancelButton from "./AdminCancelButton";

import { parse as parseUUID } from "uuid";
import CdxButton from "components/cdx/CdxButton";

import { useSearchParams } from "react-router-dom";

import getNames from "app/utils/getNames";

const ManageGeneralBusiness = ({ deploySnackbar, codexApiCatchError }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [requestUuid, setRequestUuid] = useState(
    searchParams.get("request_uuid") || ""
  );

  useEffect(() => {
    setRequestUuid(searchParams.get("request_uuid") || "");
  }, [searchParams]);

  const [userRequestList, setUserRequestList] = useState([]);
  const [adminRequestList, setAdminRequestList] = useState([]);
  const [businessUUIDInput, setBusinessUUIDInput] = useState(requestUuid || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(false);

  const getRequestList = async () => {
    try {
      const response = await CodexAPI().get("business/list-my-member-request/");
      setUserRequestList(response.data.data.user);
      setAdminRequestList(response.data.data.admin);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  useEffect(() => {
    getRequestList();
  }, []);

  const makingRequest = async () => {
    setErrorMessage("");
    try {
      parseUUID(businessUUIDInput);
    } catch {
      setErrorMessage(t("Invalid UUID format"));
      return;
    }
    setProgress(true);
    try {
      const response = await CodexAPI().post(
        "business/member-request-by-user/request/",
        { business_uuid: businessUUIDInput }
      );
      getRequestList();
      const snackBar = {
        color: "success",
        icon: "done",
        title: t("Successfully make a request"),
        content: `${t("Request has send to ")} ${businessUUIDInput}`,
      };
      deploySnackbar(snackBar);
      setBusinessUUIDInput("");
    } catch (error) {
      codexApiCatchError(error);
    }
    setProgress(false);
  };

  const renderUserCard = (e) => {
    const name = getNames(e.business_names);

    if (e.user_accepted_at) {
      return (
        <Grid item xs={12} md={6} lg={4} xxl={3} key={e.uuid}>
          <CdxCard>
            <SuiBox p={2}>
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <SuiTypography variant="h6">{name}</SuiTypography>
                <SuiTypography variant="caption">
                  {timeSince(e.user_accepted_at)}
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <SuiTypography variant="caption">
                  {t("Your make this request")}
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <SuiTypography variant="caption">
                  {t("Postion")}:{" "}
                  <SuiTypography variant="caption" fontWeight="bold">
                    {t(BUSINESS_MEMBERSHIP_TYPE.at(e.type))}
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={1}>
                <UserCancelButton
                  name={name}
                  request_uuid={e.uuid}
                  getRequestList={getRequestList}
                />
              </SuiBox>
            </SuiBox>
          </CdxCard>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} md={6} lg={4} xxl={3} key={e.uuid}>
          <CdxCard>
            <SuiBox p={2}>
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <SuiTypography variant="h6">{name}</SuiTypography>
                <SuiTypography variant="caption">
                  {timeSince(e.admin_accepted_at)}
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <SuiTypography variant="caption">
                  {t("Request by")}:{" "}
                  <SuiTypography variant="caption" fontWeight="bold">
                    {e.admin_email}
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <SuiTypography variant="caption">
                  {t("Postion")}:{" "}
                  <SuiTypography variant="caption" fontWeight="bold">
                    {t(BUSINESS_MEMBERSHIP_TYPE.at(e.type))}
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
              <SuiBox
                mt={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserRejectButton
                  name={name}
                  request_uuid={e.uuid}
                  getRequestList={getRequestList}
                />
                <UserAcceptButton
                  name={name}
                  request_uuid={e.uuid}
                  getRequestList={getRequestList}
                />
              </SuiBox>
            </SuiBox>
          </CdxCard>
        </Grid>
      );
    }
  };

  const renderAdminCard = (e) => {
    const name = getNames(e.business_names);

    return (
      <Grid item xs={12} md={6} lg={4} xxl={3} key={e.uuid}>
        <CdxCard>
          <SuiBox p={2}>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SuiTypography variant="h6">{name}</SuiTypography>
              <SuiTypography variant="caption">
                {e.user_accepted_at
                  ? timeSince(e.user_accepted_at)
                  : timeSince(e.admin_accepted_at)}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="caption">
                {t("Email")}:{" "}
                <SuiTypography variant="caption" fontWeight="bold">
                  {e.user_email}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="caption">
                {t("Postion")}:{" "}
                <SuiTypography variant="caption" fontWeight="bold">
                  {t(BUSINESS_MEMBERSHIP_TYPE.at(e.type))}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            {e.admin_email && (
              <SuiBox>
                <SuiTypography variant="caption">
                  {t("Accepted by")}:{" "}
                  <SuiTypography variant="caption" fontWeight="bold">
                    {e.admin_email}
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            )}
            <SuiBox
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {e.admin_accepted_at ? (
                <AdminCancelButton
                  name={name}
                  request_uuid={e.uuid}
                  business_uuid={e.business_uuid}
                  fullWidth
                  getRequestList={getRequestList}
                />
              ) : (
                <>
                  <AdminRejectButton
                    name={name}
                    request_uuid={e.uuid}
                    business_uuid={e.business_uuid}
                    getRequestList={getRequestList}
                  />
                  <AdminAcceptButton
                    name={name}
                    request_uuid={e.uuid}
                    business_uuid={e.business_uuid}
                    getRequestList={getRequestList}
                  />
                </>
              )}
            </SuiBox>
          </SuiBox>
        </CdxCard>
      </Grid>
    );
  };

  return (
    <SuiBox mt={3}>
      <CdxCard>
        <SuiBox p={3}>
          <Grid container>
            <Grid item xs={12} md={3} lg={5} mb={{ xs: 2, md: 0 }}>
              <SuiTypography variant="h5">{t("Manage business")}</SuiTypography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item minWidth="20rem">
              <CdxInput
                label={t("Business uuid")}
                value={businessUUIDInput}
                onChange={(e) => {
                  setBusinessUUIDInput(e.target.value);
                }}
                error={!!errorMessage}
                message={errorMessage}
              />
            </Grid>
            <Grid item>
              <SuiBox
                mb={1}
                ml={0.5}
                lineHeight={0}
                display={{ xs: "none", sm: "inline-block" }}
              >
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {" "}
                </SuiTypography>
              </SuiBox>
              <SuiBox>
                <CdxButton
                  variant="gradient"
                  color="primary"
                  onClick={makingRequest}
                  progress={progress}
                >
                  {t("Make a member request")}
                </CdxButton>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </CdxCard>
      <SuiBox mt={3} mb={2}>
        <SuiTypography variant="h5">
          {t("Your business membership request")}
        </SuiTypography>
      </SuiBox>
      {userRequestList.length > 0 ? (
        <Grid container spacing={2}>
          {userRequestList.map((e) => {
            return renderUserCard(e);
          })}
        </Grid>
      ) : (
        <SuiTypography variant="body2">{t("No active request")}</SuiTypography>
      )}
      <SuiBox mt={3} mb={2}>
        <SuiTypography variant="h5">
          {t("Administrative your business")}
        </SuiTypography>
      </SuiBox>
      {adminRequestList.length > 0 ? (
        <Grid container spacing={2}>
          {adminRequestList.map((e) => {
            return renderAdminCard(e);
          })}
        </Grid>
      ) : (
        <SuiTypography variant="body2">{t("No active request")}</SuiTypography>
      )}
    </SuiBox>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = { deploySnackbar, codexApiCatchError };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageGeneralBusiness);
