import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Grid, Icon } from "@mui/material";

import CdxInput from "components/cdx/CdxInput";
import CdxSelect from "components/cdx/CdxSelect";
import CdxButton from "components/cdx/CdxButton";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";

import { confirmerHistory } from "actions";

import getNames from "app/utils/getNames";
import GotoButton from "../GotoButton";

const ConfirmerHistoryFunctionBar = ({
  setRows,
  history,
  confirmerHistory,
  deploySnackbar,
}) => {
  const { t } = useTranslation();
  const curLang = useTranslation().i18n.language;
  const [search, setSearch] = useState("");
  const [historyRow, setHistoryRow] = useState([]);

  useEffect(() => {
    confirmerHistory();
  }, []);

  useEffect(() => {
    const rows = history.map((e) => {
      return {
        ...e,
        client_name: getNames(e.client_names),
        auditor_name: getNames(e.auditor_names),
        action: <GotoButton letterUUID={e.letterUUID} />,
      };
    });
    setHistoryRow(rows);
  }, [history, curLang]);

  useEffect(() => {
    if (search) {
      const searchRegExp = new RegExp(search, "i");
      let arr1 = historyRow.filter((e) => {
        let valid = e.confirm_entity_name.search(searchRegExp) >= 0;
        valid =
          valid ||
          e.client_names.filter((n) => n.name.search(searchRegExp) >= 0)
            .length > 0;
        valid =
          valid ||
          e.auditor_names.filter((n) => n.name.search(searchRegExp) >= 0)
            .length > 0;
        return valid;
      });

      const combineArr = [...new Set([...arr1])];
      arr1 = null;
      setRows(combineArr);
    } else {
      setRows(historyRow);
    }
  }, [historyRow, search]);

  return (
    <>
      <Grid mt={1} container spacing={2} direction="row">
        <Grid item xs>
          <CdxInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            icon={{
              component: "search",
              direction: "left",
            }}
            // error={!!errorSearchMessage}
            // message={errorSearchMessage || searchMessage}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    history: state.confirmer.history,
  };
};

const mapDispatchToProps = { confirmerHistory };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmerHistoryFunctionBar);
