import CdxCard from "components/cdx/CdxCard";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useNavigate } from "react-router-dom";
import { BASE_PATH } from "settings";


import VerifiedBusinessIcon from "../VerifiedBusinessIcon";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import getNames from "app/utils/getNames";

const BusinessCard = ({ business }) => {
  const navigate = useNavigate();

  const name = getNames(business.names);

  return (
    <CdxCard
      onClick={() => {
        navigate(
          `${BASE_PATH}/overview/business/view-business/?uuid=${business.uuid}`
        );
      }}
    >
      <SuiBox p={2}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SuiTypography variant="h6">{name}</SuiTypography>
          {business.is_verified && <VerifiedBusinessIcon />}
        </SuiBox>
        <SuiBox>
          <SuiTypography variant="caption">
            {business.visibility === 1 ? (
              <>{t("Public")}</>
            ) : (
              <>{t("Private")}</>
            )}
          </SuiTypography>
        </SuiBox>
        {business.description && (
          <SuiBox>
            <SuiTypography variant="caption">
              {business.description}
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
    </CdxCard>
  );
};

export default BusinessCard;
