import SuiBox from "components/sui/SuiBox";
import SuiSelect from "components/sui/SuiSelect";
import SuiTypography from "components/sui/SuiTypography";
import SuiInput from "components/sui/SuiInput";

const CdxSelect = ({
  label,
  mb,
  error,
  success,
  message,
  value,
  onChange,
  onBlur = () => {},
  options,
  disabled,
  ...inputProps
}) => {
  const color = () => {
    if (error) {
      return "error";
    } else if (success) {
      return "success";
    } else {
      return "inherit";
    }
  };

  const selectValue = () => {
    return options.find((option) => option.value === value);
  };

  const selectOnChange = (e) => {
    onChange(e.value);
    onBlur(e);
  };

  return (
    <>
      {label && (
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox mb={mb ? mb : 0}>
        {!disabled && (
          <SuiSelect
            {...inputProps}
            value={selectValue() || null}
            onChange={selectOnChange}
            options={options}
            error={error}
            success={success}
          />
        )}
        {disabled && <SuiInput value={selectValue().label} disabled />}
        {message && (
          <SuiBox px={1.5} sx={{ lineHeight: 1 }}>
            <SuiTypography color={color()} variant="caption">
              {message}
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
    </>
  );
};

export default CdxSelect;
