import { useNavigate } from "react-router-dom";

import SuiButton from "components/sui/SuiButton";
import Icon from "@mui/material/Icon";

import { BASE_PATH } from "settings";

const GotoButton = ({ letterUUID }) => {
  const navigate = useNavigate();
  return (
    <SuiButton
      size="small"
      variant="gradient"
      color="secondary"
      iconOnly
      circular
      onClick={() => {
        navigate(
          `${BASE_PATH}/engagement/track/response?letter_uuid=${letterUUID}`
        );
      }}
    >
      <Icon>visibility</Icon>
    </SuiButton>
  );
};

export default GotoButton;
