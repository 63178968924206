import { Icon } from "@mui/material";
import CdxButton from "components/cdx/CdxButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CodexAPI from "app/api/codexapi";

import { connect } from "react-redux";
import { codexApiCatchError } from "actions/snackBar";

import * as XLSX from "xlsx/xlsx.mjs";

const DownloadSummary = ({ activeEngagementUUID, codexApiCatchError }) => {
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);

  const downloadSummary = async () => {
    setDownloading(true);
    let letters = [];
    try {
      let response = await CodexAPI().get(
        "/engagement/all-submitted-letter-details/hash/",
        { params: { uuid: activeEngagementUUID } }
      );
      const hash = response.data.data;
      response = await CodexAPI().get(
        "/engagement/all-submitted-letter-details/",
        {
          params: { uuid: activeEngagementUUID, hash: hash },
        }
      );
      letters = response.data.data;
    } catch (error) {
      codexApiCatchError(error);
    }

    if (letters.length > 0) {
      let balance = [];
      let transaction = [];
      letters.forEach((letter) => {
        if (letter.confirmer_response.agree) {
          Object.entries(letter.confirm_information.balance_details).forEach(
            ([k, v]) => {
              const clean_row = v.map((row) => {
                return {
                  confirm_entity_code:
                    letter.confirm_information.confirm_entity_code,
                  confirm_entity_name:
                    letter.confirm_information.confirm_entity_name,
                  key: k,
                  doc_no: row.doc_no,
                  doc_date: row.doc_date,
                  cur: row.cur,
                  confirm_cur: row.cur,
                  amount: row.amount,
                  confirm_amount: row.amount,
                  diff: 0,
                  agree: true,
                };
              });
              balance = [...balance, ...clean_row];
            }
          );
          Object.entries(
            letter.confirm_information.transaction_details
          ).forEach(([k, v]) => {
            const clean_row = v.map((row) => {
              return {
                confirm_entity_code:
                  letter.confirm_information.confirm_entity_code,
                confirm_entity_name:
                  letter.confirm_information.confirm_entity_name,
                key: k,
                doc_no: row.doc_no,
                doc_date: row.doc_date,
                cur: row.cur,
                confirm_cur: row.cur,
                amount: row.amount,
                confirm_amount: row.amount,
                diff: 0,
                agree: true,
              };
            });
            transaction = [...transaction, ...clean_row];
          });
        } else {
          Object.entries(letter.confirmer_response.balance_details).forEach(
            ([k, v]) => {
              const clean_row = v.map((row) => {
                const info = letter.confirm_information.balance_details[k].find(
                  (dn) => dn.doc_no === row.doc_no
                );
                return {
                  confirm_entity_code:
                    letter.confirm_information.confirm_entity_code,
                  confirm_entity_name:
                    letter.confirm_information.confirm_entity_name,
                  key: k,
                  doc_no: row.doc_no,
                  doc_date: row.doc_date,
                  cur: info ? info.cur : "n/a",
                  confirm_cur: row.cur,
                  amount: info ? info.amount : 0,
                  confirm_amount: row.confirm_amount,
                  diff: (info ? info.amount : 0) - row.confirm_amount,
                  agree:
                    (info || {}).cur === row.cur &&
                    (info || {}).amount === row.confirm_amount &&
                    !row.response_remark,
                  response_remark: row.response_remark,
                };
              });
              balance = [...balance, ...clean_row];
            }
          );
          Object.entries(letter.confirmer_response.transaction_details).forEach(
            ([k, v]) => {
              const clean_row = v.map((row) => {
                const info = letter.confirm_information.transaction_details[
                  k
                ].find((dn) => dn.doc_no === row.doc_no);
                return {
                  confirm_entity_code:
                    letter.confirm_information.confirm_entity_code,
                  confirm_entity_name:
                    letter.confirm_information.confirm_entity_name,
                  key: k,
                  doc_no: row.doc_no,
                  doc_date: row.doc_date,
                  cur: info ? info.cur : "n/a",
                  confirm_cur: row.cur,
                  amount: info ? info.amount : 0,
                  confirm_amount: row.confirm_amount,
                  diff: (info ? info.amount : 0) - row.confirm_amount,
                  agree:
                    (info || {}).cur === row.cur &&
                    (info || {}).amount === row.confirm_amount &&
                    !row.response_remark,
                  response_remark: row.response_remark,
                };
              });
              transaction = [...transaction, ...clean_row];
            }
          );
        }
      });
      let workbook = XLSX.utils.book_new();
      let worksheet = null;

      worksheet = XLSX.utils.json_to_sheet(balance);
      XLSX.utils.book_append_sheet(workbook, worksheet, "balance");
      worksheet = XLSX.utils.json_to_sheet(transaction);
      XLSX.utils.book_append_sheet(workbook, worksheet, "transaction");
      XLSX.writeFile(
        workbook,
        `summary_response_${activeEngagementUUID.slice(0, 4)}.xlsx`
      );
    }

    setDownloading(false);
  };

  return (
    <CdxButton
      onClick={downloadSummary}
      color="dark"
      size="small"
      variant="gradient"
      progress={downloading}
    >
      <Icon>download</Icon>&nbsp;{t("Download summary")}
    </CdxButton>
  );
};

const mapStateToProps = (state) => {
  return { activeEngagementUUID: state.app.activeEngagementUUID };
};

const mapDispatchToProps = {
  codexApiCatchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadSummary);
