import { Divider, Grid, Icon } from "@mui/material";
import { useState, useEffect } from "react";

import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";
import { useTranslation } from "react-i18next";
import CdxDataTable from "components/cdx/CdxDataTable";
import CodexAPIConfirmer from "app/api/codexapiconfirmer";
import { connect } from "react-redux";
import { loadConfirmerLetter } from "actions";

import { codexApiCatchError } from "actions/snackBar";

const PreviewEditResponse = ({
  letter_uuid,
  agree,
  editResponse,
  information,
  loadConfirmerLetter,
  codexApiCatchError,
  setOnDraftResponse,
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [disabledSave, setDisabledSave] = useState(false);

  useEffect(() => {
    setDisabledSave(false);
    if (!agree && Object.keys(editResponse).length === 0) {
      setDisabledSave(true);
    }
  }, [agree, editResponse]);

  useEffect(() => {
    let new_rows = [];
    if (agree) {
      Object.entries(information.balance).forEach(([k, v]) => {
        new_rows = [
          ...new_rows,
          {
            type: t("Balance"),
            key: k,
            amount: v,
            confirm_amount: v,
            diff: 0,
            has_diff: false,
          },
        ];
      });
      Object.entries(information.transaction).forEach(([k, v]) => {
        new_rows = [
          ...new_rows,
          {
            type: t("Transaction"),
            key: k,
            amount: v,
            confirm_amount: v,
            diff: 0,
            has_diff: false,
          },
        ];
      });
    } else {
      Object.entries(information.balance).forEach(([k, v]) => {
        const cb = (editResponse.balance || {})[k] || 0;
        let has_diff = v !== cb;
        if (!has_diff) {
          information.balance_details[k].forEach((row) => {
            const responseSameRow = editResponse.balance_details[k].find(
              (r) => r.doc_no === row.doc_no
            );
            has_diff =
              has_diff ||
              responseSameRow.confirm_amount !== row.amount ||
              responseSameRow.cur !== row.cur;
          });
        }
        new_rows = [
          ...new_rows,
          {
            type: t("Balance"),
            key: k,
            amount: v,
            confirm_amount: cb,
            diff: v - cb,
            has_diff: has_diff,
          },
        ];
      });
      Object.entries(information.transaction).forEach(([k, v]) => {
        const ct = (editResponse.transaction || {})[k] || 0;
        let has_diff = v !== ct;
        if (!has_diff) {
          information.transaction_details[k].forEach((row) => {
            const responseSameRow = editResponse.transaction_details[k].find(
              (r) => r.doc_no === row.doc_no
            );
            has_diff =
              has_diff ||
              responseSameRow.confirm_amount !== row.amount ||
              responseSameRow.cur !== row.cur;
          });
        }
        new_rows = [
          ...new_rows,
          {
            type: t("Transaction"),
            key: k,
            amount: v,
            confirm_amount: ct,
            diff: v - ct,
            has_diff: has_diff,
          },
        ];
      });
      Object.entries(editResponse.transaction || {}).forEach(([k, v]) => {
        if (
          new_rows.findIndex(
            (e) => e.type === t("Transaction") && e.key === k
          ) < 0
        ) {
          new_rows = [
            ...new_rows,
            {
              type: t("Transaction"),
              key: k,
              amount: 0,
              confirm_amount: v,
              diff: 0 - v,
              has_diff: true,
            },
          ];
        }
      });
      Object.entries(editResponse.balance || {}).forEach(([k, v]) => {
        if (
          new_rows.findIndex((e) => e.type === t("Balance") && e.key === k) < 0
        ) {
          new_rows = [
            ...new_rows,
            {
              type: t("Balance"),
              key: k,
              amount: 0,
              confirm_amount: v,
              diff: 0 - v,
              has_diff: true,
            },
          ];
        }
      });
    }
    setRows(new_rows);
  }, [agree, editResponse]);

  const submitEditResponse = async () => {
    let data = {};
    if (agree) {
      data["agree"] = true;
      data["balance"] = information.balance;
      data["transaction"] = information.transaction;
    } else {
      data = editResponse;
    }
    try {
      const response = await CodexAPIConfirmer().post(
        "confirmer/response/edit/",
        {
          letter_uuid: letter_uuid,
          response: data,
        }
      );
      loadConfirmerLetter(letter_uuid);
      setOnDraftResponse(false);
    } catch (error) {
      codexApiCatchError(error);
    }
  };

  const cancelEditResponse = () => {
    setOnDraftResponse(false);
  };

  return (
    <>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center">
        <SuiBox>
          <SuiTypography variant="h6">
            {t("Preview draft response")}
          </SuiTypography>
        </SuiBox>
        <SuiBox>
          <Grid container spacing={2}>
            <Grid item>
              <SuiButton
                color="error"
                variant="gradient"
                size="small"
                onClick={cancelEditResponse}
              >
                {t("Cancel")}
              </SuiButton>
            </Grid>
            <Grid item>
              <SuiButton
                color="success"
                variant="gradient"
                size="small"
                onClick={submitEditResponse}
                disabled={disabledSave}
              >
                {t("Save response draft")}
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>

      <SuiBox>
        <SuiTypography variant="body2">
          {agree
            ? t("You confirm that all information in the letter is correct.")
            : t("You suggest that information in the letter is incorrect.")}
        </SuiTypography>
        <SuiBox mt={3}>
          <CdxDataTable
            entriesPerPage={{
              defaultValue: -1,
            }}
            showSelectEntriesPerPage={false}
            table={{
              columns: [
                { Header: t("Type"), accessor: "type" },
                { Header: t("Key"), accessor: "key" },
                {
                  Header: t("Letter amount"),
                  accessor: "amount",
                  Cell: ({ value }) =>
                    parseFloat(value.toFixed(2)).toLocaleString(),
                },
                {
                  Header: t("Confirm amount"),
                  accessor: "confirm_amount",
                  Cell: ({ value }) =>
                    parseFloat(value.toFixed(2)).toLocaleString(),
                },
                {
                  Header: t("Difference"),
                  accessor: "diff",
                  Cell: ({ value }) =>
                    parseFloat(value.toFixed(2)).toLocaleString(),
                },
                {
                  Header: t("Agreeable"),
                  accessor: "has_diff",
                  Cell: ({ value }) => {
                    return (
                      <SuiBox height="100%" display="flex" alignItems="center">
                        {value ? (
                          <Icon color="error">cancel</Icon>
                        ) : (
                          <Icon color="success">check_circle</Icon>
                        )}
                      </SuiBox>
                    );
                  },
                },
              ],
              rows: rows,
            }}
          />
        </SuiBox>
      </SuiBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { loadConfirmerLetter, codexApiCatchError };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewEditResponse);
