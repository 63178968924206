import CdxCard from "components/cdx/CdxCard";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";
import { Grid, Icon } from "@mui/material";

import { connect } from "react-redux";

import ConfirmationTemplateParse from "app/utils/ConfirmationTemplateParse";

import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CdxButton from "components/cdx/CdxButton";

import getNames from "app/utils/getNames";
import { NavLink } from "react-router-dom";

import * as XLSX from "xlsx/xlsx.mjs";
import { BASE_PATH } from "settings";

const ConfirmerLetterView = ({ letters, email }) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useState("");
  const [value, setValue] = useState({});
  const [letter, setLetter] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [letter_uuid, setUuid] = useState(searchParams.get("letter_uuid"));
  const [downloading, setDownloading] = useState(false);

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    setUuid(searchParams.get("letter_uuid"));
  }, [searchParams]);

  useEffect(() => {
    const temp = letters[letter_uuid.replace(/-/gi, "")];
    if (temp) {
      setLetter(temp);
    } else {
      setLetter({});
    }
  }, [letters, letter_uuid]);

  useEffect(() => {
    if (Object.keys(letter).length > 0) {
      if (!letter.is_invalid_uuid) {
        setTemplate(letter.template);
        const recipient = letter.recipients.find((e) => e.email === email);
        const payload = {
          recipient_name: recipient.name,
          recipient_email: recipient.email,
          recipient_phone: recipient.phone,
          recipient_position: recipient.position,
          confirm_entity_name: letter.confirm_information.confirm_entity_name,
        };
        Object.entries(letter.confirm_information.balance).forEach(([k, v]) => {
          payload[`balance__${k}`] = v.toLocaleString();
        });
        Object.entries(letter.confirm_information.transaction).forEach(
          ([k, v]) => {
            payload[`transaction__${k}`] = v.toLocaleString();
          }
        );
        setValue(payload);

        const d = new Date(letter.expire_at);
        setIsExpired(letter.expire_at && d < Date.now());
      } else {
        setTemplate("");
        setValue({});
      }
    } else {
      setTemplate("");
      setValue({});
    }
  }, [letter]);

  const downloadXLSX = () => {
    setDownloading(true);
    let workbook = XLSX.utils.book_new();
    let worksheet = null;
    Object.entries(letter.confirm_information.balance_details).forEach(
      ([k, v]) => {
        v = v.map((e) => {
          return { ...e, confirm_cur: e.cur };
        });
        worksheet = XLSX.utils.json_to_sheet(v, {
          header: [
            "doc_no",
            "doc_date",
            "cur",
            "amount",
            "remark",
            "confirm_cur",
            "confirm_amount",
            "response_remark",
          ],
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, `balance_${k}`);
      }
    );
    Object.entries(letter.confirm_information.transaction_details).forEach(
      ([k, v]) => {
        v = v.map((e) => {
          return { ...e, confirm_cur: e.cur };
        });
        worksheet = XLSX.utils.json_to_sheet(v, {
          header: [
            "doc_no",
            "doc_date",
            "cur",
            "amount",
            "remark",
            "confirm_cur",
            "confirm_amount",
            "response_remark",
          ],
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, `transaction_${k}`);
      }
    );
    XLSX.writeFile(workbook, "confirm_response.xlsx");

    setDownloading(false);
  };

  return (
    <>
      <SuiBox mt={3}>
        {letter.is_invalid_uuid && (
          <SuiTypography variant="h6">{t("Invalid letter uuid")}</SuiTypography>
        )}
        {template && (
          <>
            <CdxCard p={3}>
              <ConfirmationTemplateParse template={template} values={value} />
            </CdxCard>
            <CdxCard p={3} mt={3}>
              <SuiTypography variant="h6">
                {t("Sender information")}
              </SuiTypography>
              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12} sm={6}>
                  <SuiTypography variant="body2" fontWeight={"bold"}>
                    {t("Sender business")}:{" "}
                  </SuiTypography>
                  <NavLink
                    to={`${BASE_PATH}/confirmer/view-business?uuid=${letter.clientUUID}&letter_uuid=${letter.letterUUID}`}
                  >
                    <SuiTypography variant="body2" fontWeight={"regular"}>
                      {getNames(letter.client_names)}
                    </SuiTypography>
                  </NavLink>
                  <SuiTypography variant="caption">
                    {t("Sender email")}: {letter.client_email}
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiTypography variant="body2" fontWeight={"bold"}>
                    {t("Auditor business")}:{" "}
                  </SuiTypography>
                  <NavLink
                    to={`${BASE_PATH}/confirmer/view-business?uuid=${letter.auditorUUID}&letter_uuid=${letter.letterUUID}`}
                  >
                    <SuiTypography variant="body2" fontWeight={"regular"}>
                      {getNames(letter.auditor_names)}
                    </SuiTypography>
                  </NavLink>
                  <SuiTypography variant="caption">
                    {t("Auditor email")}: {letter.auditor_email}
                  </SuiTypography>
                </Grid>
              </Grid>
            </CdxCard>
            {!letter.confirmer_submit && !isExpired && (
              <CdxCard p={3} mt={3}>
                <SuiTypography variant="h6">
                  {t("Download detail and response template")}
                </SuiTypography>
                <SuiBox mt={2}>
                  <CdxButton
                    color="dark"
                    variant="gradient"
                    progress={downloading}
                    onClick={downloadXLSX}
                  >
                    <Icon>download</Icon>&nbsp;{t("Download xlsx")}
                  </CdxButton>
                </SuiBox>
              </CdxCard>
            )}
          </>
        )}
      </SuiBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return { letters: state.confirmer.letters, email: state.confUser.email };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmerLetterView);
