export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const RELOAD_TOKEN = "RELOAD_TOKEN";
export const LOAD_CDX_USER = "LOAD_CDX_USER";
export const UPDATE_CSRF_TOKEN = "UPDATE_CSRF_TOKEN";

export const DEPLOY_SNACKBAR = "DEPLOY_SNACKBAR";
export const UPDATE_SNACKBAR = "UPDATE_SNACKBAR";

export const UPDATE_USER_SETTING = "UPDATE_USER_SETTING";

export const LIST_MY_BUSINESS_HASH = "LIST_MY_BUSINESS_HASH";
export const LIST_MY_BUSINESS = "LIST_MY_BUSINESS";

export const VIEW_BUSINESS_HASH = "VIEW_BUSINESS_HASH";
export const VIEW_BUSINESS = "VIEW_BUSINESS";
export const CLEAR_VIEW_BUSINESS = "CLEAR_VIEW_BUSINESS";

export const BUSINESS_MEMBER_HASH = "BUSINESS_MEMBER_HASH";
export const BUSINESS_MEMBER = "BUSINESS_MEMBER";
export const CLEAR_BUSINESS_MEMBER = "CLEAR_BUSINESS_MEMBER";

export const LIST_MY_ENGAGEMENT_HASH = "LIST_MY_ENGAGEMENT_HASH";
export const LIST_MY_ENGAGEMENT = "LIST_MY_ENGAGEMENT";

export const ENGAGEMENT_MEMBER_HASH = "ENGAGEMENT_MEMBER_HASH";
export const ENGAGEMENT_MEMBER = "ENGAGEMENT_MEMBER";
export const CLEAR_ENGAGEMENT_MEMBER = "CLEAR_ENGAGEMENT_MEMBER";

export const ENGAGEMENT_DATA_HASH = "ENGAGEMENT_DATA_HASH";
export const ENGAGEMENT_DATA = "ENGAGEMENT_DATA";
export const CLEAR_ENGAGEMENT_DATA = "CLEAR_ENGAGEMENT_DATA";

export const SELECT_ACTIVE_ENGAGEMENT = "SELECT_ACTIVE_ENGAGEMENT";
export const DESELECT_ACTIVE_ENGAGEMENT = "DESELECT_ACTIVE_ENGAGEMENT";

export const CONFIRMER_INBOX_HASH = "CONFIRMER_INBOX_HASH";
export const CONFIRMER_INBOX = "CONFIRMER_INBOX";

export const CONFIRMER_HISTORY_HASH = "CONFIRMER_HISTORY_HASH";
export const CONFIRMER_HISTORY = "CONFIRMER_HISTORY";

export const CONFIRMER_SUMMARY = "CONFIRMER_SUMMARY";

export const CONFIRMER_LETTER_HASH = "CONFIRMER_LETTER_HASH";
export const CONFIRMER_LETTER = "CONFIRMER_LETTER";
export const CLEAR_CONFIRMER_LETTER = "CLEAR_CONFIRMER_LETTER";

export const ENGAGEMENT_LETTER_HASH = "ENGAGEMENT_LETTER_HASH";
export const ENGAGEMENT_LETTER = "ENGAGEMENT_LETTER";
export const CLEAR_ENGAGEMENT_LETTER = "CLEAR_ENGAGEMENT_LETTER";

export const CONF_SIGN_IN = "CONF_SIGN_IN";
export const CONF_SIGN_OUT = "CONF_SIGN_OUT";
export const CONF_RELOAD_TOKEN = "CONF_RELOAD_TOKEN";
